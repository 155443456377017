import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { useLocation } from 'react-router-dom';
import { Button, Result } from 'antd';

function FallbackComponent({ eventId, resetError }) {
  const location = useLocation();
  const [initialLocation] = useState(location);
  useEffect(() => {
    if (location !== initialLocation) {
      resetError();
    }
  }, [location]);
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Result
        status="warning"
        title="Ein Fehler ist aufgetreten. Das tut uns leid."
        extra={
          <>
            <Button
              type="primary"
              onClick={() => {
                window.location.reload();
              }}
            >
              Seite neu Laden
            </Button>
            <Button
              type="secondary"
              onClick={() => {
                Sentry.showReportDialog({
                  eventId,
                  title: 'Hilf uns, den Fehler zu beheben.',
                  subtitle:
                    'Unser Team wurde über das Problem benachrichtigt. Wenn du uns helfen möchtest, teile uns bitte unten mit, was geschehen ist.',
                  subtitle2: '',
                });
              }}
            >
              Feedback geben
            </Button>
          </>
        }
      />
    </div>
  );
}

function ErrorHandler({ children }) {
  useEffect(() => {
    Sentry.init({
      dsn: window.REACT_APP_SENTRY_DNS,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0.1,
    });
  });
  return (
    <Sentry.ErrorBoundary
      fallback={({ eventId, resetError }) => <FallbackComponent eventId={eventId} resetError={resetError} />}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}

export default ErrorHandler;
