import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import SideMenu from '../../components/SideMenu/index';

import Profile from './components/Profile';
import Account from './components/Account';
import ProfileAccounts from './components/ProfileAccounts';

import ThreeColumnLayout from '../../layouts/ThreeColumnLayout';

import { ProfileOverview, ProgressContainer } from 'components';

import './style.css';

class Settings extends Component {
  renderSettingsForm() {
    const { accountRole } = this.context;
    let { subpage = 'profile' } = this.props.match.params;

    switch (subpage) {
      case 'profile':
        return accountRole !== 'DRAFT' ? <Profile /> : null;
      case 'account':
        return <Account />;
      case 'profileAccount':
        return accountRole === 'ADMIN' || accountRole === 'SUPERADMIN' ? <ProfileAccounts /> : null;
      default:
        return <Profile />;
    }
  }

  render() {
    const { closedProgress = false, successModal = false } = this.props;

    const { accountRole, role } = this.context;

    const progressTrackingActive = role === 'STUDENT_COUNCIL' && !closedProgress && !successModal;

    let menuStructure =
      accountRole !== 'DRAFT'
        ? [
            {
              title: 'Profil',
              key: 'profile',
              icon: 'user',
            },
            {
              title: 'Account',
              key: 'account',
              icon: 'user',
            },
          ]
        : [
            {
              title: 'Account',
              key: 'account',
              icon: 'user',
            },
          ];
    if (accountRole === 'ADMIN' || accountRole === 'SUPERADMIN') {
      menuStructure.push({
        title: 'Profil Accounts',
        key: 'profileAccount',
        icon: 'team',
      });
    }

    return (
      <div>
        <ThreeColumnLayout>
          <>
            <ProfileOverview minimized={true} />
            <SideMenu items={menuStructure} />
          </>
          <div className="uninow-settings-container">{this.renderSettingsForm()}</div>
        </ThreeColumnLayout>

        {progressTrackingActive && <ProgressContainer />}
      </div>
    );
  }
}

Settings.contextTypes = {
  accountRole: PropTypes.string.isRequired,
};

const mapStateToProps = ({ progress }) => ({
  closedProgress: progress.closed,
  successModal: progress.successModal,
});

export default connect(mapStateToProps)(Settings);
