import React, { Component } from 'react';

import { Popover, Button, Row, Col } from 'antd';

import Ionicon, { ICONS } from '../Ionicon';

import './style.css';

const CHUNK_SIZE = 6;

class IconPopover extends Component {
  state = {
    visible: false,
  };

  onSelect = (icon) => {
    const { onSelect } = this.props;

    if (onSelect) {
      onSelect(icon);
    }

    this.setState({ visible: false });
  };

  onVisibleChange = (visible) => {
    this.setState({ visible });
  };

  renderIconGrid = (rows) => {
    const colSpan = 24 / rows[0].length;

    return (
      <div>
        {rows.map((row, rowIndex) => (
          <Row key={rowIndex}>
            {row.map((col, colIndex) => (
              <Col key={colIndex} span={colSpan}>
                <Ionicon
                  type={col}
                  className="icon"
                  fontStyle={{ fontSize: 24 }}
                  style={{ margin: 5, cursor: 'pointer' }}
                  onClick={() => this.onSelect(col)}
                />
              </Col>
            ))}
          </Row>
        ))}
      </div>
    );
  };

  render() {
    const { visible } = this.state;
    const { value, defaultValue = ICONS[0] } = this.props;

    const chunks = [[]];

    for (let i = 0; i < ICONS.length; i++) {
      const icon = ICONS[i];
      const currentChunk = chunks[chunks.length - 1];

      if (currentChunk.length === CHUNK_SIZE) {
        chunks.push([icon]);
      } else {
        currentChunk.push(icon);
      }
    }

    return (
      <Popover
        id="IconPopover"
        onVisibleChange={this.onVisibleChange}
        content={this.renderIconGrid(chunks)}
        trigger="click"
        visible={visible}
      >
        <Button style={{ width: 35, height: 35, padding: 0 }}>
          <Ionicon type={value || defaultValue} fontStyle={{ marginTop: '-6px' }} />
        </Button>
      </Popover>
    );
  }
}

export default IconPopover;
