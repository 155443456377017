import React, { Component } from 'react';
import moment from 'moment';
import { Row, Col, Icon } from 'antd';

import Post from '../../Post';
import DefaultListItem from './DefaultListItem';
import calender from './images/feed-calender.svg';

export default class FuturePostList extends Component {
  state = { showFuturePosts: false };

  renderPost = (edge) => {
    return <Post key={edge.cursor} post={edge.node} {...(this.props.postProps || {})} />;
  };

  render() {
    const { posts = [] } = this.props;

    return (
      <div id="PostList">
        <DefaultListItem
          style={{
            borderBottom: '1px solid rgb(230, 236, 240)',
            fontSize: 14,
          }}
          content={
            <Row>
              <Col span={3}>
                <img src={calender} alt="calender" />
              </Col>
              <Col span={1} className="weight-bold" style={{ color: '#000', fontSize: 22 }}>
                {posts.length}
              </Col>
              <Col span={13} style={{ textAlign: 'left', marginTop: 5 }}>
                <div className="weight-bold" style={{ color: '#000' }}>
                  {posts.length === 1 ? `geplante Veröffentlichung` : `geplante Veröffentlichungen`}{' '}
                </div>
                <div className="color-secondary">
                  Der nächste Post ist geplant am {moment(posts[0].node.publishDate).format('DD.MM.YYYY')} um{' '}
                  {moment(posts[0].node.publishDate).format('HH:mm')} Uhr
                </div>
              </Col>
              <Col span={7} style={{ textAlign: 'right', marginTop: 5 }}>
                {this.state.showFuturePosts ? (
                  <span
                    className="clickable-primary"
                    onClick={() =>
                      this.setState({
                        showFuturePosts: false,
                      })
                    }
                  >
                    {'  '}
                    Alle verbergen <Icon type="down" />
                  </span>
                ) : (
                  <span
                    className="clickable-primary"
                    onClick={() =>
                      this.setState({
                        showFuturePosts: true,
                      })
                    }
                  >
                    Alle anzeigen{'  '}
                    <Icon type="right" />
                  </span>
                )}
              </Col>
            </Row>
          }
        />
        {this.state.showFuturePosts && posts.map(this.renderPost)}
      </div>
    );
  }
}
