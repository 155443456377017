export const SET_DISPLAY_NAME = 'SET_DISPLAY_NAME';
export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER';

export function setDisplayName(displayName) {
  return {
    type: SET_DISPLAY_NAME,
    displayName,
  };
}

export function setPhoneNumber(phoneNumber) {
  return {
    type: SET_PHONE_NUMBER,
    phoneNumber,
  };
}