import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { Spin, Row } from 'antd';
import { PersistGate } from 'redux-persist/es/integration/react';

import './index.css';
import Router from './core/router';
import Session from './core/session';
import configureStore from './core/redux/store';
import NPSFeedback from './components/NPSFeedback';
import Chatwoot from 'components/Chatwoot/Chatwoot';

import { setupLogging } from './core/logger';

const { persistor, store } = configureStore();

setupLogging();

class App extends React.Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    setTimeout(() => this.setState({ loading: false }), 500);
  }

  render() {
    const { loading } = this.state;

    return (
      <div style={{ height: '100vh', width: '100%' }}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <Session persistor={persistor}>
              {loading ? (
                <Row
                  type="flex"
                  align="middle"
                  justify="center"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 30,
                    backgroundPosition: '50% 50%',
                    backgroundColor: 'white',
                  }}
                >
                  <Spin size="large" />
                </Row>
              ) : (
                <div>
                  <Router />
                  <Chatwoot/>
                  <NPSFeedback />
                </div>
              )}
            </Session>
          </PersistGate>
        </Provider>
      </div>
    );
  }
}

ReactDOM.render(<App />, document.getElementById('root'));
