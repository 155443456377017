import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import { notification } from 'antd';
import LoginForm from '../../components/LoginForm';
import ContentLoggedOut from '../../components/ContentLoggedOut';

class Login extends Component {
  state = {
    email: '',
    password: '',
    loading: false,
  };

  onError = (error) => {
    const { NODE_ENV } = window;

    notification.error({
      message: NODE_ENV === 'development' ? error.message : 'Login fehlgeschlagen',
      description: 'Wenn du dein Passwort vergessen hast kannst du dieses zurücksetzen',
    });
  };

  onLogin = async (credentials) => {
    const { email, password } = credentials;

    try {
      this.setState({ loading: true });
      await this.context.login(email, password);
    } catch (err) {
      this.setState({ loading: false });
      this.onError(err);
    }
  };

  componentDidMount() {
    this.context.logout();
  }

  render() {
    if (this.context.isAuthenticated === true) {
      return <Redirect to="/" />;
    }

    const { loading } = this.state;

    return (
      <ContentLoggedOut>
        <LoginForm onLogin={(credentials) => this.onLogin(credentials)} loading={loading} />
      </ContentLoggedOut>
    );
  }
}

Login.contextTypes = {
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default Login;
