import React from 'react';
import { Form, Icon, Input, Row, Tooltip } from 'antd';
import { emergencyGlobalChannelId } from '../../../core/constants';

function PushConfiguration({ selectedChannels, me, content, form }) {
  const { getFieldDecorator } = form;
  return (
    <Form.Item className="align-left verify-section">
      <div>
        <div className="color-secondary weight-medium verify-section-headline">
          Push Konfiguration (optional) &nbsp;
          <Tooltip
            placement="right"
            title="Lege benutzerdefinierte Texte fest, die dem Nutzer in der Push Mitteilung angezeigt werden."
          >
            <Icon type="question-circle-o" />
          </Tooltip>
        </div>
        <Row>
          <Form.Item className="padded-input">
            {getFieldDecorator('pushconfig.title', {
              initialValue: undefined,
            })(
              <Input
                placeholder={
                  Object.keys(selectedChannels).find((el) => el === emergencyGlobalChannelId) ? 'Notfall' : 'Neuer Post'
                }
              />
            )}
          </Form.Item>
          <Form.Item className="padded-input">
            {getFieldDecorator('pushconfig.text', {
              initialValue: undefined,
            })(
              <Input.TextArea
                autoSize={{ minRows: 2, maxRows: 2 }}
                placeholder={
                  Object.keys(selectedChannels).find((el) => el === emergencyGlobalChannelId)
                    ? content
                    : `${me.username} hat einen neuen Beitrag erstellt.`
                }
              />
            )}
          </Form.Item>
        </Row>
      </div>
    </Form.Item>
  );
}

export default PushConfiguration;
