import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import { Icon, message } from 'antd';

import './style.css';

const MAX_VIDEO_SIZE = 40000000; //40MB

class VideoDropzone extends Component {
  onFilesAccept = (files) => {
    if (this.props.onFileSelected) {
      this.props.onFileSelected(files);
    }
  };

  onFilesRejected = (files) => {
    files.forEach((file) => {
      if (file.size > MAX_VIDEO_SIZE) {
        message.error(`Dateigröße von ${file.name} übersteigt das Limit von ${MAX_VIDEO_SIZE / 1000000}MB.`);
      } else {
        message.error(`${file.name} konnte nicht hochgeladen werden.`);
      }
    });
  };

  render() {
    const { shape, icon } = this.props;

    return (
      <Dropzone
        id="VideoDropzone"
        className={`overlay ${shape}`}
        accept=".avi,.mp4,.mov,.mpg"
        maxSize={MAX_VIDEO_SIZE}
        multiple={this.props.multiple}
        onDropRejected={this.onFilesRejected}
        onDropAccepted={this.onFilesAccept}
      >
        <div style={{ position: 'relative', height: '100%' }}>
          <Icon type={icon} className="upload-icon" />
        </div>
      </Dropzone>
    );
  }
}

VideoDropzone.propTypes = {
  onFileSelected: PropTypes.func,
  shape: PropTypes.string,
  multiple: PropTypes.bool,
  icon: PropTypes.string,
};

VideoDropzone.defaultProps = {
  multiple: false,
  icon: 'video-camera',
};

export default VideoDropzone;
