import React, { Component } from 'react';

import './style.css';

class Content extends Component {
  render() {
    return (
      <div
        style={{
          ...this.props.style,
        }}
        className="uninow-feed-content"
      >
        {this.props.children}
      </div>
    );
  }
}

export default Content;
