import moment from 'moment';
import { mapValues } from 'lodash';

function toUtc(date, time) {
  return moment
    .utc()
    .year(date.year())
    .month(date.month())
    .date(date.date())
    .hours(time ? time.hours() : date.hours())
    .minutes(time ? time.minutes() : date.minutes());
}

export function attachmentFromValues(type, values = {}) {
  const {
    title,
    start,
    end,
    addition,
    image,
    images,
    audio,
    video,
    company,
    badgeTitle,
    duration,
    location = {},
    jobs = [],
  } = values.attachment || {};

  switch (type) {
    case 'recruiting':
      let recruitingAttachment;

      if (jobs.length === 0) {
        recruitingAttachment = { company: company.id, badgeTitle };
      } else {
        recruitingAttachment = { jobs: jobs.map((job) => job.id) };
      }

      if (image) {
        recruitingAttachment.image = {
          type: 'image',
          data: image.data,
        };
      }

      return recruitingAttachment;

    case 'blog':
      return {
        ...values.attachment,
        image: image
          ? {
              type: 'image',
              data: image.data,
              raw: image.raw,
            }
          : null,
        translations: {
          ...values.attachment?.translations,
          image: mapValues(values.attachment?.translations?.image, (el) =>
            el ? { type: 'image', data: el?.data, raw: el?.raw } : null
          ),
        },
      };

    case 'podcast':
      return {
        ...values.attachment,
        duration,
        audio: audio
          ? {
              type: 'audio',
              data: audio.data,
              raw: audio.raw,
            }
          : null,
        image: image
          ? {
              type: 'image',
              data: image.data,
              raw: image.raw,
            }
          : null,
        translations: {
          ...values.attachment?.translations,
          image: mapValues(values.attachment?.translations?.image, (el) =>
            el ? { type: 'image', data: el?.data, raw: el?.raw } : null
          ),
          audio: mapValues(values.attachment?.translations?.audio, (el) =>
            el ? { type: 'audio', data: el?.data, raw: el?.raw } : null
          ),
        },
      };

    case 'gallery':
      return {
        images: images?.map
          ? images.map((image) => ({
              type: 'image',
              data: image.data,
              raw: image.raw,
            }))
          : null,
        translations: {
          ...values.attachment?.translations,
          images: mapValues(values.attachment?.translations?.images, (el) =>
            el.map((e) => (e ? { type: 'image', data: e?.data, raw: e?.raw } : null)).filter((el) => !!el)
          ),
        },
      };

    case 'video':
      return {
        video: video?.raw
          ? { type: 'video', raw: video.raw }
          : {
              type: 'video',
              data: video,
            },
        translations: {
          ...values.attachment?.translations,
          video: mapValues(values.attachment?.translations?.video, (el) =>
            el?.raw ? { type: 'video', raw: el?.raw } : { type: 'video', data: el }
          ),
        },
      };

    case 'event':
      return {
        title,
        location: {
          ...location,
          addition,
        },
        start: toUtc(start, start).format(),
        end: end ? toUtc(end, end).format() : null,
        translations: {
          title: values.attachment?.translations?.title,
        },
      };

    case 'poll':
      return {
        ...values.attachment,
        end: end ? end.format() : undefined,
        translations: {
          ...values.attachment?.translations,
        },
      };

    default:
      return null;
  }
}

export function eventFromValues(values = {}) {
  const { title, start, end, addition, location = {} } = values.attachment;

  return {
    title,
    location: {
      ...location,
      addition,
    },
    start: toUtc(start, start).format(),
    end: end ? toUtc(end, end).format() : null,
  };
}
