import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import update from 'immutability-helper';
import { Link } from 'react-router-dom';
import { Mutation, Query } from 'react-apollo';
import { Button, Form, Input, Modal, notification } from 'antd';

import { privacyPolicyVersion, userAgreementVersion } from '../../core/constants';

import { PROFILE } from 'core/gql/queries';
import { REGISTER, UPDATE_CONSENT_VERSION } from 'core/gql/mutations';

class ConsentModal extends Component {
  state = {
    privacyPolicyVersion: null,
    userAgreementVersion: null,
    publishMembership: null,
    password1: null,
    password2: null,
    disabledButton: true,
  };

  isRegistration(me) {
    return !me.account.isRegistered;
  }

  updateButton(newState, me) {
    const state = { ...this.state, ...newState };
    let disabledButton = false;

    if (
      (!me.account.privacyPolicy ||
        !me.account.privacyPolicy.version ||
        me.account.privacyPolicy.version < privacyPolicyVersion) &&
      !state.privacyPolicyVersion
    ) {
      disabledButton = true;
    }
    if (
      (!me.account.userAgreement ||
        !me.account.userAgreement.version ||
        me.account.userAgreement.version < userAgreementVersion) &&
      !state.userAgreementVersion
    ) {
      disabledButton = true;
    }

    if (!state.password1 || !state.password2 || state.password1 !== state.password2) {
      disabledButton = true;
    }
    this.setState({ ...state, disabledButton: disabledButton });
  }

  onSubmit(isRegister, me, callback) {
    const { validateFields } = this.props.form;
    validateFields((error, values) => {
      if (error) {
        return;
      }
      const variables = {};

      if (
        (!me.account.privacyPolicy ||
          !me.account.privacyPolicy.version ||
          me.account.privacyPolicy.version < privacyPolicyVersion) &&
        this.state.privacyPolicyVersion
      ) {
        variables.privacyPolicyVersion = privacyPolicyVersion;
      }
      if (
        (!me.account.userAgreement ||
          !me.account.userAgreement.version ||
          me.account.userAgreement.version < userAgreementVersion) &&
        this.state.userAgreementVersion
      ) {
        variables.userAgreementVersion = userAgreementVersion;
      }

      if (!me.publishMembership && values.publishMembership) {
        variables.publishMembership = true;
      }

      if (isRegister) {
        variables.password = values.password1;
      }
      callback({ variables });
    });
  }

  onError = (isRegister) => {
    notification.error({
      message: isRegister
        ? 'Während der Registrierung kam es zu einem Fehler'
        : 'Während der Aktualisierung kam es zu einem Fehler',
      description: 'Bitte kontaktieren sie support@uninow.de.',
    });
  };

  onCompleted = (isRegister) => {
    notification.success({
      message: isRegister ? 'Du hast dich erfolgreich registriert' : 'Deine Daten wurden erfolgreich aktualisiert',
      description: isRegister ? 'Verfasse gleich deinen ersten Post!' : null,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { disabledButton } = this.state;
    return (
      <Query query={PROFILE}>
        {({ loading, error, data }) => {
          if (loading || error || !data.me) {
            return null;
          }
          const isRegister = true || this.isRegistration(data.me);
          const isConsentUpdated =
            true ||
            !data.me.account.userAgreement.version ||
            data.me.account.userAgreement.version < userAgreementVersion ||
            !data.me.account.privacyPolicy.version ||
            data.me.account.privacyPolicy.version < privacyPolicyVersion;
          const showModal = isConsentUpdated || isRegister;
          return (
            <Modal
              title={isRegister ? 'Bitte ändere dein Passwort' : 'Wir haben unsere Bestimmungen aktualisiert'}
              visible={showModal}
              onOk={this.handleOk}
              closable={false}
              footer={null}
            >
              <Form>
                {isRegister && (
                  <div>
                    <Form.Item label="Neues Passwort">
                      {getFieldDecorator('password1', {
                        rules: [
                          {
                            required: true,
                            message: 'Bitte gib dein neues Passwort ein',
                          },
                        ],
                      })(
                        <Input
                          type="password"
                          onChange={(e) => {
                            this.updateButton(
                              {
                                password1: e.target.value,
                              },
                              data.me
                            );
                          }}
                        />
                      )}
                    </Form.Item>
                    <Form.Item label="Neues Passwort bestätigen">
                      {getFieldDecorator('password2', {
                        rules: [
                          {
                            required: true,
                            message: 'Bitte wiederhole dein neues Passwort',
                          },
                        ],
                      })(
                        <Input
                          type="password"
                          onChange={(e) => {
                            this.updateButton(
                              {
                                password2: e.target.value,
                              },
                              data.me
                            );
                          }}
                        />
                      )}
                    </Form.Item>
                  </div>
                )}
                Ich habe die{' '}
                <a target="_blank" rel="noopener norefferer" href="https://legal.uninow.com/feed/terms-of-use-uninow-feed/de/latest" >
                  Nutzervereinbarung
                </a>{' '}
                und{' '}
                <a target="_blank" rel="noopener norefferer" href="https://legal.uninow.com/feed/privacy-policy-uninow-feed/de/latest" >
                  Datenschutzerklärung
                </a>{' '}
                gelesen und stimme diesen zu.
                <Form.Item style={{ textAlign: 'center' }}>
                  <Mutation
                    mutation={isRegister ? REGISTER : UPDATE_CONSENT_VERSION}
                    onError={() => this.onError(isRegister)}
                    onCompleted={() => this.onCompleted(isRegister)}
                    update={(cache, { data }) => {
                      const result = isRegister ? data.register : data.updateConsentVersions;
                      const { me } = cache.readQuery({
                        query: PROFILE,
                      });
                      cache.writeQuery({
                        query: PROFILE,
                        data: {
                          me: update(me, {
                            account: {
                              privacyPolicy: {
                                version: {
                                  $set: privacyPolicyVersion,
                                },
                              },
                              userAgreement: {
                                version: {
                                  $set: userAgreementVersion,
                                },
                              },
                              publishMembership: {
                                $set: me.publishMembership || this.state.publishMembership,
                              },
                              isRegistered: {
                                $set: true,
                              },
                            },
                          }),
                        },
                      });
                      if (isRegister) {
                        this.context.login(result);
                      }
                    }}
                  >
                    {(callback, { error }) => (
                      <Button
                        disabled={disabledButton}
                        type="primary"
                        htmlType="submit"
                        style={{
                          fontWeight: 700,
                          padding: '0px 30px',
                          borderWidth: 0,
                          borderRadius: 100,
                          marginTop: 20,
                        }}
                        onClick={() => this.onSubmit(isRegister, data.me, callback)}
                      >
                        {isRegister ? 'Jetzt registrieren' : 'Bestätigen'}
                      </Button>
                    )}
                  </Mutation>
                </Form.Item>
              </Form>
            </Modal>
          );
        }}
      </Query>
    );
  }
}

ConsentModal.contextTypes = {
  login: PropTypes.func.isRequired,
};

export default Form.create()(ConsentModal);
