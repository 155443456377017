export const MINIMIZE = 'MINIMIZE';
export const COMPLETED = 'COMPLETED';
export const CLOSE = 'CLOSE';
export const RESET_PROGRESS = 'RESET_PROGRESS';

export function minimize() {
  return {
    type: MINIMIZE,
  };
}

export function complete() {
  return {
    type: COMPLETED,
  };
}

export function close() {
  return {
    type: CLOSE,
  };
}

export function resetProgress() {
  return {
    type: RESET_PROGRESS,
  };
}
