import { Input } from 'antd';
import translate from '../../../utils/translate';
import React from 'react';
import useFeedDisclaimer from './useFeedDisclaimer';

export default function PostTextArea({ lang, primaryValue, disabled, className, value, onChange, primaryLanguage }) {
  const { showDisclaimer } = useFeedDisclaimer();
  return (
    <Input.TextArea
      value={value}
      onChange={onChange}
      onFocus={showDisclaimer}
      disabled={disabled}
      className={className}
      autoSize={{ minRows: 4, maxRows: 10 }}
      style={{ resize: 'none' }}
      rows={4}
      placeholder={lang !== primaryLanguage && !!primaryValue ? primaryValue : translate('NEW_POST', lang)}
    />
  );
}
