import React, { memo } from 'react';

import { Collapse } from 'antd';

import './style.css';

const { Panel } = Collapse;

export default memo(function ({ renderItem, renderSectionHeader, sections = [] }) {
  return (
    <div id="CollapsableSectionChecklist">
      <Collapse bordered={false} defaultActiveKey={sections.map(({ title }) => title)} expandIconPosition="right">
        {sections.map(({ title, data = [] }) => (
          <Panel header={renderSectionHeader({ section: { title } })} key={title}>
            {data.map((item) => renderItem({ item }))}
          </Panel>
        ))}
      </Collapse>
    </div>
  );
});
