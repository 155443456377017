import update from 'immutability-helper';
import moment from 'moment';
import { GET_MY_POSTS, PROFILE } from './gql/queries';

export function deletePost(id, client, queryAndVariables = {}) {
  let me = null;
  try {
    let { me: cacheMe } = client.readQuery(queryAndVariables);
    me = cacheMe;
  } catch {
    return false;
  }
  if (!me) return false;
  const { edges } = me.posts;
  const index = edges.findIndex((elem) => elem.node.id === id);

  if (index !== -1) {
    client.writeQuery({
      ...queryAndVariables,
      data: {
        me: update(me, {
          posts: {
            edges: {
              $set: [...edges.slice(0, index), ...edges.slice(index + 1)],
            },
          },
        }),
      },
    });

    return true;
  }

  return false;
}

export function addPost(post, cacheOrClient, queryAndVariables = {}, sort = false) {
  const { me } = cacheOrClient.readQuery(queryAndVariables);

  const { edges } = me.posts;
  cacheOrClient.writeQuery({
    ...queryAndVariables,
    data: {
      me: update(me, {
        posts: {
          edges: {
            $set: sort
              ? [post, ...edges].sort((a, b) => moment(a.node.publishDate).diff(b.node.publishDate))
              : [post, ...edges],
          },
        },
      }),
    },
  });
}

export function updateCacheAfterSend(cache, { data }) {
  const { node } = data.sendPost;

  const freshmanPost = node.notificationChannels.some((channel) => channel.name === 'Ersti');
  if (data?.sendPost?.node?.status === 'DRAFT') {
    addPost(
      data.sendPost,
      cache,
      {
        query: GET_MY_POSTS,
        variables: {
          filter: 'DRAFT',
          paginationInput: { first: 200 },
        },
      },
      true
    );
  } else if (data.sendPost.node.planned) {
    addPost(
      data.sendPost,
      cache,
      {
        query: GET_MY_POSTS,
        variables: {
          filter: 'PLANNED',
          paginationInput: { first: 200 },
        },
      },
      true
    );

    if (freshmanPost) {
      addPost(
        data.sendPost,
        cache,
        {
          query: GET_MY_POSTS,
          variables: {
            filter: 'FIRST_SEMESTER_PLANNED',
            paginationInput: { first: 200 },
          },
        },
        true
      );
    }
  } else {
    addPost(data.sendPost, cache, {
      query: GET_MY_POSTS,
      variables: { filter: 'PUBLISHED' },
    });

    if (freshmanPost) {
      addPost(data.sendPost, cache, {
        query: GET_MY_POSTS,
        variables: { filter: 'FIRST_SEMESTER' },
      });
    }
  }

  const myProfile = cache.readQuery({
    query: PROFILE,
  });
  const { postsCount } = myProfile.me;
  cache.writeQuery({
    query: PROFILE,
    data: {
      me: update(myProfile.me, {
        postsCount: {
          $set: postsCount + 1,
        },
      }),
    },
  });
}
