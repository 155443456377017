import React from 'react';
import { LANGUAGES } from '../../../core/constants';
import { PROFILE } from '../../../core/gql/queries';
import { useQuery } from '@apollo/react-hooks';
import { Badge, Tabs } from 'antd';

const { TabPane } = Tabs;
export const NewPostInput = ({ renderForm, changeLanguage, validateError, primaryLanguage }) => {
  const { data: profileData = {}, loading, error } = useQuery(PROFILE);
  const multilingualPostsIsActive = profileData?.me?.permissions?.includes('MULTILINGUAL_POSTS');

  if (!loading && !error && multilingualPostsIsActive) {
    return (
      <Tabs defaultActiveKey="1" type="card" className="tabs" onChange={changeLanguage}>
        {Object.keys(LANGUAGES).map((key) => (
          <TabPane
            tab={
              primaryLanguage === key ? (
                <Badge
                  count={validateError ? '!' : ''}
                  offset={[10, 0]}
                  style={{
                    backgroundColor: 'transparent',
                    color: 'red',
                    boxShadow: '0 0 0 0px transparent inset',
                    fontSize: 18,
                    fontFamily: 'Georgia',
                  }}
                >
                  {LANGUAGES[key]}
                </Badge>
              ) : (
                LANGUAGES[key]
              )
            }
            key={key}
          >
            {renderForm(key, true)}
          </TabPane>
        ))}
      </Tabs>
    );
  }

  return <>{renderForm('de')}</>;
};
