import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AvatarEditor from 'react-avatar-editor';

import { Slider } from 'antd';

import './style.css';

class ImageEditor extends Component {
  state = {
    position: {
      x: 0.5,
      y: 0.5,
    },
    scale: 1.0,
  };

  resetState = () => {
    this.setState({
      position: {
        x: 0.5,
        y: 0.5,
      },
      scale: 1.0,
    });
  };

  getPayload = () => {
    if (this.editor) {
      const { scale } = this.state;
      const rect = this.editor.getCroppingRect();

      return {
        scale,
        ...rect,
      };
    }

    return null;
  };

  getImage = () => {
    if (this.editor) {
      return new Promise((resolve) => {
        const canvas = this.editor.getImage();

        canvas.toBlob((blob) => {
          resolve({
            type: 'image',
            data: blob,
          });
        });
      });
    }

    return null;
  };

  getDataURL = () => {
    return this.editor.getImage().toDataURL();
  };

  onPositionChange = (position) => {
    this.setState({ position });
  };

  onScaleChange = (scale) => {
    this.setState({ scale });
  };

  UNSAFE_componentWillReceiveProps(props) {
    const { image } = this.props;

    if (image !== props.image) {
      this.resetState();
    }
  }

  render() {
    const { position, scale } = this.state;
    const { image, width, height } = this.props;

    return (
      <div id="ImageEditor">
        <AvatarEditor
          ref={(editor) => {
            this.editor = editor;
          }}
          crossOrigin="anonymous"
          image={image}
          width={width}
          height={height}
          position={position}
          border={0}
          scale={scale}
          rotate={0}
          style={{ border: '1px solid black' }}
          onPositionChange={this.onPositionChange}
        />
        <Slider
          max={4.0}
          min={1.0}
          step={0.1}
          value={scale}
          style={{ width: width, margin: '25px auto' }}
          onChange={this.onScaleChange}
        />
      </div>
    );
  }
}

ImageEditor.propTypes = {
  image: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default ImageEditor;
