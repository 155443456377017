import React from 'react';

import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { minimize } from '../../core/redux/actions/progress';

import { Row, Col, Divider } from 'antd';

import { ResponsiveContainer, PieChart, Pie, Cell, Label } from 'recharts';
import { interpolateRgb } from 'd3-interpolate';

import { ChecklistItem } from 'components';

import useProgress from '../../hooks/useProgress';
import useBreakpoint from '../../hooks/useBreakpoint';

import './style.css';

const colorPalette = interpolateRgb('#f21850', '#faac0d');

const CHART_SIZE = {
  xs: { innerRadius: 30, outerRadius: 40 },
  sm: { innerRadius: 45, outerRadius: 60 },
  md: { innerRadius: 35, outerRadius: 45 },
  lg: { innerRadius: 45, outerRadius: 60 },
  xl: { innerRadius: 45, outerRadius: 60 },
  xxl: { innerRadius: 45, outerRadius: 60 },
};

const mapDispatchToProps = (dispatch) => ({
  minimizeProgressPost: () => dispatch(minimize()),
});

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(function ({ minimizeProgressPost, history = null }) {
    const { sections, itemCount, checkedItems, loading } = useProgress(history);
    const { breakpoint } = useBreakpoint();

    const percentage = Math.round((checkedItems * 100) / itemCount);

    const chartData = sections.reduce((chartData, section) => chartData.concat(section.data), []);

    if (loading) {
      return null;
    }

    return (
      <div id="ProgressPost">
        <span style={{ fontWeight: 700 }}>Vervollständige jetzt euer Profil!</span>
        <span className="clickable-primary" style={{ float: 'right' }} onClick={minimizeProgressPost}>
          minimieren
        </span>
        <Row type="flex" align="middle">
          <Col span={6}>
            <div style={{ width: '100%', height: '100%' }}>
              <ResponsiveContainer aspect={0.5} width="100%">
                <PieChart>
                  <Pie
                    isAnimationActive={false}
                    data={chartData}
                    startAngle={90}
                    endAngle={450}
                    paddingAngle={5}
                    dataKey="value"
                    {...CHART_SIZE[breakpoint]}
                  >
                    <Label value={`${percentage}%`} position="center" />
                    {chartData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={index < checkedItems ? colorPalette((chartData.length / 100) * index) : '#edeff4'}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
          </Col>
          <Col span={18}>
            {sections.map((section) => (
              <div key={section.title}>
                <Divider
                  orientation="left"
                  style={{
                    fontSize: '0.8em',
                    fontWeight: 200,
                    color: '#85858f',
                    letterSpacing: '1px',
                    margin: 5,
                  }}
                >
                  {section.title.toUpperCase()}
                </Divider>
                <Row>
                  {section.data.map((item) => (
                    <Col xs={24} sm={12} md={24} lg={12} key={item.title}>
                      <ChecklistItem
                        title={item.title}
                        description={item.description}
                        checked={item.checked}
                        onClick={item.onClick}
                        minimized={true}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            ))}
          </Col>
        </Row>
      </div>
    );
  })
);
