import React, { Component } from 'react';

import { Spin, Form, Input, Button, notification, Switch, Popover, Icon } from 'antd';
import { Query, Mutation, withApollo } from 'react-apollo';

import Geosearch from '../../../components/Geosearch/index';

import { PROFILE, PROOF_USERNAME } from 'core/gql/queries';
import { UPDATE_USER } from 'core/gql/mutations';
import { InstagramSwitch, InstagramPostReviewSwitch } from 'components';

class Profile extends Component {
  state = {
    pendingChanges: false,
  };

  updatePending = (event) => {
    const { pendingChanges } = this.state;
    const { getFieldsValue } = this.props.form;

    const values = {
      ...getFieldsValue(),
      [event.target.id]: event.target.value,
    };

    const hasChanges =
      ['displayName', 'username', 'email', 'website', 'imprint', 'telephoneNumber', 'publishMembership'].reduce(
        (curr, field) => curr | (values[field] !== this.data[field]),
        false
      ) |
      (this.data.location && this.data.location.label !== values.location.label) |
      (this.data.location && this.data.location.addition !== values.locationAddition);

    if (hasChanges !== pendingChanges) {
      this.setState({
        pendingChanges: hasChanges,
      });
    }
  };

  onBlurCompletionWebsite = (fieldName) => {
    const { getFieldValue, setFields, validateFields } = this.props.form;
    const value = getFieldValue(fieldName);
    if (value.length > 0 && !value.startsWith('https://') && !value.startsWith('http://')) {
      let newValue = {};
      newValue[fieldName] = { value: `https://${value}`, errors: null };
      setFields(newValue);
    }
    validateFields([fieldName]);
  };

  validateUsername = async (value, ownUsername, callback) => {
    clearTimeout(this.timer);
    if (value.toLowerCase() === ownUsername.toLowerCase()) callback();
    const res = await this.props.client.query({
      query: PROOF_USERNAME,
      variables: { username: value },
    });
    if (res && res.data && res.data.checkUsername === false) {
      callback();
    } else {
      callback('Nutzername bereits vergeben');
    }
  };

  updateTimer(field) {
    clearTimeout(this.timer);
    this.setState(
      { timerValidatingField: field },
      () =>
        (this.timer = setTimeout(() => {
          this.props.form.validateFields([this.state.timerValidatingField]);
        }, 1000))
    );
  }

  saveSettings = async (callback, currentSettings) => {
    const { validateFields } = this.props.form;

    validateFields(async (errors, values) => {
      if (errors) {
        return;
      }

      if (callback) {
        let body = {
          variables: {
            contact: {},
          },
        };
        ['displayName', 'username', 'publishMembership'].forEach((prop) => {
          if (values[prop] !== undefined && values[prop] !== currentSettings[prop]) {
            body.variables[prop] = values[prop];
          }
        });
        ['email', 'website', 'imprint', 'telephoneNumber'].forEach((prop) => {
          if (values[prop] !== currentSettings[prop]) {
            body.variables.contact[prop] = values[prop] || null;
          }
        });
        if (values.location.label !== currentSettings.location.label) {
          body.variables.contact.location = values.location;
        }
        if (values.locationAddition !== currentSettings.location.addition) {
          if (!body.variables.contact.location) {
            body.variables.contact.location = { ...values.location };
            if (body.variables.contact.location['__typename']) delete body.variables.contact.location['__typename'];
          }
          body.variables.contact.location.addition = values.locationAddition;
        }
        callback(body);
        this.setState({ pendingChanges: false });
      }
    });
  };

  onError = () => {
    notification.error({
      message: 'Einstellungen konnten nicht gespeichert werden',
      description: 'Bitte versuchen Sie es später noch einmal oder kontaktieren Sie uns.',
      duration: 3,
    });
  };

  onSuccess = () => {
    notification.success({
      message: 'Einstellungen wurden aktualisiert',
      description: 'Ihre Einstellungen wurden erfolgreich gespeichert',
      duration: 3,
    });
  };

  handleInstagramChange = (value) => {
    const { client } = this.props;
    const data = client.readQuery({ query: PROFILE });

    client.writeQuery({
      query: PROFILE,
      data: {
        ...data,
        me: {
          ...data.me,
          instagramConnection: {
            ...(data.me.instagramConnection || {}),
            active: value,
            error: null,
          },
        },
      },
    });
  };

  handleInstagramPostReviewChange = (value) => {
    const { client } = this.props;
    const data = client.readQuery({ query: PROFILE });

    client.writeQuery({
      query: PROFILE,
      data: {
        ...data,
        me: {
          ...data.me,
          mustReviewInstagramPosts: value,
        },
      },
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13, offset: 2 },
        md: { span: 15, offset: 2 },
      },
    };

    return (
      <Query query={PROFILE}>
        {({ loading, error, data }) => {
          if (loading) {
            return (
              <div style={{ textAlign: 'center' }}>
                <Spin />
              </div>
            );
          }

          if (error || !data.me) {
            return (
              <div style={{ textAlign: 'center' }}>
                Deine Einstellungen konnten leider nicht geladen werden.
                <br />
                <br />
                Versuche es doch später noch einmal oder kontaktiere uns über{' '}
                <a href="mailto:support@uninow.de">support@uninow.de</a>.
              </div>
            );
          }

          const {
            displayName = '',
            username = '',
            contact,
            publishMembership,
            instagramConnection = null,
            mustReviewInstagramPosts = false,
          } = data.me;

          let { email = '', website = '', imprint = '', location = {}, telephoneNumber = '' } = contact || {};

          location = location || {};

          this.data = {
            displayName,
            username,
            email,
            website,
            imprint,
            location,
            telephoneNumber,
            publishMembership: !!publishMembership,
          };

          return (
            <Form>
              <div className="headline weight-bold size-large">Profil</div>
              <Form.Item
                label="Anzeigename"
                {...formLayout}
                hasFeedback={true}
                extra={<div>Dieser Name repräsentiert das Profil und ist für alle zugehörigen Accounts gleich.</div>}
              >
                {getFieldDecorator('displayName', {
                  initialValue: displayName,
                  validateFirst: true,
                  validateTrigger: 'onBlur',
                  rules: [
                    {
                      min: 1,
                      max: 50,
                      message: 'Anzeigname muss aus 1-50 Zeichen bestehen',
                    },
                    {
                      pattern: /^[\w\süöäß\-\.]+$/i, // eslint-disable-line
                      message:
                        'Bitte benutze für deinen Anzeigenamen nur Buchstaben, Zahlen, Leerzeichen sowie folgende Zeichen: . und -',
                    },
                    {
                      required: true,
                      message: 'Bitte gib einen Anzeignamen ein',
                    },
                  ],
                })(
                  <Input
                    onChange={(event) => {
                      this.updatePending(event);
                    }}
                  />
                )}
              </Form.Item>
              <Form.Item
                label="Nutzername"
                {...formLayout}
                hasFeedback={true}
                extra={<div>Dieser Name repräsentiert das Profil und ist für alle zugehörigen Accounts gleich.</div>}
              >
                {getFieldDecorator('username', {
                  initialValue: username,
                  validateFirst: true,
                  validateTrigger: 'onBlur',
                  rules: [
                    {
                      required: true,
                      message: 'Bitte gib einen Nutzernamen ein',
                    },
                    {
                      min: 3,
                      message: 'Bitte gib mindestens 3 Zeichen ein',
                    },
                    {
                      max: 30,
                      message: 'Bitte gib maximal 30 Zeichen ein',
                    },
                    {
                      pattern: /^[\wüöäß\-_\.]+$/i, // eslint-disable-line
                      message: 'Bitte benutze für deinen Nutzernamen nur die Zeichen: a-z, A-Z, 0-9, - , _ und .',
                    },
                    {
                      pattern: new RegExp('^[a-zA-Z]'),
                      message: 'Bitte mit einem Buchstaben beginnen',
                    },
                    {
                      validator: (rule, value, callback) => {
                        if (/uninow|admin/gim.test(value) && !/uninow|admin/gim.test(username)) {
                          callback('Bitte vermeide folgende Wörter: UniNow, Admin');
                        } else {
                          callback();
                        }
                      },
                    },
                    {
                      validator: async (rule, value, callback) =>
                        await this.validateUsername(value, username, callback),
                    },
                  ],
                })(
                  <Input
                    onChange={(event) => {
                      this.updatePending(event);
                      this.updateTimer('username');
                    }}
                  />
                )}
              </Form.Item>
              <div className="headline weight-bold size-large">Kontaktdaten</div>
              <Form.Item label="E-Mail-Adresse" {...formLayout} hasFeedback={true}>
                {getFieldDecorator('email', {
                  initialValue: email,
                  validateFirst: true,
                  validateTrigger: 'onBlur',
                  rules: [
                    {
                      type: 'email',
                      message: 'Keine valide E-Mail-Adresse',
                    },
                  ],
                })(
                  <Input
                    type="email"
                    onChange={(event) => {
                      this.updatePending(event);
                    }}
                  />
                )}
              </Form.Item>
              <Form.Item label="Telefonnummer" {...formLayout} hasFeedback={true}>
                {getFieldDecorator('telephoneNumber', {
                  initialValue: telephoneNumber,
                  validateFirst: true,
                  validateTrigger: 'onBlur',
                  rules: [
                    {
                      pattern: new RegExp('^[+]?[0-9()/ -]*$'),
                      message: 'Keine valide Telefonnummer',
                    },
                  ],
                })(
                  <Input
                    onChange={(event) => {
                      this.updatePending(event);
                    }}
                  />
                )}
              </Form.Item>
              <Form.Item label="Website" {...formLayout} hasFeedback={true}>
                {getFieldDecorator('website', {
                  initialValue: website,
                  validateFirst: true,
                  validateTrigger: false,
                  rules: [
                    {
                      type: 'url',
                      message: 'Keine valide URL',
                    },
                  ],
                })(
                  <Input
                    onChange={(event) => {
                      this.updatePending(event);
                    }}
                    onBlur={() => this.onBlurCompletionWebsite('website')}
                  />
                )}
              </Form.Item>
              <Form.Item label="Impressum" {...formLayout} hasFeedback={true}>
                {getFieldDecorator('imprint', {
                  initialValue: imprint,
                  validateFirst: true,
                  validateTrigger: false,
                  rules: [
                    {
                      type: 'url',
                      message: 'Keine valide URL',
                    },
                  ],
                })(
                  <Input
                    onChange={(event) => {
                      this.updatePending(event);
                    }}
                    onBlur={() => this.onBlurCompletionWebsite('imprint')}
                  />
                )}
              </Form.Item>
              <Form.Item label="Standort" {...formLayout} hasFeedback={false} className="uninow-geosuggest">
                {getFieldDecorator('location', {
                  initialValue: location,
                  validateFirst: true,
                  validateTrigger: 'onBlur',
                  trigger: 'onSelect',
                  rules: [],
                })(
                  <Geosearch
                    initialValue={location.label}
                    onChange={(value) => {
                      this.updatePending({
                        target: { id: 'location', value },
                      });
                    }}
                    onSelect={(value) => {
                      this.updatePending({
                        target: { id: 'location', value },
                      });
                    }}
                  />
                )}
              </Form.Item>
              <Form.Item label="Adresszusatz" {...formLayout} hasFeedback={true}>
                {getFieldDecorator('locationAddition', {
                  initialValue: location.addition,
                  validateFirst: true,
                  validateTrigger: false,
                })(
                  <Input
                    onChange={(event) => {
                      this.updatePending(event);
                    }}
                  />
                )}
              </Form.Item>
              <div className="headline weight-bold size-large">Automatische Synchronisation</div>
              <Form.Item
                label="Instagram"
                {...formLayout}
                extra={
                  <div>
                    <p>
                      Verknüpft euren Instagram-Account, damit alle eure Beiträge automatisch im UniNow Feed gepostet werden.
                      <Popover
                        content={
                          <div style={{ maxWidth: 450 }}>
                            <h5>Voraussetzungen</h5>
                            <div>
                              Ihr benötigt einen{' '}
                              <a href="https://www.facebook.com/help/502981923235522" target="blank_">
                                Instagram-Business-Account
                              </a>
                              .
                            </div>
                            <br />
                            <h5>Synchronisation</h5>
                            <div>
                              Die Synchronisation mit euren Instagram Beiträgen findet stündlich statt. Hierbei werden
                              maximal die letzten 5 Beiträge berücksichtigt.
                              Hinweis: Die initiale Synchronisation kann bis zu 24h dauern. 
                            </div>
                            <br />
                            <h5>Löschen</h5>
                            <div>
                              Löscht ihr Beiträge bei Instagram müsst ihr diese händisch in eurem UniNow Profil löschen.
                              Trennt ihr die Verbindung zu Instagram werden künftig keine neuen Beiträge von Instagram
                              in den UniNow Feed geladen.
                            </div>
                          </div>
                        }
                      >
                        <Icon
                          type="question-circle"
                          style={{ cursor: 'pointer', padding: '0px 5px' }}
                          className="color-primary"
                        />
                      </Popover>
                    </p>
                    {instagramConnection.error && (
                      <span className="color-primary">
                        Fehler bei der Synchronisation. Bitte verbindet euren Instagram Account erneut!
                      </span>
                    )}
                  </div>
                }
              >
                <InstagramSwitch
                  checked={instagramConnection?.active && !instagramConnection.error}
                  onChange={this.handleInstagramChange}
                />
              </Form.Item>
              {!!instagramConnection?.active && (
                <Form.Item
                  label="Individuelle Postfreigabe"
                  {...formLayout}
                  extra={
                    <div>
                      <p>
                        Möchtest du, dass nicht alle Beiträge von Instagram übertragen werden? Wähle hier selbst, welche Posts im Feed erscheinen sollen.
                        <Popover
                          content={
                            <div style={{ maxWidth: 450 }}>
                              <h5>Wichtig</h5>
                              <div>
                                Es können nur Nutzer mit einer entsprechenden Rolle diese Posts freigeben, diese werden
                                erst nach der Prüfung veröffentlicht.
                              </div>
                            </div>
                          }
                        >
                          <Icon
                            type="question-circle"
                            style={{ cursor: 'pointer', padding: '0px 5px' }}
                            className="color-primary"
                          />
                        </Popover>
                      </p>
                    </div>
                  }
                >
                  <InstagramPostReviewSwitch
                    checked={!!mustReviewInstagramPosts}
                    onChange={this.handleInstagramPostReviewChange}
                  />
                </Form.Item>
              )}

              <div className="headline weight-bold size-large">Einwilligung Marketingzwecke</div>
              <Form.Item
                label="UniNow.de"
                {...formLayout}
                extra={
                  <div>
                    Bitte bestätigt uns, dass wir euer Logo und den Namen eurer Studierendenvertretung für eigene
                    Marketingmaßnahmen und Werbezwecke verarbeiten dürfen. Die Verwendung eurer Daten zu
                    Marketingzwecken könnt ihr jederzeit hier oder unter{' '}
                    <a href="mail:support@uninow.de">support@uninow.de</a> widersprechen.
                  </div>
                }
              >
                {getFieldDecorator('publishMembership', {
                  initialValue: publishMembership,
                  valuePropName: 'checked',
                  rules: [],
                })(
                  <Switch
                    onChange={(event) => {
                      this.updatePending({
                        target: { id: 'publishMembership', value: event },
                      });
                    }}
                  />
                )}
              </Form.Item>
              <Form.Item {...formLayout} label=" " colon={false}>
                <Mutation
                  onError={this.onError}
                  onCompleted={this.onSuccess}
                  mutation={UPDATE_USER}
                  update={(cache, { data: { updateUser } }) => {
                    const { me } = cache.readQuery({
                      query: PROFILE,
                    });
                    cache.writeQuery({
                      query: PROFILE,
                      data: { me: { ...me, ...updateUser } },
                    });
                  }}
                >
                  {(send, { loading }) => (
                    <Button
                      loading={loading}
                      disabled={!this.state.pendingChanges}
                      type="primary"
                      htmlType="submit"
                      onClick={() => this.saveSettings(send, this.data)}
                    >
                      Änderungen Speichern
                    </Button>
                  )}
                </Mutation>
              </Form.Item>
            </Form>
          );
        }}
      </Query>
    );
  }
}

export default Form.create()(withApollo(Profile));
