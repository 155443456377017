import React, { useEffect, useState } from 'react';

import { useMutation } from '@apollo/react-hooks';

import { Spin, Result } from 'antd';

import { CONNECT_INSTAGRAM } from '../../core/gql/mutations';

const TIME_TO_CLOSE = 4;

export default ({ location }) => {
  const [closeTime, setCloseTime] = useState(TIME_TO_CLOSE);
  const [connectInstagram, { loading, error, data }] = useMutation(CONNECT_INSTAGRAM);
  const { search } = location;

  const origin = window.location.origin;
  const redirectUri = `${origin}/instagram/auth`;

  const [, code] = /code=(.+)/gim.exec(search);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      connectInstagram({ variables: { code, redirectUri } });
    }, 3000);
    
    // Delay the execution by 3000 milliseconds (3 seconds) for accidental rerenders
    // DONT DELETE! Important for Meta App Reviews from the Philippines and Singapore

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, [code, connectInstagram, redirectUri]);

  useEffect(() => {
    if (!loading && (data || error)) {
      if (window.opener && closeTime === TIME_TO_CLOSE) {
        window.opener.postMessage(error ? 'error' : 'success', '*');
      }

      if (closeTime > 0) {
        setTimeout(() => setCloseTime(closeTime - 1), 1000);
      }
    }
  }, [closeTime, loading, data, error]);

  const closeWindow = () => window.close();

  if (closeTime === 0) {
    closeWindow();
  }

  if ((loading || !data) && !error) {
    return (
      <div style={{ textAlign: 'center' }}>
        <br />
        <div className="headline weight-bold size-large">Instagram wird verbunden...</div>
        <br />
        <Spin />
      </div>
    );
  }

  return error ? (
    <div style={{ textAlign: 'center' }}>
      <br />
      <div className="headline weight-bold size-large">Instagram konnte nicht verbunden werden!</div>
      <br />
      <Result status="error" style={{ padding: 0 }} />
      <br />
      <span>
        Dieses Fenster schließt sich automatisch in {closeTime} Sekunden.
        <br />
        <a href="" onClick={closeWindow}>
          Fenster jetzt schließen
        </a>
      </span>
    </div>
  ) : (
    <div style={{ textAlign: 'center' }}>
      <br />
      <div className="headline weight-bold size-large">Instagram verbunden!</div>
      <br />
      <Result status="success" style={{ padding: 0 }} />
      <br />
      <span>
        Dieses Fenster schließt sich automatisch in {closeTime} Sekunden.
        <br />
        <a href="" onClick={closeWindow}>
          Fenster jetzt schließen
        </a>
      </span>
    </div>
  );
};
