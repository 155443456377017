import React from 'react';

import { Collapse, Icon } from 'antd';

import { withRouter } from 'react-router-dom';
import { interpolateRgb } from 'd3-interpolate';

import { DottedProgressBar, ChecklistItem, CollapsableSectionChecklist } from 'components';
import useProgress from '../../hooks/useProgress';

import './style.css';

const colorPalette = interpolateRgb('#f21850', '#faac0d');

const { Panel } = Collapse;

export default withRouter(function ({ history = null }) {
  const { sections, itemCount, checkedItems, loading } = useProgress(history);

  const renderChecklistItem = ({ item }) => (
    <ChecklistItem
      key={item.title}
      title={item.title}
      onClick={item.onClick}
      onHide={item.onHide}
      checked={item.checked}
      description={item.description}
    />
  );

  const renderSectionHeader = ({ section: { title } }) => (
    <span style={{ fontWeight: 400, letterSpacing: '1px' }}>{title.toUpperCase()}</span>
  );

  if (loading) {
    return null;
  }

  const colorMap = new Array(itemCount).fill(null).map((_, index) => colorPalette((itemCount / 100) * index));

  return (
    <div id="ProgressContainer" style={{ width: 350 }}>
      <Collapse
        expandIconPosition="right"
        expandIcon={({ isActive }) => <Icon style={{ color: '#f21850' }} type={isActive ? 'down' : 'right'} />}
        style={{ backgroundColor: '#FFFFFF' }}
      >
        <Panel
          key="1"
          style={{ padding: 0, margin: 0 }}
          header={
            <div>
              Vervollständige jetzt euer Profil!
              <br />
              <DottedProgressBar min={0} max={itemCount} status={checkedItems} colors={colorMap} />
            </div>
          }
        >
          <div
            style={{
              overflowY: 'auto',
              overflowX: 'hidden',
              maxHeight: '80vh',
            }}
          >
            <CollapsableSectionChecklist
              renderItem={renderChecklistItem}
              renderSectionHeader={renderSectionHeader}
              sections={sections}
            />
          </div>
        </Panel>
      </Collapse>
    </div>
  );
});
