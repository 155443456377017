import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Icon, Form, Input } from 'antd';
import { mapValues } from 'lodash';
import AudioDropzone from '../../../../AudioDropzone';
import ImageDropzone from '../../../../ImageDropzone';

import { AudioPlayer } from '../../../../';

import { loadFromFile } from '../../../../../core/image';

class Podcast extends Component {
  constructor(props) {
    super(props);

    let { audio = null, duration = null, image = null } = props.data || {};

    if (props.data?.translations?.audio) audio = props.data?.translations?.audio?.[props.language] || null;
    if (props.data?.translations?.duration) duration = props.data?.translations?.duration?.[props.language] || null;
    if (props.data?.translations?.image) image = props.data?.translations?.image?.[props.language] || null;

    this.state = {
      audio,
      duration,
      image,
      primaryLanguage: 'de',
    };
  }

  onAudioFileSelected = async (files) => {
    const { setFieldsValue } = this.props.form;

    const selectedAudio = {
      type: 'audio',
      preview: files[0].preview,
      data: files[0],
    };

    setFieldsValue({
      [`attachment.translations.audio.${this.props.language}`]: selectedAudio,
    });

    this.setState({
      audio: selectedAudio,
    });
  };

  setAudioDuration = (audioElement) => {
    const { setFieldsValue } = this.props.form;

    if (audioElement) {
      const duration = Math.ceil(audioElement.duration);

      setFieldsValue({
        [`attachment.translations.duration.${this.props.language}`]: duration,
      });

      this.setState({
        duration,
      });
    }
  };

  onImageFileSelected = async (files) => {
    const { setFieldsValue } = this.props.form;

    const selectedImage = await loadFromFile(files[0]);

    setFieldsValue({
      [`attachment.translations.image.${this.props.language}`]: selectedImage,
    });

    this.setState({
      image: selectedImage,
    });
  };

  removeImage = () => {
    const { setFieldsValue } = this.props.form;

    setFieldsValue({
      [`attachment.translations.image.${this.props.language}`]: null,
    });

    this.setState({
      image: null,
    });
  };

  render() {
    const { form, data } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const { image, audio, duration } = this.state;

    const { title, description, translations } = data || {};

    const formTranslations = getFieldValue(`attachment.translations`);
    const primaryValues = formTranslations
      ? mapValues(formTranslations, (el) => el?.[this.state.primaryLanguage])
      : null;

    return (
      <div style={{ padding: '0px 20px 10px 20px' }}>
        <Row type="flex" align="top" style={{ alignItems: audio ? 'center' : 'stretch' }} gutter={25}>
          <Col span={12}>
            <div style={{ width: '100%', height: '100%' }}>
              {audio ? (
                <AudioPlayer onLoadedMetadata={this.setAudioDuration} source={audio.preview || audio.raw} />
              ) : (
                <AudioDropzone
                  icon="audio"
                  onFileSelected={this.onAudioFileSelected}
                  style={{ position: 'relative' }}
                />
              )}
            </div>
            {getFieldDecorator(`attachment.translations.audio.${this.props.language}`, { initialValue: audio })(
              <div />
            )}
            {getFieldDecorator(`attachment.translations.duration.${this.props.language}`, { initialValue: duration })(
              <div />
            )}
          </Col>
          <Col span={12} style={{ height: 100 }}>
            {getFieldDecorator(`attachment.translations.image.${this.props.language}`, { initialValue: image })(
              image ? (
                <div>
                  <img className="gallery-image" src={image.preview || image.large} alt="" />
                  <div className="gallery-overlay">
                    <div className="inner-overlay-two">
                      <div className="inner-overlay-three" onClick={() => this.removeImage()}>
                        <Icon type="close" />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <ImageDropzone
                  icon="picture"
                  multiple={false}
                  onFileSelected={this.onImageFileSelected}
                  style={{ position: 'relative' }}
                />
              )
            )}
          </Col>
        </Row>
        <Row style={{ paddingTop: 10 }}>
          <Form.Item className="padded-input">
            {getFieldDecorator(`attachment.translations.title.${this.props.language}`, {
              initialValue: translations?.title ? translations?.title?.[this.props.language] : title,
            })(<Input placeholder={primaryValues?.title || 'Titel'} />)}
          </Form.Item>
          <Form.Item className="padded-input">
            {getFieldDecorator(`attachment.translations.description.${this.props.language}`, {
              initialValue: translations?.description ? translations?.description?.[this.props.language] : description,
            })(
              <Input.TextArea
                autosize={{ minRows: 2, maxRows: 2 }}
                placeholder={primaryValues?.description || 'Beschreibung'}
              />
            )}
          </Form.Item>
        </Row>
      </div>
    );
  }
}

Podcast.propTypes = {
  form: PropTypes.object.isRequired,
  language: PropTypes.string,
};

export default Podcast;
