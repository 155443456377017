import React from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { changePreviewFocus } from '../../../core/redux/actions/preview';

export default function PreviewButton() {
  const focus = useSelector(({ preview }) => preview.focus);
  const dispatch = useDispatch();
  return (
    <Button
      type="primary"
      style={{
        backgroundColor: '#EDEFF4',
        color: '#82828C',
        float: 'left',
      }}
      className="hide-xs hide-sm hide-md hide-lg"
      onClick={() => {
        dispatch(changePreviewFocus(!focus));
      }}
    >
      {focus ? 'Preview ausblenden' : 'Preview einblenden'}
    </Button>
  );
}
