import gql from 'graphql-tag';

import { FRAGMENT_POST_DATA, FRAGMENT_PROFILE_DATA } from './fragments';

export const SWITCH_PROFILE = gql`
  mutation switchProfile($id: ID!) {
    switchProfile(id: $id) {
      id
      role
    }
  }
`;

export const SET_AUTO_FOLLOW = gql`
  mutation setAutoFollow($id: ID!) {
    setAutoFollow(id: $id)
  }
`;

export const UPDATE_CONSENT_VERSION = gql`
  mutation updateConsentVersions($privacyPolicyVersion: Int, $userAgreementVersion: Int, $publishMembership: Boolean) {
    updateConsentVersions(
      privacyPolicyVersion: $privacyPolicyVersion
      userAgreementVersion: $userAgreementVersion
      publishMembership: $publishMembership
    )
  }
`;
export const REGISTER = gql`
  mutation register(
    $privacyPolicyVersion: Int!
    $userAgreementVersion: Int!
    $publishMembership: Boolean
    $password: String
  ) {
    register(
      privacyPolicyVersion: $privacyPolicyVersion
      userAgreementVersion: $userAgreementVersion
      publishMembership: $publishMembership
      password: $password
    )
  }
`;

export const SEND = gql`
  mutation sendPost(
    $content: String!
    $translations: PostTranslationsInput
    $notificationChannels: [InputNotificationChannel]
    $blog: BlogInput
    $podcast: PodcastInput
    $poll: PollInput
    $event: EventInput
    $gallery: GalleryInput
    $video: VideoInput
    $publishDate: Date
    $company: CompanyInput
    $jobs: JobsInput
    $pushConfig: PushConfigInput
  ) {
    sendPost(
      content: $content
      translations: $translations
      notificationChannels: $notificationChannels
      blog: $blog
      podcast: $podcast
      poll: $poll
      event: $event
      gallery: $gallery
      video: $video
      publishDate: $publishDate
      company: $company
      jobs: $jobs
      pushConfig: $pushConfig
    ) {
      cursor
      node {
        ...PostData
      }
    }
  }
  ${FRAGMENT_POST_DATA}
`;

export const DELETE_POST = gql`
  mutation deletePost($id: ID!) {
    deletePost(id: $id)
  }
`;

export const PIN_POST = gql`
  mutation pinPost($id: ID!, $status: Boolean) {
    pinPost(id: $id, status: $status)
  }
`;

export const UPDATE_POST = gql`
  mutation updatePost(
    $id: ID!
    $content: String
    $poll: PollInput
    $event: EventInput
    $blog: BlogInput
    $gallery: GalleryInput
    $video: VideoInput
    $podcast: PodcastInput
    $translations: PostTranslationsInput
  ) {
    updatePost(
      id: $id
      content: $content
      poll: $poll
      event: $event
      blog: $blog
      gallery: $gallery
      video: $video
      podcast: $podcast
      translations: $translations
    ) {
      cursor
      node {
        ...PostData
      }
    }
  }
  ${FRAGMENT_POST_DATA}
`;

export const UPDATE_POST_PUBLISH_DATE = gql`
  mutation updatePostPublishDate($id: ID!, $publishDate: Date) {
    updatePostPublishDate(id: $id, publishDate: $publishDate) {
      cursor
      node {
        ...PostData
      }
    }
  }
  ${FRAGMENT_POST_DATA}
`;

export const CHANGE_POST_STATUS = gql`
  mutation changePostStatus($id: ID!, $status: String) {
    changePostStatus(id: $id, status: $status) {
      cursor
      node {
        ...PostData
      }
    }
  }
  ${FRAGMENT_POST_DATA}
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $displayName: String
    $contact: ContactInput
    $avatar: AssetInput
    $header: AssetInput
    $username: String
    $name: String
    $email: String
    $accessToken: String
    $publishMembership: Boolean
  ) {
    updateUser(
      displayName: $displayName
      contact: $contact
      avatar: $avatar
      header: $header
      username: $username
      name: $name
      email: $email
      accessToken: $accessToken
      publishMembership: $publishMembership
    ) {
      ...ProfileData
    }
  }
  ${FRAGMENT_PROFILE_DATA}
`;

export const CHANGE_MUST_REVIEW_INSTAGRAM_POST = gql`
  mutation changeMustReviewInstagramPosts($value: Boolean) {
    changeMustReviewInstagramPosts(value: $value)
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updateProfile($id: ID!, $pipedriveId: String, $permissions: [String]) {
    updateProfile(id: $id, pipedriveId: $pipedriveId, permissions: $permissions) {
      pipedriveId
    }
  }
`;

export const SAVE_LINK_COLLECTION = gql`
  mutation updateLinkCollection($id: ID!, $links: [LinkItemInput]!) {
    updateLinkCollection(id: $id, links: $links)
  }
`;

export const SET_PROFILE_VISIBILITY = gql`
  mutation setVisibility($visible: Boolean!) {
    setVisibility(visible: $visible)
  }
`;

export const UPDATE_MAP = gql`
  mutation updateMap($id: ID, $name: String, $description: String, $visible: Boolean) {
    updateMap(id: $id, name: $name, description: $description, visible: $visible) {
      id
      name
      description
      visible
      locations {
        id
        name
        type
        order
        location {
          name
          country
          city
          postalCode
          street
          state
          addition
          label
          geoPoint {
            lat
            lng
          }
        }
      }
    }
  }
`;

export const UPDATE_LOCATION = gql`
  mutation updateLocation($id: ID, $map: ID, $name: String, $type: String, $location: LocationInput) {
    updateLocation(id: $id, map: $map, name: $name, type: $type, location: $location) {
      id
      type
      order
      name
      location {
        name
        country
        city
        postalCode
        street
        state
        addition
        label
        geoPoint {
          lat
          lng
        }
      }
    }
  }
`;

export const DELETE_MAP = gql`
  mutation deleteMap($id: ID!) {
    deleteMap(id: $id)
  }
`;

export const DELETE_LOCATION = gql`
  mutation deleteLocation($id: ID!) {
    deleteLocation(id: $id)
  }
`;

export const UPDATE_LOCATION_ORDER = gql`
  mutation updateLocationOrder($map: ID!, $locations: [MapLocationInput]!) {
    updateLocationOrder(map: $map, locations: $locations)
  }
`;

export const ADD_ACCOUNT = gql`
  mutation addAccount($email: String!, $name: String!, $role: AccountRole) {
    addAccount(email: $email, name: $name, role: $role) {
      id
      email
      name
      role
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation deleteAccount($id: ID!) {
    deleteAccount(id: $id)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($id: ID!) {
    resetPassword(id: $id)
  }
`;

export const DELETE_PROFILE = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;

export const UPDATE_ACCOUNT_ROLE = gql`
  mutation updateAccountRole($id: ID!, $role: AccountRole) {
    updateAccountRole(id: $id, role: $role) {
      id
      role
    }
  }
`;

export const RESET_PASSWORT_REQUEST = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;

export const SIGNUP = gql`
  mutation signup(
    $email: String!
    $username: String!
    $displayName: String!
    $name: String
    $recipient: String
    $role: Role
    $pipedriveId: String
  ) {
    signup(
      email: $email
      username: $username
      displayName: $displayName
      name: $name
      recipient: $recipient
      role: $role
      pipedriveId: $pipedriveId
    )
  }
`;

export const UPDATE_TARGETS = gql`
  mutation updateTargets(
    $userId: ID!
    $universities: [ID]
    $affiliations: [String]
    $allUniversities: Boolean
    $roles: [String]
  ) {
    saveTargeting(
      id: $userId
      universities: $universities
      affiliations: $affiliations
      allUniversities: $allUniversities
      roles: $roles
    )
  }
`;

export const CREATE_CHANNEL = gql`
  mutation createChannel($userId: ID!, $channel: ID!) {
    createChannel(user: $userId, channel: $channel)
  }
`;

export const DELETE_CHANNEL = gql`
  mutation deleteChannel($userId: ID!, $channel: ID!) {
    deleteChannel(user: $userId, channel: $channel)
  }
`;

export const CONFIRM_EMAIL_APP_ACCOUNT = gql`
  mutation verifyEmail($token: String!) {
    verifyEmail(token: $token)
  }
`;

export const CONNECT_INSTAGRAM = gql`
  mutation connectInstagram($code: String!, $redirectUri: String!) {
      connectInstagram(code: $code, redirectUri: $redirectUri)
  }
`;

export const DISCONNECT_INSTAGRAM = gql`
  mutation disconnectInstagram {
    disconnectInstagram
  }
`;
export const UPDATE_USER_SETTINGS = gql`
  mutation updateUserSettings($settings: UserSettingsInput!) {
    updateUserSettings(settings: $settings) {
      skippedSetupSteps
    }
  }
`;

export const CREATE_NOTIFICATION_CHANNEL = gql`
  mutation createNotificationChannel($channelName: String!) {
    createNotificationChannel(channelName: $channelName)
  }
`;
