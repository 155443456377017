import React, { useState } from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';

import { Row, Col, Icon } from 'antd';

import { GET_PINNED_POST, PROFILE } from 'core/gql/queries';

import { Post, StatisticModal } from '../';

import pinnedImage from './images/pinned.svg';
import './style.css';

const PinnedPost = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [isExtended, setIsExtended] = useState(false);
  const { data = {}, loading, error } = useQuery(GET_PINNED_POST);
  const { data: profileData = {} } = useQuery(PROFILE);

  if (loading || error || !data.pinnedPost) {
    return null;
  }

  const { me = {} } = profileData;

  const canReadStatistics = (me.permissions || []).includes('READ_POST_STATISTICS');

  const canAdvertisePosts = (me.permissions || []).includes('ADVERTISE_POST');

  return (
    <div id="PinnedPost">
      <StatisticModal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        post={modalVisible ? data.pinnedPost : null}
      />
      <Row id="PinnedHeader" type="flex" align="middle">
        <Col span={3} style={{ textAlign: 'center' }}>
          <img src={pinnedImage} style={{ maxWidth: 48 }} alt="calender" />
        </Col>
        <Col span={13} offset={1} style={{ textAlign: 'left', marginTop: 5 }}>
          <div className="weight-bold" style={{ color: '#000' }}>
            Post gepinnt am {moment(data.pinnedPost.pinnedAt).format('DD. MMM YYYY [um] HH:mm')}
          </div>
          <div className="color-secondary overflow-ellipsis">{data.pinnedPost.content}</div>
        </Col>
        <Col span={7} style={{ textAlign: 'right', marginTop: 5 }}>
          {isExtended ? (
            <span className="clickable-primary" onClick={() => setIsExtended(false)}>
              Verbergen
              <Icon type="down" />
            </span>
          ) : (
            <span className="clickable-primary" onClick={() => setIsExtended(true)}>
              Anzeigen
              <Icon type="right" />
            </span>
          )}
        </Col>
      </Row>
      {isExtended && (
        <Post
          post={data.pinnedPost}
          renderStatisticButton={canReadStatistics}
          renderAdvertiseButton={canAdvertisePosts}
          onStatisticClick={() => setModalVisible(true)}
        />
      )}
    </div>
  );
};

export default PinnedPost;
