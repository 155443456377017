import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../images/uninow_logo_vertical_white.svg';
import background from '../../images/background.jpg';

import './style.css';

export default class ContentLoggedOut extends Component {
  render() {
    const contentStyle = {
      backgroundImage: `url(${background})`,
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
    };

    return (
      <div
        style={{
          height: '100vh',
          textAlign: 'center',
          overflowY: 'scroll',
          padding: '60px 15px',
          ...contentStyle,
        }}
        className="uninow-feed-content-loggedOut"
      >
        <img src={logo} alt="" style={{ paddingTop: '5%', paddingBottom: '50px', margin: 'auto' }} />
        {Array.isArray(this.props.children) ? this.props.children.map((elem) => elem) : this.props.children}
        <div style={{ position: 'absolute', bottom: 10, right: 10 }}>
          <a target="_blank" rel="noopener norefferer" href="https://legal.uninow.com/web/imprint/de/latest" style={{ color: 'white' }}>
            Impressum
          </a>
        </div>
      </div>
    );
  }
}
