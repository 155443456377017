import React, { Component } from 'react';

import ContentLoggedOut from '../../components/ContentLoggedOut';

class ErrorPage extends Component {
  render() {
    return (
      <ContentLoggedOut>
        <div>
          <h1 style={{ color: '#ffffff' }}>404</h1>
          <h2 style={{ color: '#ffffff' }}>Die Seite die du suchst wurde hier nicht gefunden.</h2>
        </div>
      </ContentLoggedOut>
    );
  }
}

export default ErrorPage;
