import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Mutation } from 'react-apollo';
import { Modal, Form, Input, Button, Message } from 'antd';
import { CHANGE_PASSWORD } from 'core/gql/mutations';

import './style.css';

class ChangePasswortModal extends Component {
  onCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  onClick = (callback) => {
    const { validateFields, setFields } = this.props.form;

    validateFields(async (err, values) => {
      if (!err) {
        const { oldPassword, newPassword, newPasswordRepeat } = values;

        if (oldPassword === newPassword) {
          setFields({
            newPassword: {
              errors: [new Error('Altes und neues Passwort dürfen nicht übereinstimmen')],
            },
          });
          return;
        }

        if (newPassword !== newPasswordRepeat) {
          setFields({
            newPasswordRepeat: {
              errors: [new Error('Passwörter stimmen nicht überein')],
            },
          });
          return;
        }

        const success = await callback({
          variables: { oldPassword, newPassword },
        });

        if (success) {
          Message.success('Passwort erfolgreich geändert');

          if (this.props.onSuccess) {
            this.props.onSuccess();
          }
        }
      }
    });
  };

  onError = (err) => {
    const { setFields } = this.props.form;

    if (err.message.indexOf('Old Password incorrect') !== -1) {
      setFields({
        oldPassword: {
          errors: [new Error('Falsches Passwort')],
        },
      });
      return;
    } else {
      Message.error('Passwort konnte nicht geändert werden');
    }
  };

  render() {
    const { visible } = this.props;
    const { getFieldDecorator } = this.props.form;

    const rules = [
      {
        required: true,
        message: 'Bitte gib ein Passwort ein',
      },
      {
        min: 8,
        message: 'Bitte gib mindestens 8 Zeichen ein',
      },
      {
        whitespace: true,
        message: 'Nicht nur Leerzeichen',
      },
    ];

    return (
      <div id="ChangePasswordModal">
        <Modal
          destroyOnClose={true}
          footer={null}
          getContainer={() => document.getElementById('ChangePasswordModal')}
          style={{ textAlign: 'center' }}
          onCancel={this.onCancel}
          visible={visible}
        >
          <h4 className="weight-medium">Passwort ändern</h4>
          <Form>
            <Form.Item className="align-left">
              {getFieldDecorator('oldPassword', {
                validateTrigger: 'onBlur',
                rules: rules,
              })(
                <div>
                  <div className="color-secondary weight-medium size-small">Altes Passwort</div>
                  <Input type="password" size="large" />
                </div>
              )}
            </Form.Item>
            <Form.Item className="align-left">
              {getFieldDecorator('newPassword', {
                validateTrigger: 'onBlur',
                rules: rules,
              })(
                <div>
                  <div className="color-secondary weight-medium size-small">Neues Passwort</div>
                  <Input type="password" size="large" />
                </div>
              )}
            </Form.Item>
            <Form.Item className="align-left">
              {getFieldDecorator('newPasswordRepeat', {
                validateTrigger: 'onBlur',
                rules: rules,
              })(
                <div>
                  <div className="color-secondary weight-medium size-small">Neues Passwort wiederholen</div>
                  <Input type="password" size="large" />
                </div>
              )}
            </Form.Item>
            <br />
            <Form.Item>
              <Mutation onError={this.onError} mutation={CHANGE_PASSWORD}>
                {(send, { loading }) => (
                  <Button loading={loading} type="primary" htmlType="submit" onClick={() => this.onClick(send)}>
                    Ändern
                  </Button>
                )}
              </Mutation>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

ChangePasswortModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onCancel: PropTypes.func,
};

export default Form.create()(ChangePasswortModal);
