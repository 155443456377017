import React, { useEffect, useRef } from 'react';

// import update from 'immutability-helper';
import { notification } from 'antd';

import { useDispatch } from 'react-redux';
import { close, complete } from '../core/redux/actions/progress';

import { useMutation, useQuery } from '@apollo/react-hooks';
import { PROFILE, PROFILE_APP_CONNECTED } from 'core/gql/queries';
// import { APP_CONNECTED_SUBSCRIPTION } from 'core/gql/subscriptions';
import { freshmanGlobalChannelId } from '../core/constants';
import { UPDATE_USER_SETTINGS } from '../core/gql/mutations';

const skippedStep = (settings, key) => {
  return settings?.skippedSetupSteps?.includes(key) ?? false;
};
const PROGRESS_SECTIONS = [
  {
    title: 'Profil',
    data: [
      {
        title: 'Passwort festlegen',
        description: 'Sichere euren Account',
        // MIGRATION listen to state
        checked: () => true,
        onClick: (history) => (history ? () => history.push('/settings/profile') : null),
      },
      {
        title: 'Profilbild hochladen',
        description: 'Euer Logo als Erkennungsmerkmal',
        checked: ({ avatar }) => avatar && avatar.large && !avatar.placeholder,
        onClick: (history) => (history ? () => history.push('/', { editMode: true }) : null),
      },
      {
        title: 'Titelbild hochladen',
        description: 'Gestaltet euer Profil',
        checked: ({ header }) => header && header.large && !header.placeholder,
        onClick: (history) => (history ? () => history.push('/', { editMode: true }) : null),
      },
      {
        title: 'Kontaktdaten hinterlegen',
        description: 'E-Mail, Telefon, Webseite & Adresse',
        checked: ({ contact }) => {
          const { email, telephoneNumber, website, location } = contact || {};

          return !!(
            email ||
            telephoneNumber ||
            website ||
            (location && ['geoPoint', 'label', 'city', 'addition'].some((key) => location[key]))
          );
        },
        onClick: (history) => (history ? () => history.push('/', { editMode: true }) : null),
      },
    ],
  },
  {
    title: 'Aktivieren',
    data: [
      {
        title: 'Online schalten',
        description: 'Werdet sichtbar in der App',
        checked: ({ visible }) => visible,
        onClick: (history) => (history ? () => history.push('/', { openMenu: true }) : null),
      },
      {
        title: 'Willkommenspost verfassen',
        description: 'Begrüßt eure Studierenden bei UniNow',
        checked: ({ postsCount = 0 }) => postsCount > 0,
        onClick: (history) => (history ? () => history.push('/', { focusPostCreator: true }) : null),
      },
    ],
  },
  {
    title: 'Bonus',
    data: [
      {
        key: 'freshman',
        title: 'Erstsemesterbereich anlegen',
        description: 'Erstellt Karten und Linksammlungen',
        checked: ({ freshmanInUse, settings }) => freshmanInUse || skippedStep(settings, 'freshman'),
        onClick: (history) => (history ? () => history.push('/freshman') : null),
      },
      {
        key: 'addAccounts',
        title: 'Accounts hinzufügen',
        description: 'Verwaltet euer Profil gemeinsam',
        checked: ({ accounts = [], settings }) => accounts.length > 1 || skippedStep(settings, 'addAccounts'),
        onClick: (history) => (history ? () => history.push('/settings/profileAccount') : null),
      },
      {
        key: 'connectApp',
        title: 'Login mit der UniNow-App',
        description: 'Teilt unterwegs Beiträge',
        checked: ({ appConnected, settings }) => skippedStep(settings, 'connectApp'), //!!appConnected ||
        onClick: () => () =>
          notification.info({
            duration: 5,
            message: 'Login in der App',
            description:
              'Unter "Einstellungen" > "Login verbinden" kann du dich in der UniNow App mit deinem Account anmelden.',
          }),
      },
      {
        key: 'connectInstagram',
        title: 'Instagram verknüpfen',
        description: 'Spiegelt eure Beiträge von Instagram',
        checked: ({ instagramConnection, settings }) =>
          instagramConnection?.active || skippedStep(settings, 'connectInstagram'),
        onClick: (history) => (history ? () => history.push('/settings/profile') : null),
      },
    ],
  },
];

export default function (history = null) {
  const { data, loading /* subscribeToMore */ } = useQuery(PROFILE);
  const [updateUserSettings] = useMutation(UPDATE_USER_SETTINGS);

  useQuery(PROFILE_APP_CONNECTED, {
    pollInterval: !data?.me?.appConnected ? 10000 : 0,
  });

  const { me = {} } = data || {};
  const skippedSetupSteps = me.settings?.skippedSetupSteps ?? [];

  const { notificationChannels = [] } = me;
  const hasFreshmanAccess = notificationChannels.some(({ globalChannel }) => globalChannel === freshmanGlobalChannelId);

  const ignoreItem = (item) => {
    updateUserSettings({
      variables: {
        settings: { skippedSetupSteps: [...skippedSetupSteps, item.key] },
      },
      refetchQueries: [{ query: PROFILE }],
      optimisticResponse: {
        updateUserSettings: {
          __typename: 'UserSettings',
          skippedSetupSteps: [...skippedSetupSteps, item.key],
        },
      },
    });
  };
  const sections = PROGRESS_SECTIONS.map((section) => ({
    ...section,
    data: section.data
      .filter((item) => hasFreshmanAccess || item.key !== 'freshman')
      .map((item) => ({
        ...item,
        value: 1,
        checked: item.checked ? item.checked(me) : false,
        onClick: item.onClick ? item.onClick(history) : null,
        onHide: section.title === 'Bonus' ? () => ignoreItem(item) : undefined,
      })),
  }));

  const { itemCount, checkedItems } = sections.reduce(
    ({ itemCount, checkedItems }, { data = [] }) => ({
      itemCount: itemCount + data.length,
      checkedItems: checkedItems + data.filter(({ checked }) => checked).length,
    }),
    { itemCount: 0, checkedItems: 0 }
  );

  const dispatch = useDispatch();
  const previousCheckedItem = useRef(0);
  useEffect(() => {
    if (loading) {
      return;
    }
    if (checkedItems >= itemCount) {
      switch (previousCheckedItem.current) {
        case 0:
          dispatch(close());
          break;
        case itemCount:
          break;
        default:
          dispatch(complete());
          break;
      }
    }
    previousCheckedItem.current = itemCount;
  }, [loading, checkedItems]);

  return { sections, itemCount, checkedItems, loading };
}
