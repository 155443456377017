import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';

function ScriptLoader({ src, children }) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const scriptNode = document.createElement('script');
    scriptNode.type = 'text/javascript';
    scriptNode.src = src;
    scriptNode.addEventListener('load', () => {
      console.log('script loaded');
      setLoading(false);
    });

    document.body.appendChild(scriptNode);
  }, [src]);
  return loading ? <Spin /> : children;
}

export default ScriptLoader;
