import React, { useState } from 'react';
import { Button, Col, Form, Row, Select } from 'antd';
import { emergencyGlobalChannelId, freshmanGlobalChannelId } from '../../../core/constants';

const colorScheme = {
  roles: { label: 'Rollen', color: '#5187f8', backgroundColor: '#A7C4FF' },
  freshman: { label: 'Erstis', color: '#48c54e', backgroundColor: '#87C98A' },
  emergency: {
    label: 'Notfall',
    color: '#fd5d5a',
    backgroundColor: '#F48C8A',
  },
};
function ChannelSelection({ handleChannelChange, notificationChannels, options, onAddClick }) {
  const [loading, setLoading] = useState(false);
  const onChange = async (values) => {
    setLoading(true);
    try {
      await handleChannelChange(values);
    } finally {
      setLoading(false);
    }
  };
  const getColor = (channel) => {
    if (channel.targeting?.roles?.length > 0) {
      return colorScheme.roles;
    }
    if (channel.id === emergencyGlobalChannelId) {
      return colorScheme.emergency;
    }
    if (channel.id === freshmanGlobalChannelId) {
      return colorScheme.freshman;
    }
    return {};
  };

  return (
    <Form.Item label="Channels">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {Object.values(colorScheme).map(({ label, backgroundColor }) => (
          <>
            <div
              style={{
                backgroundColor,
                width: 15,
                height: 15,
                borderRadius: 15,
                marginLeft: 5,
                marginRight: 5,
              }}
            ></div>
            {label}
          </>
        ))}
      </div>
      <Row>
        <Col span={18}>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Wähle einen Channel"
            onChange={onChange}
            loading={loading}
            disabled={loading}
            value={notificationChannels}
            filterOption={(input, option) => {
              const sanitizedInput = input.replace('#', '').toLowerCase();
              return option.props.label?.toLowerCase().includes(sanitizedInput);
            }}
            optionLabelProp="title"
          >
            {options.map((channel) => {
              const colors = getColor(channel);
              return (
                <Select.Option
                  key={channel.id}
                  label={channel.name}
                  style={{ backgroundColor: colors.backgroundColor }}
                  title={<span style={{ color: colors.color }}>#{channel.name}</span>}
                >
                  #{channel.name}
                </Select.Option>
              );
            })}
          </Select>
        </Col>
        <Col span={1} offset={1}>
          <Button type="primary" onClick={onAddClick}>
            Add
          </Button>
        </Col>
      </Row>
    </Form.Item>
  );
}

export default ChannelSelection;
