import React, { Component } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import { Spin, Form, Input, Button, Modal, Select, List, Icon, notification, Popconfirm, Tooltip, Popover } from 'antd';
import { Query, withApollo } from 'react-apollo';

import { PROFILE_ACCOUNTS, PROOF_EMAIL } from 'core/gql/queries';
import { ADD_ACCOUNT, DELETE_ACCOUNT, UPDATE_ACCOUNT_ROLE } from 'core/gql/mutations';

import { PermissionList } from 'components';

const ROLE_MAP = {
  SUPERADMIN: 'UniNow Intern',
  ADMIN: 'Account Administrator',
  DRAFT: 'Content-Ersteller (eingeschränkt)',
  USER: 'Account Nutzer',
};

class ProfileAccounts extends Component {
  state = {
    showModal: false,
    account: {},
    loading: false,
    validateStatusMail: null,
  };

  addUser = async () => {
    await this.setState({ loading: true });
    const { validateFields } = this.props.form;

    validateFields(async (errors, values) => {
      if (errors) {
        this.setState({ loading: false });
        return;
      }
      try {
        const result = await this.props.client.mutate({
          mutation: ADD_ACCOUNT,
          variables: {
            email: values.email,
            role: values.accountRole,
            name: values.name,
          },
        });
        const { me } = this.props.client.readQuery({ query: PROFILE_ACCOUNTS });
        this.props.client.writeQuery({
          query: PROFILE_ACCOUNTS,
          data: {
            me: update(me, {
              accounts: {
                $push: [result.data.addAccount],
              },
            }),
          },
        });
        notification.success({
          message: 'Account wurde erfolgreich erstellt',
          description: 'Dem Accountinhaber wurde an die angegebenen E-Mail eine Nachricht gesendet.',
        });
        this.setState({ loading: false, showModal: false, account: {} });
      } catch (err) {
        notification.error({
          message: 'Account konnte nicht erstellt werden',
          description: 'Bitte kontaktiere uns unter support@uninow.de.',
        });
        this.setState({ loading: false });
      }
    });
  };

  updateUser = async () => {
    await this.setState({ loading: true });
    const { validateFields } = this.props.form;
    const { id, role } = this.state.account;

    validateFields(async (errors, values) => {
      if (errors) {
        this.setState({ loading: false });
        return;
      }
      try {
        if (role !== values.accountRole) {
          await this.props.client.mutate({
            mutation: UPDATE_ACCOUNT_ROLE,
            variables: {
              id,
              role: values.accountRole,
            },
          });
        }

        notification.success({
          message: 'Account wurde erfolgreich aktualisiert',
        });
        await this.setState({ loading: false, showModal: false, account: {} });
      } catch (err) {
        notification.error({
          message: 'Account konnte nicht aktualisiert werden',
          description: 'Bitte kontaktiere uns unter support@uninow.de.',
        });
        this.setState({ loading: false });
      }
    });
  };

  deleteUser = async (account) => {
    try {
      await this.props.client.mutate({
        mutation: DELETE_ACCOUNT,
        variables: {
          id: account.id,
        },
      });
      const { me } = this.props.client.readQuery({ query: PROFILE_ACCOUNTS });
      const index = me.accounts.findIndex((elem) => elem.id === account.id);
      this.props.client.writeQuery({
        query: PROFILE_ACCOUNTS,
        data: {
          me: update(me, {
            accounts: {
              $set: [...me.accounts.slice(0, index), ...me.accounts.slice(index + 1)],
            },
          }),
        },
      });
      notification.success({
        message: 'Account erfolgreich gelöscht',
      });
    } catch (err) {
      notification.error({
        message: 'Account konnte nicht gelöscht werden',
        description: 'Bitte kontaktiere uns unter support@uninow.de.',
      });
    }
  };

  proofEmail = async (value, ownEmail, callback) => {
    if (value === ownEmail) callback();
    const res = await this.props.client.query({
      query: PROOF_EMAIL,
      variables: { email: value },
    });

    if (res && res.data && res.data.checkEmail === true) {
      callback();
    } else {
      callback('E-Mail bereits vergeben');
    }
  };

  validateMail = async () => {
    this.setState({ validateStatusMail: 'validating' });

    this.validateMailDebounce();
  };

  validateMailDebounce = debounce(async () => {
    const { validateFields } = this.props.form;

    validateFields(['email'], (error) => {
      if (error) {
        this.setState({ validateStatusMail: 'error' });
      } else {
        this.setState({ validateStatusMail: 'success' });
      }
    });
  }, 500);

  renderUserItem = (user) => {
    const { accountId } = this.context;

    const itsamemario = user.id === accountId;

    return (
      <List.Item
        actions={
          itsamemario
            ? [
                <Icon type="edit" style={{ cursor: 'not-allowed' }} />,
                <Icon type="delete" style={{ cursor: 'not-allowed' }} />,
              ]
            : [
                <Tooltip title="Bearbeiten">
                  <div className="clickable-primary">
                    <Icon type="edit" onClick={() => this.setState({ showModal: true, account: user })} />
                  </div>
                </Tooltip>,
                <Tooltip title="Löschen">
                  <Popconfirm
                    title="Bist du dir sicher den Account zu löschen?"
                    onConfirm={() => this.deleteUser(user)}
                    okText="Ja"
                    cancelText="Abbrechen"
                  >
                    <div className="clickable-primary">
                      <Icon type="delete" />
                    </div>
                  </Popconfirm>
                </Tooltip>,
              ]
        }
      >
        <List.Item.Meta description={user.email} title={user.name ? user.name : 'Kein Name gesetzt'} />
        <Popover content={<PermissionList role={user.role} />}>{ROLE_MAP[user.role] || 'Keine Rolle gesetzt'}</Popover>
      </List.Item>
    );
  };

  render() {
    const formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13, offset: 2 },
        md: { span: 15, offset: 2 },
      },
    };
    const { validateStatusMail } = this.state;
    const { id, email = '', role = 'USER', name = '' } = this.state.account;
    const { getFieldDecorator, getFieldValue } = this.props.form;

    const selectedRole = getFieldValue('accountRole') || role;

    return (
      <div>
        <Modal
          destroyOnClose
          title={id ? 'Nutzer bearbeiten' : 'Neuen Nutzer hinzufügen'}
          cancelText="Abbrechen"
          visible={this.state.showModal}
          onCancel={() => this.setState({ showModal: false })}
          onOk={() => (id ? this.updateUser() : this.addUser())}
          confirmLoading={this.state.loading}
        >
          <Form>
            <Form.Item label="Name" hasFeedback={true}>
              {getFieldDecorator('name', {
                validateTrigger: 'onBlur',
                initialValue: name,
                rules: [
                  {
                    required: !id,
                    message: 'Bitte gib eine Namen ein',
                  },
                ],
              })(<Input disabled={!!id} placeholder="Name" />)}
            </Form.Item>
            <Form.Item label="E-Mail-Adresse" hasFeedback={true} validateStatus={validateStatusMail}>
              {getFieldDecorator('email', {
                initialValue: email,
                validateTrigger: null,
                validateFirst: true,
                rules: [
                  {
                    type: 'email',
                    message: 'Keine valide E-Mail-Adresse',
                  },
                  {
                    required: !id,
                    message: 'Bitte gib eine E-Mail-Adresse ein',
                  },
                  {
                    validator: (rule, value, callback) => this.proofEmail(value, email, callback),
                  },
                ],
              })(<Input type="email" disabled={!!id} placeholder="E-Mail-Adresse" onChange={this.validateMail} />)}
            </Form.Item>
            <Form.Item label="Account Typ">
              {getFieldDecorator('accountRole', {
                initialValue: role,
                rules: [
                  {
                    required: true,
                    message: 'Bitte gib ein Account Typ an',
                  },
                ],
              })(
                <Select>
                  <Select.Option value="USER">Account Nutzer</Select.Option>
                  <Select.Option value="DRAFT">Content-Ersteller (eingeschränkt)</Select.Option>
                  <Select.Option value="ADMIN">Account Administrator</Select.Option>
                </Select>
              )}
            </Form.Item>

            <PermissionList role={selectedRole} />
          </Form>
        </Modal>
        <Query query={PROFILE_ACCOUNTS}>
          {({ loading, error, data }) => {
            if (loading) {
              return (
                <div style={{ textAlign: 'center' }}>
                  <Spin />
                </div>
              );
            }
            console.log('err', error, data);
            if (error || !data.me) {
              return (
                <div style={{ textAlign: 'center' }}>
                  Deine Einstellungen konnten leider nicht geladen werden.
                  <br />
                  <br />
                  Versuche es doch später noch einmal oder kontaktiere uns über{' '}
                  <a href="mailto:support@uninow.de">support@uninow.de</a>.
                </div>
              );
            }

            const { accounts } = data.me;

            return (
              <Form>
                <div className="headline weight-bold size-large">Accounts</div>
                {accounts.length <= 1 ? (
                  <div style={{ textAlign: 'center', marginBottom: 20 }}>
                    Du hast bisher keine weiteren Accounts zu deinem Profile hinzugefügt.
                  </div>
                ) : (
                  <List
                    style={{ marginBottom: 20 }}
                    itemLayout="horizontal"
                    dataSource={accounts}
                    renderItem={(account) => this.renderUserItem(account)}
                  />
                )}

                <Form.Item {...formLayout} label=" " colon={false}>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    onClick={() => this.setState({ showModal: true, account: {} })}
                  >
                    Account hinzufügen
                  </Button>
                </Form.Item>
              </Form>
            );
          }}
        </Query>
      </div>
    );
  }
}

ProfileAccounts.contextTypes = {
  accountId: PropTypes.string.isRequired,
};

export default Form.create()(withApollo(ProfileAccounts));
