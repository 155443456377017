import React, { Component } from 'react';
import { Row } from 'antd';
import { Link } from 'react-router-dom';

import './styles.css';

export const FOOTER_HEIGHT = 30;

class Footer extends Component {
  render() {
    return (
      <Row
        id="footerContainer"
        type="flex"
        justify="start"
        align="middle"
        style={{
          height: FOOTER_HEIGHT,
          position: 'fixed',
          bottom: 0,
          textAlign: 'right',
          width: '100%',
          backgroundColor: 'white',
        }}
      >
          <a target="_blank" rel="noopener noreferrer" href="https://legal.uninow.com/feed/terms-of-use-uninow-feed/de/latest" id="footerItem">Nutzervereinbarung</a>
          <a target="_blank" rel="noopener noreferrer" href="https://legal.uninow.com/feed/privacy-policy-uninow-feed/de/latest" id="footerItem">Datenschutzerklärung</a>
          <a target="_blank" rel="noopener noreferrer" href="https://legal.uninow.com/web/imprint/de/latest" id="footerItem">Impressum</a>
      </Row>
    );
  }
}

export default Footer;
