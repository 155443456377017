import React from 'react';
import { PropTypes } from 'prop-types';

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { BackTop, Button, Icon } from 'antd';
import Navbar, { NAVBAR_HEIGHT } from '../components/Navbar';
import Footer, { FOOTER_HEIGHT } from '../components/Footer';

import Login from '../screens/Login/index';
import Overview from '../screens/Overview/index';
import Settings from '../screens/Settings/index';
import Admin from '../screens/Admin/index';
import Freshman from '../screens/Freshman/index';
import PasswordResetUpdate from '../screens/PasswortReset/Update';
import PasswordResetIntro from '../screens/PasswortReset/Intro';
import ErrorPage from '../screens/ErrorPages/404';
import Register from '../screens/Register/index';
import ConfirmAppAccount from '../screens/ConfirmAppAccount/index';
import RegistrationModal from '../components/RegistrationModal/index';
import InstagramAuthScreen from '../screens/Instagram/auth';
import MediaStatus from '../screens/MediaStatus/index';

import { CongratulationsModal } from 'components';
import ScriptLoader from '../components/ScriptLoader';
import ErrorHandler from './ErrorHandler';

const PrivateRoute = (
  { requires = [], props = {}, withRegistrationModal = false, user = {}, component: Component, ...rest },
  context
) => {
  return (
    <Route
      {...rest}
      render={(renderProps) =>
        context.isAuthenticated ? (
          requires.length === 0 || requires.includes(context.accountRole) ? (
            <div style={user.passwordSet || !withRegistrationModal ? {} : { filter: 'blur(2px)' }}>
              <Component {...renderProps} {...props} />
              {withRegistrationModal && <RegistrationModal visible={!user.passwordSet} />}
            </div>
          ) : (
            <ErrorPage />
          )
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.node,
};

PrivateRoute.contextTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  role: PropTypes.string,
  accountRole: PropTypes.string,
};

const PageRouter = (props, context) => (
  <Router>
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/media/status" component={MediaStatus} />
      <Route path="/set/:token" render={(props) => <PasswordResetUpdate setPassword={true} {...props} />} />
      <Route path="/register" component={Register} />
      <Route path="/reset/:token" component={PasswordResetUpdate} />
      <Route path="/reset" component={PasswordResetIntro} />
      <Route path="/confirm/:token" component={ConfirmAppAccount} />
      <PrivateRoute
        withRegistrationModal={true}
        user={props.user}
        component={() => (
          <div
            style={{
              overflow: 'hidden',
              position: 'relative',
              height: '100vh',
            }}
          >
            {context && context.role && context.role === 'STUDENT_COUNCIL' && <CongratulationsModal />}
            <Navbar />
            <div
              id="ScrollContainer"
              style={{
                position: 'absolute',
                top: NAVBAR_HEIGHT,
                bottom: FOOTER_HEIGHT,
                left: 0,
                right: 0,
                overflowY: 'scroll',
                overflowX: 'hidden',
              }}
            >
              <ErrorHandler>
                <ScriptLoader
                  src={
                    'https://maps.googleapis.com/maps/api/js?key=AIzaSyAkdtiZMaugmqip5tyYmgRAJ_851Ubx3bs&libraries=places&language=de&region=GER'
                  }
                >
                  <Switch>
                    <PrivateRoute exact path="/" component={Overview} />
                    <PrivateRoute path="/instagram/auth" component={InstagramAuthScreen} />
                    <PrivateRoute path="/settings/:subpage?" component={Settings} />
                    <PrivateRoute path="/freshman/:subpage?" component={Freshman} />
                    <PrivateRoute path="/admin/:subpage?" component={Admin} requires={['SUPERADMIN']} />

                    <Route
                      path={['/posts', '/maps', '/links', '/accessToken']}
                      render={({ location }) => <Redirect to={`/freshman${location.pathname}`} />}
                    />

                    <Route component={ErrorPage} />
                  </Switch>
                </ScriptLoader>
              </ErrorHandler>
              <BackTop
                target={() => document.getElementById('ScrollContainer')}
                visibilityHeight="600"
                style={{
                  position: 'fixed',
                  bottom: 40,
                  right: 80,
                  zIndex: 40,
                  height: 'auto',
                  width: 'auto',
                }}
              >
                <Button type="primary">
                  <Icon
                    style={{
                      fontSize: 20,
                      lineHeight: '20px',
                      height: 30,
                    }}
                    type="up"
                  />
                </Button>
              </BackTop>
            </div>
            <Footer />
          </div>
        )}
      />
    </Switch>
  </Router>
);

PageRouter.contextTypes = {
  role: PropTypes.string,
  profileId: PropTypes.string,
};

const mapStateToProps = ({ user }) => ({
  user: user.userData,
  accountId: user.accountId,
});

export default connect(mapStateToProps)(PageRouter);
