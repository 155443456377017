import update from 'immutability-helper';

import { CHANGE_FOCUS, UPDATE_PREVIEW, RESET_PREVIEW, SET_PREVIEW_LANGUAGE } from '../actions/preview.js';

function preview(state = { focus: true, post: null, language: 'de' }, action) {
  switch (action.type) {
    case CHANGE_FOCUS: {
      return update(state, {
        focus: {
          $set: action.focus,
        },
      });
    }
    case UPDATE_PREVIEW: {
      return update(state, {
        post: {
          $set: action.post,
        },
      });
    }
    case RESET_PREVIEW: {
      return update(state, {
        post: {
          $set: null,
        },
      });
    }
    case SET_PREVIEW_LANGUAGE: {
      return update(state, {
        language: {
          $set: action.lang,
        },
      });
    }
    default:
      return state;
  }
}

export default preview;
