import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NPSFeedback as NPS } from '@uninow/user-feedback-client-react-dom';

const NPSFeedback = (props, context) => {
  return context.isAuthenticated && context.profileId && props.accountId ? (
    <NPS
      appID="c65a8bb6-d53c-46eb-858b-c891380068ae"
      userID={props.accountId}
      locale={'de'}
      position={'rightBottom'}
    ></NPS>
  ) : null;
};

NPSFeedback.contextTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  profileId: PropTypes.string,
};

const mapStateToProps = ({ user }) => ({
  user: user.userData,
  accountId: user.accountId,
});

export default connect(mapStateToProps)(NPSFeedback);
