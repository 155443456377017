import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { Query } from 'react-apollo';
import { PROFILE_INFO } from 'core/gql/queries';

import {
  InfoBox,
  ChangelogNotification,
  ProfileOverview,
  PostCreator,
  PostList,
  Preview,
  ProgressPost,
  ProgressContainer,
  PinnedPost,
} from 'components';

import ThreeColumnLayout from '../../layouts/ThreeColumnLayout';

class Overview extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { post } = nextProps;

    if (post && (!this.props.post || post.id !== this.props.post.id)) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  componentDidUpdate() {
    const { location, history } = this.props;

    const focusPostCreator = history.action === 'PUSH' && location.state && location.state.focusPostCreator;

    if (focusPostCreator) {
      const element = document.getElementById('PostCreator');

      element && element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  render() {
    const { role } = this.context;
    const { post, closedProgress = false, successModal = false, location, history, user } = this.props;

    const progressTrackingActive = role === 'STUDENT_COUNCIL' && !closedProgress && !successModal;

    const editMode = history.action === 'PUSH' && location.state && location.state.editMode;

    return (
      <div>
        <Query query={PROFILE_INFO}>
          {({ loading, error, data }) => {
            if (loading || error || !data.me) {
              return null;
            }

            const { visible, instagramConnection } = data.me;

            if (!user.passwordSet) {
              return null;
            }

            const infos = [<ChangelogNotification key="changelog" />];

            if (visible !== true) {
              infos.push(
                <InfoBox
                  key="visible"
                  message="Profil offline"
                  description="Um euer Profil online zu schalten, einfach im Menü oben rechts den “Online” Schalter aktivieren"
                />
              );
            }

            if (instagramConnection?.error) {
              infos.push(
                <InfoBox
                  key="instagram"
                  message="Instagram Verknüpfung ungültig"
                  description="Unter Einstellungen kannst du das Feed-Profil erneut mit Instagram verknüpfen."
                />
              );
            }

            return infos;
          }}
        </Query>
        <ThreeColumnLayout>
          <>
            <ProfileOverview editMode={editMode} />
          </>
          <>
            <PostCreator post={post} />
            <br />
            <PinnedPost />
            <PostList />
          </>
          <>
            <Preview />
          </>
        </ThreeColumnLayout>
        {progressTrackingActive && <ProgressContainer />}
      </div>
    );
  }
}

Overview.contextTypes = {
  role: PropTypes.string,
};

const mapStateToProps = ({ post, progress, user }) => ({
  post: post.post,
  closedProgress: progress.closed,
  successModal: progress.successModal,
  user: user.userData,
});

export default connect(mapStateToProps)(Overview);
