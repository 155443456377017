import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Menu, Icon } from 'antd';

import './style.css';

class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPage: props.defaultItem ? props.defaultItem : props.items[0].key,
    };
  }

  navigate = ({ key }) => {
    this.props.history.push(`${key}`);
  };

  render() {
    const { items } = this.props;
    const { subpage = items[0].key } = this.props.match.params;

    return (
      <div id="SideMenu">
        <Menu mode="inline" selectedKeys={[subpage]} onSelect={this.navigate}>
          {items.map((item) => (
            <Menu.Item key={item.key}>
              <Icon type={item.icon} theme="outlined" />
              {item.title}
            </Menu.Item>
          ))}
        </Menu>
      </div>
    );
  }
}

SideMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    })
  ),
};

export default withRouter(SideMenu);
