import useBreakpoint from 'hooks/useBreakpoint';

export default function (props) {
  const { breakpoint, breakpoints } = useBreakpoint();

  let render = null;
  for (let i = 0; i < breakpoints.length; i += 1) {
    const [key] = breakpoints[i];

    render = props[key] !== undefined ? props[key] : render;

    if (key === breakpoint) {
      break;
    }
  }

  return render;
}
