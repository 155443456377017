import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Icon } from 'antd';

class ScrollTopButton extends Component {
  state = {
    scrollTopShow: false,
  };

  scrollTop = () => {
    if (window.scrollTo) {
      window.scrollTo(0, 0);
    } else {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
    this.setState({
      scrollTopShow: false,
    });
  };

  componentDidMount() {
    window.onscroll = () => {
      this.onScroll();
    };
  }

  onScroll = () => {
    const { scrollTop } = this.props;
    if (
      !this.state.scrollTopShow &&
      (document.body.scrollTop > scrollTop || document.documentElement.scrollTop > scrollTop)
    ) {
      this.setState({
        scrollTopShow: true,
      });
    } else if (
      this.state.scrollTopShow &&
      ((document.body.scrollTop <= scrollTop && document.body.scrollTop !== 0) ||
        (document.documentElement.scrollTop <= scrollTop && document.documentElement.scrollTop !== 0))
    ) {
      this.setState({
        scrollTopShow: false,
      });
    }
  };

  render() {
    const child = this.props.children ? (
      this.props.children
    ) : (
      <Button type="primary" onClick={() => this.scrollTop()}>
        <Icon
          style={{
            fontSize: 20,
            lineHeight: '20px',
            height: 30,
          }}
          type="up"
        />
      </Button>
    );
    return <div style={this.props.style}>{this.state.scrollTopShow && child}</div>;
  }
}

ScrollTopButton.propTypes = {
  scrollTop: PropTypes.number,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

ScrollTopButton.defaultProps = {
  scrollTop: 750,
  style: {
    position: 'fixed',
    bottom: 40,
    right: 80,
    zIndex: 40,
  },
};

export default ScrollTopButton;
