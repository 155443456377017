import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Icon, Message } from 'antd';

import ImageDropzone from '../../../../ImageDropzone';

import { loadFromFile } from '../../../../../core/image';

const MAX_IMAGES_SIZE = 35000000; // 35MB

class Gallery extends Component {
  constructor(props) {
    super(props);

    let { images = [] } = props.data || {};
    if (props.data?.translations?.images) images = props.data?.translations?.images?.[props.language] || [];

    this.state = {
      images,
    };
  }

  onImageFilesSelected = async (files) => {
    const { setFieldsValue } = this.props.form;
    const { images } = this.state;

    const newFiles = await Promise.all(files.map(loadFromFile));

    const selectedImages = images.concat(newFiles).slice(0, 10);

    const filesSize = selectedImages.reduce((acc, curr) => {
      acc += curr.data.size;
      return acc;
    }, 0);

    if (filesSize > MAX_IMAGES_SIZE) {
      return Message.error(
        `Die Größe aller Bilder soll das Limit von ${MAX_IMAGES_SIZE / 1000000}MB nicht überschreiten.`
      );
    }

    setFieldsValue({
      [`attachment.translations.images.${this.props.language}`]: selectedImages,
    });

    this.setState({
      images: selectedImages,
    });
  };

  removeImage = (index) => {
    const { setFieldsValue } = this.props.form;
    const { images } = this.state;

    const slicedImages = [...images.slice(0, index), ...images.slice(index + 1)];

    setFieldsValue({
      [`attachment.translations.images.${this.props.language}`]: slicedImages,
    });

    this.setState({
      images: slicedImages,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { images } = this.state;

    return (
      <Row type="flex" align="middle">
        {getFieldDecorator(`attachment.translations.images.${this.props.language}`, { initialValue: images })(<div />)}
        {images.map((image, index) => (
          <Col span={8} key={image.name || image.large}>
            <img className="gallery-image" src={image.preview || image.large} alt="" />
            <div className="gallery-overlay">
              <div className="inner-overlay-two">
                <div className="inner-overlay-three" onClick={() => this.removeImage(index)}>
                  <Icon type="close" />
                </div>
              </div>
            </div>
          </Col>
        ))}
        <Col span={images.length >= 10 ? 0 : 24 - ((images.length * 8) % 24)} style={{ height: 100 }}>
          <ImageDropzone icon="plus" multiple={true} onFileSelected={this.onImageFilesSelected} />
        </Col>
      </Row>
    );
  }
}

Gallery.propTypes = {
  form: PropTypes.object.isRequired,
  language: PropTypes.string,
};

export default Gallery;
