import React, { Component } from 'react';
import { Icon, Spin, List, Modal, Popover, Popconfirm, Button, Input, message } from 'antd';
import { DELETE_ACCOUNT, RESET_PASSWORD } from 'core/gql/mutations';
import { GET_REGISTER_LINK } from 'core/gql/queries';
import PropTypes from 'prop-types';
import './../../../components/PostList/style.css';

class AccountsList extends Component {
  state = {
    authorRole: null,
    showPasswordResetModal: false,
    accounts: this.props.dataSource,
  };

  copyLinkToClipboard = (account) => {
    const inputElement = this[`input${account.id}`];

    if (inputElement) {
      inputElement.select();
      document.execCommand('copy');

      message.success('Erfolgreich kopiert.');
    }
  };

  resetPasswordAndGetRegisterLink = async (account) => {
    const { client } = this.props;
    const { accounts = [] } = this.state;

    try {
      this.setState({
        [`loading${account.id}`]: true,
        showPasswordResetModal: true,
      });

      await client.mutate({
        mutation: RESET_PASSWORD,
        variables: { id: account.id },
      });

      const { data } = await client.mutate({
        mutation: GET_REGISTER_LINK,
        variables: { id: account.id },
      });

      this.setState({
        [`loading${account.id}`]: false,
        accounts: accounts.map((acc) => {
          if (acc.id === account.id) {
            acc.inviteLink = data.getRegisterLink;
          }
          return acc;
        }),
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  deleteAccount = async (account) => {
    const { client } = this.props;
    const { accounts = [] } = this.state;

    this.setState({
      accounts: accounts.filter((acc) => acc.id !== account.id),
    });

    client.mutate({
      mutation: DELETE_ACCOUNT,
      variables: { id: account.id },
    });
  };

  handelModelClick = (async) => {
    this.setState({
      showPasswordResetModal: false,
    });
  };

  render() {
    return (
      <List
        dataSource={this.state.accounts}
        itemLayout="horizontal"
        renderItem={(account) => (
          <div>
            <Modal
              title="Passwort zurückgesetzt"
              visible={!!account.inviteLink && this.state.showPasswordResetModal}
              onOk={() => this.handelModelClick()}
              onCancel={() => this.handelModelClick()}
              footer={[
                <Button type="primary" onClick={() => this.handelModelClick()}>
                  <span>Okay</span>
                </Button>,
              ]}
            >
              <div className="size-medium">
                <p>Das Passwort wurde erfolgreich zurückgesetzt.</p>
                <p>Kopiere den Link und verschicke ihn an den User, damit er ein neues Passowrt setzten kann.</p>
              </div>
              <Input
                style={{ width: 350 }}
                value={account.inviteLink}
                ref={(input) => (this[`input${account.id}`] = input)}
                addonAfter={
                  document.queryCommandSupported('copy') && (
                    <Icon
                      style={{
                        cursor: 'pointer',
                        color: '#f21850',
                      }}
                      onClick={() => this.copyLinkToClipboard(account)}
                      type="copy"
                    />
                  )
                }
              />
            </Modal>
            <List.Item
              actions={[
                this.props.accountRole === 'SUPERADMIN' ? (
                  account.inviteLink ? (
                    <Popover
                      trigger="click"
                      title="Link"
                      content={
                        <Input
                          style={{ width: 350 }}
                          value={account.inviteLink}
                          ref={(input) => (this[`input${account.id}`] = input)}
                          addonAfter={
                            document.queryCommandSupported('copy') && (
                              <Icon
                                style={{
                                  cursor: 'pointer',
                                  color: '#f21850',
                                }}
                                onClick={() => this.copyLinkToClipboard(account)}
                                type="copy"
                              />
                            )
                          }
                        />
                      }
                    >
                      <span className="clickable-primary">Link anzeigen</span>
                    </Popover>
                  ) : (
                    <Popconfirm
                      title="Passwort wirklich zurücksetzen?"
                      okText="Ja"
                      cancelText="Abbrechen"
                      onConfirm={() => {
                        this.resetPasswordAndGetRegisterLink(account);
                      }}
                    >
                      <span className="clickable-primary">Passwort zurücksetzten</span>
                    </Popconfirm>
                  )
                ) : null,
                <Popconfirm
                  title="Account unwiderruflich löschen?"
                  okText="Ja"
                  cancelText="Abbrechen"
                  onConfirm={() => this.deleteAccount(account)}
                >
                  <span className="clickable-primary">Löschen</span>
                </Popconfirm>,
              ]}
            >
              <List.Item.Meta title={account.email} description={account.role} />
              {this.state[`loading${account.id}`] && <Spin size="small" />}
            </List.Item>
          </div>
        )}
      />
    );
  }
}

AccountsList.propTypes = {
  dataSource: PropTypes.array.isRequired,
  accountRole: PropTypes.string.isRequired,
};

export default AccountsList;
