import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import SideMenu from '../../components/SideMenu/index';

import Posts from './components/Posts';
import Maps from './components/Maps';
import LinkCollection from './components/LinkCollection';
import AccessToken from './components/AccessToken';
import FreshmanMap from './components/FreshmanMap';

import {
  ProfileOverview,
  Preview,
  ResponsiveRender,
  ProgressPost,
  ProgressContainer,
} from 'components';

import ThreeColumnLayout from '../../layouts/ThreeColumnLayout';

import './style.css';

const MENU = [
  {
    title: 'Posts',
    key: 'posts',
    icon: 'form',
  },
  {
    title: 'Karten',
    key: 'maps',
    icon: 'environment',
  },
  {
    title: 'Linksammlung',
    key: 'links',
    icon: 'link',
  },
  {
    title: 'Zugangscode',
    key: 'accessToken',
    icon: 'lock',
  },
];

class Freshi extends Component {
  renderContent(subpage) {
    switch (subpage) {
      case 'accessToken':
        return <AccessToken />;

      case 'links':
        return <LinkCollection />;

      case 'maps':
        return (
          <>
            <ResponsiveRender
              xs={
                <>
                  <FreshmanMap
                    onLocationDragEnd={() => null}
                    onLocationConfirm={() => null}
                    setCenterAndZoomFunction={() => null}
                  />
                  <br />
                </>
              }
              xl={null}
            />
            <Maps />
          </>
        );

      case 'posts':
      default:
        return <Posts />;
    }
  }

  renderSidebar(subpage) {
    switch (subpage) {
      case 'maps':
        return (
          <FreshmanMap
            onLocationDragEnd={() => null}
            onLocationConfirm={() => null}
            setCenterAndZoomFunction={() => null}
          />
        );

      case 'posts':
        return <Preview />;

      default:
        return null;
    }
  }

  render() {
    const { role } = this.context;
    const { closedProgress = false, successModal = false } = this.props;

    const progressTrackingActive = role === 'STUDENT_COUNCIL' && !closedProgress && !successModal;

    const { subpage = 'posts' } = this.props.match.params;

    const isMaps = subpage === 'maps';

    return (
      <div>
        <ThreeColumnLayout middleSpans={isMaps ? { xl: 11 } : null} rightSpans={isMaps ? { xl: 7 } : null}>
          <>
            <ProfileOverview minimized={true} />
            <SideMenu items={MENU} />
          </>
          <>{this.renderContent(subpage)}</>
          <>{this.renderSidebar(subpage)}</>
        </ThreeColumnLayout>
        {progressTrackingActive && <ProgressContainer /> }
      </div>
    );
  }
}

Freshi.contextTypes = {
  role: PropTypes.string,
};

const mapStateToProps = ({ progress }) => ({
  closedProgress: progress.closed,
  successModal: progress.successModal,
});

export default connect(mapStateToProps)(Freshi);
