import React, { Component } from 'react';
import { Row, Col, Icon, Menu, Dropdown, Tooltip, Badge, Modal, Form, Alert, DatePicker, Button } from 'antd';

import { connect } from 'react-redux';
import { Query, withApollo } from 'react-apollo';
import update from 'immutability-helper';

import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/de';

import { uniq, isEmpty } from 'lodash';

import { buildTimeString } from '../../utils/time';

import { loadPost } from '../../core/redux/actions/post';
import { LANGUAGES } from '../../core/constants';
import { deletePost, addPost } from '../../core/gqlCache';
import { GET_MY_POSTS, PROFILE, GET_PINNED_POST, USERS, GET_POST_STATS } from 'core/gql/queries';
import { DELETE_POST, PIN_POST, UPDATE_POST_PUBLISH_DATE, CHANGE_POST_STATUS } from 'core/gql/mutations';
import { FRAGMENT_POST_DATA } from 'core/gql/fragments';
import formatText from '../../utils/markdown';

import defaultAvatar from '../../images/avatar_placeholder.png';
import GMap from '../GMap/index';
import ProgressBar from '../ProgressBar/index';
import FollowButton from '../FollowButton';
import Ionicon from '../Ionicon';

import GalleryModal from '../GalleryModal/index';

import { AudioPlayer } from '../';

import locale from 'antd/lib/date-picker/locale/de_DE';
import './style.css';
import Audience from './Audience';

const CONTENT_THRESHOLD = 500;

class Post extends Component {
  state = {
    galleryModalImages: [],
    galleryModalVisible: false,
    hideContent: true,
    visibleLanguage: 'de',
    publishDateSelection: 'now',
    displayPublishingDateModal: false,
    buttonLoading: false,
  };

  expandContent = () => {
    this.setState({
      hideContent: false,
    });
  };

  collapseContent = () => {
    this.setState({
      hideContent: true,
    });
  };

  showGallery = (images) => {
    this.setState({
      galleryModalImages: images,
      galleryModalVisible: true,
    });
  };

  renderJobsListing = ({ jobs = [] }) => {
    return jobs.slice(0, 2).map(({ id, title, type, company, address }) => (
      <Row
        key={id}
        type="flex"
        align="middle"
        style={{
          padding: '10px 0px',
          borderTop: '1px solid #eef0f2',
          borderBottom: '1px solid #eef0f2',
        }}
      >
        <Col span={2}>
          <Ionicon fontStyle={{ fontSize: '1.8em', color: '#f21850' }} type="ios-briefcase" />
        </Col>
        <Col span={20}>
          <div className="color-secondary">{type}</div>
          <div className="weight-bold">{title}</div>
          <div className="color-secondary">{company.title}</div>
          <div className="color-secondary">
            <Ionicon
              style={{ display: 'inline-block', paddingRight: '1.0em' }}
              fontStyle={{ fontSize: '1.2em' }}
              type="ios-pin-outline"
            />
            {address.city}
          </div>
        </Col>
        <Col span={2}>
          <Ionicon fontStyle={{ fontSize: '1.6em', color: '#f21850' }} type="ios-bookmark-outline" />
        </Col>
      </Row>
    ));
  };

  renderCompanyPromotion = ({ company, image, badgeTitle }) => {
    const { header } = company;
    const { large } = image || header || {};

    return (
      <div>
        <div className="company-promotion">
          {large && (
            <div style={{ position: 'relative', width: '100%' }}>
              <img style={{ width: '100%' }} src={large} alt="" />
              {badgeTitle && (
                <div
                  style={{
                    position: 'absolute',
                    top: '5%',
                    left: 0,
                    color: '#ffffff',
                    backgroundColor: '#f21850',
                    fontWeight: 600,
                    padding: '5px 15px',
                  }}
                >
                  {badgeTitle}
                </div>
              )}
            </div>
          )}
          <Row type="flex" justify="space-between" style={{ padding: '10px 20px' }}>
            <Col>
              <span className="weight-bold color-secondary">Erfahre mehr</span>
            </Col>
            <Col>
              <Icon type="right" className="color-secondary" />
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  renderInfo = ({ title, description, url }) => {
    return (
      <div className="info">
        <a target="_blank" href={url} rel="noopener noreferrer">
          {title}
        </a>
        <div>{description}</div>
      </div>
    );
  };

  renderBlog = ({ title, subtitle, description, url, image }) => {
    return (
      <div className="blog">
        <a target="_blank" href={url} rel="noopener noreferrer">
          {image && image.large && <img src={image.large} alt="" />}
          <div style={{ padding: '1rem' }}>
            <div className="weight-bold color-secondary">{title.toUpperCase()}</div>

            <div
              className="weight-bold"
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {subtitle}
            </div>

            <div
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {description}
            </div>
          </div>
        </a>
      </div>
    );
  };

  renderPodcast = ({ title, description, audio, image }) => {
    return (
      <div className="podcast">
        {image && image.large && <img src={image.large} alt="" />}
        <div style={{ padding: '1rem' }}>
          <div
            className="weight-bold"
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {title}
          </div>

          <div
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {description}
          </div>
          <AudioPlayer source={audio.raw} />
        </div>
      </div>
    );
  };

  renderEvent = ({ title, start, end, location = {} }) => {
    const { label, addition } = location || {};

    let locationString =
      label ||
      ['city', 'street', 'addition']
        .map((prop) => location[prop])
        .filter((prop) => prop)
        .join(', ');

    if (!locationString) {
      locationString = 'Kein Ort angegeben';
    }

    const timeString = buildTimeString(start, end);

    return (
      <div className="event" style={{ marginTop: 15 }}>
        {location.geoPoint && (
          <a href={`https://maps.google.com?q=${locationString}`} target="blank">
            <GMap
              containerElement={<div style={{ height: `250px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              locations={location.geoPoint}
            />
          </a>
        )}
        <div style={{ padding: '1rem' }}>
          <div className="weight-bold" style={{ paddingBottom: 10 }}>
            {title}
          </div>
          <div className="color-secondary">
            <div>
              <Icon type="clock-circle-o" style={{ color: '#F21852', textAlign: 'left', width: 25 }} />
              {timeString}
            </div>
            <div className="overflow-ellipsis">
              <Icon type="environment-o" style={{ color: '#F21852', textAlign: 'left', width: 25 }} />
              {locationString}
            </div>
            {addition && <div style={{ paddingLeft: 25 }}>Gebäude/Raum: {addition}</div>}
          </div>
        </div>
      </div>
    );
  };

  renderGallery = ({ images = [] }) => {
    return (
      <Row>
        <div className="gallery" onClick={() => this.showGallery(images)}>
          {images.length > 0 && <img src={images[0].large} alt="" className="gallery" />}
        </div>
        <div style={{ textAlign: 'center' }}>
          {images.length > 1 &&
            images.map((image, index) => <Badge key={image.large} status={index === 0 ? 'error' : 'default'} />)}
        </div>
      </Row>
    );
  };

  localizeValues(value = null, translations = [], locale) {
    let newLocale = locale;
    let localeValue = value;
    if (translations && translations.find) {
      localeValue =
        // eslint-disable-next-line no-nested-ternary
        translations.find((el) => el.lang === newLocale) && translations.find((el) => el.lang === newLocale).value
          ? translations.find((el) => el.lang === newLocale).value
          : translations.find((el) => el.lang === 'de')
          ? translations.find((el) => el.lang === 'de').value
          : value;
    }
    return localeValue;
  }

  renderAttachment = (attachment) => {
    if (!attachment) {
      return null;
    }

    switch (attachment.type) {
      case 'CompanyPromotion':
        return this.renderCompanyPromotion(attachment);

      case 'JobsListing':
        return this.renderJobsListing(attachment);

      case 'Info':
        return this.renderInfo(attachment);

      case 'Blog':
        const blog = attachment;
        blog.title = this.localizeValues(blog.title, blog?.translations?.title, this.state.visibleLanguage);
        blog.subtitle = this.localizeValues(blog.subtitle, blog?.translations?.subtitle, this.state.visibleLanguage);
        blog.description = this.localizeValues(
          blog.description,
          blog?.translations?.description,
          this.state.visibleLanguage
        );
        blog.url = this.localizeValues(blog.url, blog?.translations?.url, this.state.visibleLanguage);
        blog.image = this.localizeValues(blog.image, blog?.translations?.image, this.state.visibleLanguage);
        return this.renderBlog(blog);

      case 'Podcast':
        const podcast = attachment;
        podcast.title = this.localizeValues(podcast.title, podcast?.translations?.title, this.state.visibleLanguage);
        podcast.audio = this.localizeValues(podcast.audio, podcast?.translations?.audio, this.state.visibleLanguage);
        podcast.description = this.localizeValues(
          podcast.description,
          podcast?.translations?.description,
          this.state.visibleLanguage
        );
        podcast.duration = this.localizeValues(
          podcast.duration,
          podcast?.translations?.duration,
          this.state.visibleLanguage
        );
        podcast.image = this.localizeValues(podcast.image, podcast?.translations?.image, this.state.visibleLanguage);
        return this.renderPodcast(podcast);

      case 'Gallery':
        const gallery = attachment;
        gallery.images = this.localizeValues(gallery.images, gallery?.translations?.images, this.state.visibleLanguage);
        return this.renderGallery(gallery);

      case 'Event':
        const event = attachment;
        event.title = this.localizeValues(event.title, event?.translations?.title, this.state.visibleLanguage);
        return this.renderEvent(event);

      case 'Video':
        const video = attachment;
        video.video = this.localizeValues(video.video, video?.translations?.video, this.state.visibleLanguage);
        const payload = video.video?.payload || {};

        const style = {
          width: '100%',
        };

        const hasThumbnailImage = video.image && video.image.large;

        if (hasThumbnailImage) {
          style.background = `transparent url('${video.image.large}') 50% 50% / cover no-repeat`;
        }

        return (
          <>
            {!video.video && <span style={{ color: 'red' }}>Fehler: Fehlendes Video</span>}
            <video
              preload={hasThumbnailImage ? 'none' : null}
              src={video.video.raw}
              autoPlay={payload.autoplay || false}
              muted={payload.muted || true}
              controls={true}
              style={style}
            />
          </>
        );

      case 'Poll':
        const poll = attachment;
        if (poll?.translations?.options?.map && !isEmpty(poll?.translations?.options)) {
          poll.options = poll.translations.options.map((option, index) => ({
            option:
              option?.find && option.find((el) => el?.lang === this.state.visibleLanguage)?.value
                ? option.find((el) => el.lang === this.state.visibleLanguage).value
                : attachment.options[index].option,
            votes: attachment.options[index].votes,
          }));
        }
        const { options, end, total } = poll;

        const endDate = moment(end);
        const now = moment();
        let timeString = null;
        let counter = 0;

        if (endDate.isBefore(now)) {
          timeString = `Diese Umfrage endete am ${endDate.format('DD.MM.YYYY')}`;
        } else {
          const timeTillEnd = moment.duration(endDate.diff(now));
          timeString = `Diese Umfrage endet ${timeTillEnd.humanize(true)}`;
        }

        return (
          <div>
            {options.map(({ option, votes }) => (
              <div key={++counter}>
                <ProgressBar value={total > 0 ? (votes * 100) / total : 0} />
                <div className="weight-small">
                  <div className="weight-bold" style={{ width: 150, display: 'inline-block' }}>
                    {votes} {votes === 1 ? 'Stimme' : 'Stimmen'} ({total > 0 ? Math.round((votes * 100) / total) : 0}
                    %)
                  </div>
                  {option}
                </div>
              </div>
            ))}
            <br />
            <div className="weight-bold color-secondary size-tiny">
              {total} {total === 1 ? 'Stimme' : 'Stimmen'}
            </div>
            <div className="color-secondary size-tiny">{timeString}</div>
          </div>
        );

      default:
        return null;
    }
  };

  triggerReload = () => {
    const { post, client } = this.props;
    const { publishDate, planned, status } = post;

    const isFuturePost = planned && moment().diff(publishDate) < 0;
    const isDraftPost = status !== 'LIVE';
    if (isFuturePost) {
      deletePost(post.id, client, {
        query: GET_MY_POSTS,
        variables: {
          filter: 'PLANNED',
          paginationInput: { first: 200 },
        },
      });

      deletePost(post.id, client, {
        query: GET_MY_POSTS,
        variables: {
          filter: 'FIRST_SEMESTER_PLANNED',
          paginationInput: { first: 200 },
        },
      });
    } else if (isDraftPost) {
      deletePost(post.id, client, {
        query: GET_MY_POSTS,
        variables: {
          filter: 'DRAFT',
          paginationInput: { first: 200 },
        },
      });
    } else {
      const successful = deletePost(post.id, client, {
        query: GET_MY_POSTS,
        variables: { filter: 'PUBLISHED' },
      });

      const successfulFreshman = deletePost(post.id, client, {
        query: GET_MY_POSTS,
        variables: { filter: 'FIRST_SEMESTER' },
      });

      if (successful || successfulFreshman) {
        const myProfile = client.readQuery({
          query: PROFILE,
        });

        const { postsCount } = myProfile.me;

        client.writeQuery({
          query: PROFILE,
          data: {
            me: update(myProfile.me, {
              postsCount: {
                $set: postsCount - 1,
              },
            }),
          },
        });
      }
    }
  };

  onPin = async () => {
    const { post, client } = this.props;

    const isPinned = !!post.pinnedAt;

    const { me = {} } = client.readQuery({
      query: GET_MY_POSTS,
      variables: {
        filter: 'PUBLISHED',
      },
    });

    client.writeQuery({
      query: GET_PINNED_POST,
      data: {
        pinnedPost: isPinned
          ? null
          : {
              ...post,
              pinnedAt: moment.utc().format(),
            },
      },
    });

    await client.mutate({
      mutation: PIN_POST,
      variables: { id: post.id, status: !isPinned },
    });

    const unpinPost = isPinned ? { node: post } : me.posts.edges.find(({ node }) => !!node.pinnedAt);

    if (unpinPost) {
      const fragmentId = `${unpinPost.node.__typename}:${unpinPost.node.id}`;

      await client.writeFragment({
        id: fragmentId,
        fragment: FRAGMENT_POST_DATA,
        data: {
          __typename: unpinPost.node.__typename,
          pinnedAt: null,
        },
      });
    }
  };

  onUpdatePublishDate = async () => {
    const { validateFields } = this.props.form;
    const { post, client } = this.props;
    this.setState({ buttonLoading: true });

    validateFields(async (errors, values) => {
      if (!errors) {
        const variables = {
          id: post.id,
          publishDate:
            this.state.publishDateSelection === 'now' ? moment().toISOString() : values?.publishDate.toISOString(),
        };
        try {
          await client.mutate({
            mutation: UPDATE_POST_PUBLISH_DATE,
            variables,
            update: (cache, { data }) => {
              const { node } = data.updatePostPublishDate;

              if (this.state.publishDateSelection === 'now' || !node.planned) {
                addPost(data.updatePostPublishDate, cache, {
                  query: GET_MY_POSTS,
                  variables: { filter: 'PUBLISHED' },
                });
                deletePost(post.id, client, {
                  query: GET_MY_POSTS,
                  variables: {
                    filter: 'PLANNED',
                    paginationInput: { first: 200 },
                  },
                });

                deletePost(post.id, client, {
                  query: GET_MY_POSTS,
                  variables: {
                    filter: 'FIRST_SEMESTER_PLANNED',
                    paginationInput: { first: 200 },
                  },
                });
              }
            },
          });
        } finally {
          this.setState({
            validateError: false,
            publishDateSelection: 'now',
            displayPublishingDateModal: false,
            buttonLoading: false,
          });
        }
      } else {
        this.setState({
          validateError: true,
          buttonLoading: false,
        });
      }
    });
  };

  onDelete = async () => {
    const { post, client } = this.props;

    await client.mutate({
      mutation: DELETE_POST,
      variables: { id: post.id },
    });

    this.triggerReload();
  };

  onEdit = async () => {
    const { post, loadPost } = this.props;

    loadPost(post);
  };

  onMenuClick = ({ key, item }) => {
    switch (key) {
      case 'pin':
        this.onPin();
        break;

      case 'delete':
        this.onDelete();
        break;

      case 'edit':
        this.onEdit();
        break;
      case 'lang':
        const language = item?.props?.languageKey || 'de';
        if (language !== this.state.visibleLanguage) {
          this.setState({ visibleLanguage: language });
        }
        break;
      case 'changePublishingDate':
        const { setFieldsValue } = this.props.form;
        this.setState({
          displayPublishingDateModal: true,
          publishDateSelection: this.props.post?.publishDate ? 'costum' : 'now',
        });
        break;
      default:
        break;
    }
  };

  getSupportedLanguages = (post) => {
    let supportedLanguages = ['de'];
    if (post?.translations?.content.map) {
      post.translations.content.map((el) => supportedLanguages.push(el?.lang));
    }
    if (post?.attachments?.map) {
      post.attachments.map((el) => {
        if (el?.translations) {
          Object.keys(el.translations).map((key) => {
            if (el.translations[key]?.map) {
              el.translations[key].map((e) => supportedLanguages.push(e?.lang));
            }
          });
        }
      });
    }
    supportedLanguages = uniq(supportedLanguages.filter((el) => el));
    return supportedLanguages;
  };

  renderPublishingDateModal = (callback, loading, error) => {
    const { channelFilter, client } = this.props;
    const { publishDateSelection, displayPublishingDateModal } = this.state;

    const { getFieldDecorator, setFields, getFieldValue, getFieldError } = this.props.form;

    return (
      <Modal
        visible={displayPublishingDateModal}
        footer={null}
        onCancel={() =>
          this.setState(
            {
              displayPublishingDateModal: !displayPublishingDateModal,
            },
            () => {
              const { setFieldsValue } = this.props.form;
              setFieldsValue({
                publishDate: this.props.post?.publishDate ? moment(this.props.post.publishDate) : undefined,
              });
            }
          )
        }
        className="PostChangePublishingDateModal"
        centered
      >
        <Form>
          {error && (
            <Alert
              type="error"
              message="
                Der Post konnte aufgrund eines Fehlers nicht bearbeitet
                werden. Versuch es gleich wieder oder kontaktiere uns."
              style={{ width: '100%', marginBottom: 10 }}
            />
          )}

          <Form.Item className="align-left verify-section">
            <div>
              <div className="color-secondary weight-medium verify-section-headline">
                Veröffentlichung &nbsp;
                <Tooltip placement="right" title="Stelle ein, wann dein Post veröffentlicht werden soll.">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </div>
              <Row>
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 11, offset: 0 }}
                  className={publishDateSelection === 'now' ? 'publish-button active' : 'publish-button'}
                  onClick={() =>
                    this.setState({ publishDateSelection: 'now' }, () =>
                      setFields({
                        publishDate: {
                          value: getFieldValue('publishDate'),
                          errors: null,
                        },
                      })
                    )
                  }
                >
                  Jetzt
                  {publishDateSelection === 'now' && <Icon type="check" style={{ float: 'right', marginTop: 10 }} />}
                </Col>
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 11, offset: 2 }}
                  onClick={() => this.setState({ publishDateSelection: 'costum' })}
                >
                  {getFieldDecorator('publishDate', {
                    initialValue: this.props.post?.publishDate ? moment(this.props.post.publishDate) : undefined,
                    rules: [
                      {
                        validator: (rule, value, callback) => {
                          if (publishDateSelection === 'costum' && !value) {
                            callback('Du musst ein Datum setzen, an dem dein Post veröffentlicht werden soll.');
                          } else {
                            callback();
                          }
                        },
                      },
                    ],
                  })(
                    <DatePicker
                      showTime={{ format: 'HH:mm' }}
                      format="DD.MM.YYYY HH:mm"
                      placeholder="Benutzerdefiniert"
                      allowClear={false}
                      locale={locale}
                      suffixIcon={
                        publishDateSelection === 'costum' ? (
                          getFieldError('publishDate') ? (
                            <Icon type="close" />
                          ) : (
                            <Icon type="check" />
                          )
                        ) : (
                          <Icon type="calendar" />
                        )
                      }
                      width="100%"
                      className={
                        publishDateSelection === 'costum' ? 'publish-button-costum active' : 'publish-button-costum'
                      }
                      disabledDate={(date) => moment(date).diff(moment(), 'seconds') < 0}
                      disabledDate={(date) => moment(date).diff(moment(), 'seconds') < 0}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </Form.Item>

          <Form.Item className="verify-section" style={{ textAlign: 'center' }}>
            <Button
              loading={this.state.buttonLoading}
              type="primary"
              htmlType="submit"
              onClick={() => {
                this.onUpdatePublishDate();
              }}
            >
              Speichern
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  resetState = () => {
    this.setState({
      pendingApproval: false,
    });
  };

  changePostStatus = async (status = 'APPROVED') => {
    const { post, client } = this.props;

    this.setState({ pendingApproval: true });

    const variables = {
      id: post.id,
      status: status,
    };

    await client.mutate({
      mutation: CHANGE_POST_STATUS,
      variables,
      update: (cache, { data }) => {
        const { node } = data.changePostStatus;
        const freshmanPost = node.notificationChannels.some((channel) => channel.name === 'Ersti');
        if (!node.planned && node.status === 'LIVE') {
          addPost(data.changePostStatus, cache, {
            query: GET_MY_POSTS,
            variables: { filter: 'PUBLISHED' },
          });
          if (freshmanPost) {
            addPost(data.changePostStatus, cache, {
              query: GET_MY_POSTS,
              variables: { filter: 'FIRST_SEMESTER' },
            });
          }
          deletePost(post.id, client, {
            query: GET_MY_POSTS,
            variables: {
              filter: 'DRAFT',
              paginationInput: { first: 200 },
            },
          });
          deletePost(post.id, client, {
            query: GET_MY_POSTS,
            variables: {
              filter: 'PLANNED',
              paginationInput: { first: 200 },
            },
          });
          deletePost(post.id, client, {
            query: GET_MY_POSTS,
            variables: {
              filter: 'FIRST_SEMESTER_PLANNED',
              paginationInput: { first: 200 },
            },
          });
        } else if (node.planned && node.status === 'APPROVED') {
          addPost(
            data.changePostStatus,
            cache,
            {
              query: GET_MY_POSTS,
              variables: {
                filter: 'PLANNED',
                paginationInput: { first: 200 },
              },
            },
            true
          );
          if (freshmanPost) {
            addPost(
              data.changePostStatus,
              cache,
              {
                query: GET_MY_POSTS,
                variables: {
                  filter: 'FIRST_SEMESTER_PLANNED',
                  paginationInput: { first: 200 },
                },
              },
              true
            );
          }
        }
      },
    });

    this.resetState();
  };

  render() {
    const { galleryModalVisible, galleryModalImages = [], hideContent } = this.state;
    const { post, renderAdvertiseButton = false } = this.props;
    const {
      id,
      author = {},
      content: postContent,
      createdAt,
      editedAt,
      attachments = [],
      likesCount = 0,
      verificationNeeded = false,
      notificationChannels: postNotificationChannels = [],
      publishDate,
      planned,
      pinnedAt,
      translations,
      status,
      creator,
      importedFrom,
    } = post;
    const notificationChannels = postNotificationChannels.filter(
      (channel) => !channel.targeting?.roles || channel.targeting?.roles?.length === 0
    );
    const roleBasedChannels = postNotificationChannels.filter(
      (channel) => !!channel.targeting?.roles && channel.targeting.roles.length > 0
    );

    const content = this.localizeValues(postContent, translations?.content, this.state.visibleLanguage);
    const { username = '', displayName = '', avatar } = author ? author : {};

    const avatarImg = avatar ? avatar.large : defaultAvatar;

    const isLongContent = content && content.length > CONTENT_THRESHOLD;

    const replacedContent = formatText(
      isLongContent && hideContent ? `${content.substring(0, CONTENT_THRESHOLD)}...` : content
    );

    const isPinned = !!pinnedAt;

    const isFuturePost = planned && moment().diff(publishDate) < 0;
    const isDraft = status === 'DRAFT';
    const isApproved = status === 'APPROVED';

    const attachmentType = attachments && attachments[0] ? attachments[0].type : null;

    let supportedLanguages = this.getSupportedLanguages(post);

    const buttonState = {
      DRAFT: {
        text: this.context.accountRole === 'DRAFT' ? 'Wartet auf Freigabe' : 'Freigeben',
        action: () => {
          this.changePostStatus('APPROVED');
        },
      },
      APPROVED: {
        text: 'Jetzt Veröffentlichen',
        action: () => {
          this.changePostStatus('LIVE');
        },
      },
      LIVE: {
        text: 'Live',
        action: null,
      },
    };

    const hasModifyPermission =
      this.context.accountRole !== 'DRAFT' || (isDraft && creator === this.props.userData.email);

    return (
      <Row className="uninow-post" style={isFuturePost && !this.props.reviewMode ? { opacity: '0.5' } : {}}>
        <GalleryModal
          onCancel={() => this.setState({ galleryModalVisible: false })}
          visible={galleryModalVisible}
          images={galleryModalImages}
        />

        <Col style={{ width: 54, padding: '0 7', float: 'left' }}>
          <img src={avatarImg} alt="" className="avatar" />
        </Col>
        <Col style={{ marginLeft: 54 }}>
          <Row>
            <Col span={18}>
              <div>
                <div className="weight-bold size-large" style={{ display: 'inline-block' }}>
                  {displayName}
                </div>
                <div className="weight-regular color-secondary" style={{ display: 'inline-block' }}>
                  &nbsp;@
                  {this.props.reviewMode && creator
                    ? creator === this.props.userData.email
                      ? 'Ich'
                      : creator
                    : username}
                </div>
              </div>
              <Audience channels={roleBasedChannels} />
              <div className="weight-regular color-secondary size-tiny">
                {verificationNeeded && (
                  <Tooltip placement="right" title="Nur für verifizierte Studierende sichtbar">
                    <Icon type="lock" />
                  </Tooltip>
                )}
                {notificationChannels.length > 0 && notificationChannels.map((c) => (c.name ? ` #${c.name} ` : ``))}
              </div>
            </Col>
            <Col span={1}>
              {isPinned && (
                <div style={{ marginTop: '-25px' }}>
                  <Tooltip title={moment(pinnedAt).format('[Post gepinnt am] DD.MM.YYYY [um] HH:mm [Uhr]')}>
                    <Ionicon type="ios-bookmark" style={{ color: '#f21850', margin: 'auto' }} />
                  </Tooltip>
                </div>
              )}
            </Col>
            <Col span={4}>
              <Tooltip
                title={moment(publishDate || createdAt).format(
                  isFuturePost
                    ? '[Veröffentlichung am] DD.MM.YYYY [um] HH:mm [Uhr]'
                    : '[Veröffentlicht am] DD.MM.YYYY [um] HH:mm [Uhr]'
                )}
              >
                <div className="weight-regular color-secondary size-tiny" style={{ textAlign: 'right', marginTop: 4 }}>
                  {isFuturePost
                    ? moment(publishDate || createdAt).format('DD.MM.YYYY [um] HH:mm [Uhr]')
                    : moment.duration(moment(publishDate || createdAt).diff(moment())).humanize(true)}
                </div>
              </Tooltip>
            </Col>
            {!this.props.noDelete && (
              <Col span={1}>
                <div style={{ textAlign: 'right', marginTop: 4 }}>
                  <Dropdown
                    overlay={
                      <Menu
                        style={{
                          boxShadow: '0 2px 22px 0 rgba(196,196,196,0.50)',
                        }}
                        onClick={this.onMenuClick}
                      >
                        {!this.props.reviewMode && this.context.accountRole !== 'DRAFT' && (
                          <Menu.Item key="pin">Post {isPinned && 'ent'}pinnen</Menu.Item>
                        )}
                        {hasModifyPermission && <Menu.Item key="edit">Post editieren</Menu.Item>}
                        {(isDraft || !!isFuturePost) && hasModifyPermission && status !== 'LIVE' && (
                          <Menu.Item key="changePublishingDate">Veröffentlichung bearbeiten</Menu.Item>
                        )}
                        {renderAdvertiseButton && !isFuturePost && hasModifyPermission && (
                          <Menu.Item key="advertise">
                            <a
                              rel="noopener noreferrer"
                              href={`https://recruiting.uninow.com/werbeanzeigen/editieren?post=${id}`}
                              target="_blank"
                            >
                              Post bewerben
                            </a>
                          </Menu.Item>
                        )}
                        {hasModifyPermission && <Menu.Divider />}
                        {supportedLanguages.map((lang) => (
                          <Menu.Item key={'lang'} languageKey={lang}>
                            <Col style={{ minWidth: 100 }}>
                              {`${LANGUAGES[lang]}`}
                              {this.state.visibleLanguage === lang && (
                                <Icon
                                  type="check"
                                  size={20}
                                  style={{
                                    float: 'right',
                                    marginTop: 2,
                                  }}
                                />
                              )}
                            </Col>
                          </Menu.Item>
                        ))}
                        {hasModifyPermission && <Menu.Divider />}
                        {hasModifyPermission && <Menu.Item key="delete">Post löschen</Menu.Item>}
                      </Menu>
                    }
                  >
                    <div className="weight-regular clickable-secondary size-tiny">
                      <Icon type="down" style={{ fontSize: '18px' }} />
                    </div>
                  </Dropdown>
                </div>
              </Col>
            )}
          </Row>
          <Row
            style={{
              paddingTop: 5,
              wordBreak: 'break-word',
            }}
          >
            <Col span={attachmentType === 'CompanyPromotion' ? 20 : 24}>
              {replacedContent}
              {isLongContent &&
                (hideContent ? (
                  <div>
                    <span onClick={this.expandContent} className="clickable-primary">
                      Alles anzeigen
                    </span>
                  </div>
                ) : (
                  <div>
                    <span onClick={this.collapseContent} className="clickable-primary">
                      Weniger anzeigen
                    </span>
                  </div>
                ))}
            </Col>
            <Col span={attachmentType === 'CompanyPromotion' ? 4 : 0}>
              <FollowButton />
            </Col>
          </Row>
          <Row style={{ display: 'block', paddingTop: 10 }}>
            {attachments && attachments.length > 0 && this.renderAttachment(attachments[0])}
          </Row>
          <Row
            type="flex"
            align="middle"
            justify="space-between"
            style={{ paddingTop: 10, textAlign: 'left', fontSize: '13px' }}
            className="color-secondary"
          >
            <Col span={12}>
              <Icon type="heart-o" style={{ fontSize: '15px' }} />
              &nbsp;
              {likesCount}
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              {this.props.renderStatisticButton && (
                <Query query={GET_POST_STATS} variables={{ id: post.id }}>
                  {({ loading, error, data }) => {
                    if (loading || error) {
                      return null;
                    }
                    return (
                      <div
                        className="statistic-button"
                        style={{
                          cursor: 'pointer',
                        }}
                        onClick={this.props.onStatisticClick}
                      >
                        <Row type="flex" align="top" justify="space-between" gutter={10}>
                          <Col span={6}>
                            <i style={{ fontSize: '16px' }} className={`ion-ios-stats`} />
                          </Col>
                          <Col span={18} style={{ textAlign: 'right' }}>
                            <div className={'color-primary'} style={{ whiteSpace: 'nowrap' }}>
                              <b style={{ fontSize: 16 }}>{data.post.statistics.seen}</b>
                            </div>
                            <div style={{ whiteSpace: 'nowrap' }}>gesehen</div>
                          </Col>
                        </Row>
                      </div>
                    );
                  }}
                </Query>
              )}
            </Col>
          </Row>
          {(editedAt || importedFrom) && (
            <Row style={{ fontStyle: 'italic', fontSize: 12, paddingTop: 10 }}>
              {importedFrom && `aus Instagram @${importedFrom}`}
              {importedFrom && editedAt && ' - '}
              {editedAt && `Zuletzt editiert am ${moment(editedAt).format('DD.MM.YYYY HH:mm')}`}
            </Row>
          )}
          {this.props.reviewMode && (
            <Button
              loading={this.state.pendingApproval}
              type="primary"
              onClick={buttonState?.[status]?.action}
              style={{ float: 'right' }}
              disabled={(status === 'DRAFT' && this.context.accountRole === 'DRAFT') || status === 'LIVE'}
            >
              {buttonState?.[status]?.text || 'Freigeben'}
            </Button>
          )}
        </Col>
        {this.renderPublishingDateModal(() => {}, false, false)}
      </Row>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadPost: (post) => dispatch(loadPost(post)),
});

Post.contextTypes = {
  accountRole: PropTypes.string,
};
const mapStateToProps = ({ user }) => ({
  userData: user.userData,
});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(withApollo(Post)));
