import React, { memo } from 'react';

export default memo(function ({ min = 0, max = 10, status = 0, colors = [] }) {
  const elements = [];
  const numOfItems = max - min;

  const percentage = Math.round((100 * status) / numOfItems);

  const itemWidth = Math.floor((80 - numOfItems) / numOfItems);

  for (let i = min; i < max; i += 1) {
    elements.push(
      <div
        key={i}
        style={{
          display: 'inline-block',
          backgroundColor: status > i ? colors[i] || '#f21850' : '#edeff4',
          borderRadius: 2,
          marginRight: '1%',
          height: '8px',
          width: `${itemWidth}%`,
        }}
      />
    );
  }

  return (
    <div>
      {elements}
      <span
        style={{
          fontWeight: 400,
          fontSize: '0.8em',
          float: 'right',
          width: '10%',
        }}
      >
        {percentage}%
      </span>
    </div>
  );
});
