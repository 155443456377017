import React, { Component } from 'react';

import { Spin, Form, Input, Button, notification } from 'antd';
import { Query, Mutation, withApollo } from 'react-apollo';

import { PROFILE } from 'core/gql/queries';
import { CHANGE_PASSWORD } from 'core/gql/mutations';

class PasswordForm extends Component {
  state = {
    pendingChanges: false,
  };

  updatePending = (event) => {
    const { pendingChanges } = this.state;
    const { getFieldsValue } = this.props.form;

    const values = {
      ...getFieldsValue(),
      [event.target.id]: event.target.value,
    };

    const passwordInput = !!(values.oldPassword || values.newPassword || values.newPasswordRepeat);

    if (passwordInput !== pendingChanges) {
      this.setState({
        pendingChanges: passwordInput,
      });
    }
  };

  validatePasswordRepeat = (value, callback) => {
    const { getFieldValue } = this.props.form;

    const newPassword = getFieldValue('newPassword');

    if (!newPassword || value === newPassword) {
      callback();
    } else {
      callback('Passwörter stimmen nicht überein');
    }
  };

  saveSettings = async (callback) => {
    const { validateFields, setFieldsValue } = this.props.form;

    validateFields(async (errors, values) => {
      const { oldPassword, newPassword } = values;

      if (!errors && oldPassword && newPassword) {
        const body = {
          variables: {
            oldPassword,
            newPassword,
          },
        };

        callback(body);

        setFieldsValue({
          oldPassword: '',
          newPassword: '',
          newPasswordRepeat: '',
        });
        this.setState({ pendingChanges: false });
      }
    });
  };

  onError = () => {
    notification.error({
      message: 'Passwort konnte nicht geändert werden',
      description: 'Bitte versuchen Sie es später noch einmal oder kontaktieren Sie uns.',
      duration: 3,
    });
  };

  onSuccess = () => {
    notification.success({
      message: 'Passwort wurde aktualisiert',
      description: 'Ihre Einstellungen wurden erfolgreich gespeichert',
      duration: 3,
    });
  };

  render() {
    const { pendingChanges } = this.state;
    const { getFieldDecorator, getFieldValue } = this.props.form;

    const formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13, offset: 2 },
        md: { span: 15, offset: 2 },
      },
    };

    const passwordRules = [
      {
        min: 8,
        message: 'Bitte gib mindestens 8 Zeichen ein',
      },
      {
        whitespace: true,
        message: 'Nicht nur Leerzeichen',
      },
      {
        pattern: /\d/,
        message: 'Bitte verwende mindestens eine Zahl',
      },
      {
        pattern: /[a-z]+.*[A-Z]+|[A-Z]+.*[a-z]+/,
        message: 'Bitte verwende Groß- und Kleinschreibung',
      },
    ];

    const oldPasswordRequired = !!getFieldValue('newPassword') || !!getFieldValue('newPasswordRepeat');

    return (
      <Form>
        <div className="headline weight-bold size-large">Passwort ändern</div>
        <Form.Item label="Aktuelles Passwort" {...formLayout}>
          {getFieldDecorator('oldPassword', {
            rules: [
              {
                required: oldPasswordRequired,
                message: 'Bitte gib dein altes Passwort ein',
              },
            ],
          })(<Input type="password" onChange={(event) => this.updatePending(event)} />)}
        </Form.Item>
        <Form.Item label="Neues Passwort" {...formLayout} hasFeedback={true}>
          {getFieldDecorator('newPassword', {
            validateFirst: true,
            rules: passwordRules,
          })(<Input type="password" onChange={(event) => this.updatePending(event)} />)}
        </Form.Item>
        <Form.Item label="Passwort wiederholen" {...formLayout} hasFeedback={true}>
          {getFieldDecorator('newPasswordRepeat', {
            validateFirst: true,
            rules: [
              ...passwordRules,
              {
                validator: (rule, value, callback) => this.validatePasswordRepeat(value, callback),
              },
            ],
          })(<Input type="password" onChange={(event) => this.updatePending(event)} />)}
        </Form.Item>
        <Form.Item {...formLayout} label=" " colon={false}>
          <Mutation onError={this.onError} onCompleted={this.onSuccess} mutation={CHANGE_PASSWORD}>
            {(changePassword, { loading }) => (
              <Button
                loading={loading}
                disabled={!pendingChanges}
                type="primary"
                htmlType="submit"
                onClick={() => this.saveSettings(changePassword)}
              >
                Änderungen Speichern
              </Button>
            )}
          </Mutation>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create()(withApollo(PasswordForm));
