import React, { Component } from 'react';
import update from 'immutability-helper';

import { Spin } from 'antd';
import { Query, withApollo } from 'react-apollo';

import { GET_FRESHMAN_MAPS } from 'core/gql/queries';
import { UPDATE_MAP, UPDATE_LOCATION, DELETE_MAP, DELETE_LOCATION, UPDATE_LOCATION_ORDER } from 'core/gql/mutations';
import MapSelection from './MapSelection';
import LocationList from './LocationList';

class Maps extends Component {
  onMapSelect = (mapId) => {
    const { client } = this.props;

    client.writeData({
      data: {
        selectedMap: mapId,
      },
    });
  };

  onMapChange = async (map) => {
    const { client } = this.props;
    const { data } = await client.mutate({
      mutation: UPDATE_MAP,
      variables: map,
    });

    const isNewMap = !map.id;
    const cacheData = client.readQuery({ query: GET_FRESHMAN_MAPS });

    if (isNewMap) {
      client.writeQuery({
        query: GET_FRESHMAN_MAPS,
        data: update(cacheData, {
          me: {
            maps: {
              $push: [data.updateMap],
            },
            freshmanInUse: {
              $set: Date.now(),
            },
          },
        }),
      });

      client.writeData({
        data: {
          selectedMap: data.updateMap.id,
        },
      });
    } else {
      const { maps = [] } = cacheData.me;
      const mapIndex = maps.findIndex((m) => m.id === map.id);

      if (mapIndex !== -1) {
        client.writeQuery({
          query: GET_FRESHMAN_MAPS,
          data: update(cacheData, {
            me: {
              maps: {
                [mapIndex]: {
                  $set: data.updateMap,
                },
              },
            },
          }),
        });
      }
    }
  };

  onMapDelete = async (mapId) => {
    const { client } = this.props;
    const { data } = await client.mutate({
      mutation: DELETE_MAP,
      variables: {
        id: mapId,
      },
    });

    if (data.deleteMap) {
      const cacheData = client.readQuery({ query: GET_FRESHMAN_MAPS });
      const { maps = [] } = cacheData.me;
      const mapIndex = maps.findIndex((map) => map.id === mapId);

      if (mapIndex !== -1) {
        client.writeQuery({
          query: GET_FRESHMAN_MAPS,
          data: update(cacheData, {
            me: {
              maps: {
                $splice: [[mapIndex, 1]],
              },
            },
          }),
        });

        client.writeData({
          data: {
            selectedMap: null,
          },
        });
      }
    }
  };

  onVisibilityToggle = async (value, mapId) => {
    const { client } = this.props;

    await client.mutate({
      mutation: UPDATE_MAP,
      variables: {
        visible: value,
        id: mapId,
      },
    });
  };

  onLocationSave = async (location) => {
    const { client } = this.props;

    const cacheData = client.readQuery({ query: GET_FRESHMAN_MAPS });
    const { maps = [] } = cacheData.me;

    const { data } = await client.mutate({
      mutation: UPDATE_LOCATION,
      variables: {
        ...location,
        map: cacheData.selectedMap || maps[0].id,
      },
    });

    const isNewLocation = !location.id;
    const mapIndex = cacheData.selectedMap ? maps.findIndex((map) => map.id === cacheData.selectedMap) : 0;

    if (isNewLocation) {
      client.writeQuery({
        query: GET_FRESHMAN_MAPS,
        data: update(cacheData, {
          tmpLocation: { $set: null },
          me: {
            maps: {
              [mapIndex]: {
                locations: {
                  $push: [data.updateLocation],
                },
              },
            },
          },
        }),
      });
    } else {
      const locationsIndex = maps[mapIndex].locations.findIndex((l) => l.id === location.id);

      if (locationsIndex !== -1) {
        client.writeQuery({
          query: GET_FRESHMAN_MAPS,
          data: update(cacheData, {
            tmpLocation: { $set: null },
            me: {
              maps: {
                [mapIndex]: {
                  locations: {
                    [locationsIndex]: {
                      $set: data.updateLocation,
                    },
                  },
                },
              },
            },
          }),
        });
      }
    }
  };

  onLocationSelect = (location) => {
    const { client } = this.props;

    client.writeData({
      data: {
        tmpLocation: location ? JSON.stringify(location) : null,
      },
    });
  };

  onLocationDelete = async (locationId) => {
    const { client } = this.props;
    const { data } = await client.mutate({
      mutation: DELETE_LOCATION,
      variables: {
        id: locationId,
      },
    });

    if (data.deleteLocation) {
      const cacheData = client.readQuery({ query: GET_FRESHMAN_MAPS });

      const { maps = [] } = cacheData.me;
      const mapIndex = cacheData.selectedMap ? maps.findIndex((map) => map.id === cacheData.selectedMap) : 0;
      const locationsIndex = maps[mapIndex].locations.findIndex((l) => l.id === locationId);

      if (mapIndex !== -1) {
        client.writeQuery({
          query: GET_FRESHMAN_MAPS,
          data: update(cacheData, {
            me: {
              maps: {
                [mapIndex]: {
                  locations: {
                    $splice: [[locationsIndex, 1]],
                  },
                },
              },
            },
          }),
        });
      }
    }
  };

  onLocationMove = async (index, value) => {
    const { client } = this.props;
    const cacheData = client.readQuery({ query: GET_FRESHMAN_MAPS });

    const { maps = [] } = cacheData.me;
    const mapIndex = cacheData.selectedMap ? maps.findIndex((map) => map.id === cacheData.selectedMap) : 0;
    const map = maps[mapIndex];
    const movingLocation = map.locations[index];
    const otherLocation = map.locations[index + value];

    if (movingLocation && otherLocation) {
      const newLocations = update(map.locations, {
        [index]: {
          $set: otherLocation,
        },
        [index + value]: {
          $set: movingLocation,
        },
      });

      client.writeQuery({
        query: GET_FRESHMAN_MAPS,
        data: update(cacheData, {
          me: {
            maps: {
              [mapIndex]: {
                locations: {
                  $set: newLocations,
                },
              },
            },
          },
        }),
      });

      await client.mutate({
        mutation: UPDATE_LOCATION_ORDER,
        variables: {
          map: map.id,
          locations: newLocations.map((location, index) => ({
            id: location.id,
            order: index + 1,
          })),
        },
      });
    }
  };

  render() {
    return (
      <Query query={GET_FRESHMAN_MAPS} returnPartialData={true}>
        {({ loading, error, data }) => {
          if (loading) {
            return (
              <div style={{ textAlign: 'center' }}>
                <Spin />
              </div>
            );
          }

          if (error || !data.me) {
            return (
              <div style={{ textAlign: 'center' }}>
                Deine Karten konnten leider nicht geladen werden.
                <br />
                <br />
                Versuche es doch später noch einmal oder kontaktiere uns über{' '}
                <a href="mailto:support@uninow.de">support@uninow.de</a>.
              </div>
            );
          }

          const { tmpLocation } = data;
          const { maps = [] } = data.me;

          const activeMap = data.selectedMap ? maps.find((map) => map.id === data.selectedMap) : maps[0];
          const locations = activeMap ? activeMap.locations : [];

          return (
            <div className="uninow-content-container">
              <MapSelection
                selected={data.selectedMap}
                maps={maps}
                onSelect={this.onMapSelect}
                onChange={this.onMapChange}
                onDelete={this.onMapDelete}
                onVisibilityToggle={this.onVisibilityToggle}
              />
              <br />
              <div className="headline weight-bold">Standorte</div>
              <LocationList
                tmpLocation={tmpLocation ? JSON.parse(tmpLocation) : null}
                locations={locations}
                onSave={this.onLocationSave}
                onDelete={this.onLocationDelete}
                onMove={this.onLocationMove}
                onSelect={this.onLocationSelect}
                disabled={!activeMap}
              />
            </div>
          );
        }}
      </Query>
    );
  }
}

export default withApollo(Maps);
