import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import jwt from 'jsonwebtoken';

import { connect } from 'react-redux';
import { ApolloClient } from 'apollo-client';
import { onError } from 'apollo-link-error';
import { ApolloProvider } from 'react-apollo';
// temporarly disabled gql subscription
import { ApolloLink } from 'apollo-link';
/* import { WebSocketLink } from 'apollo-link-ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { getMainDefinition } from 'apollo-utilities'; */
import { changeAuthDataActionCreator, setSelectedProfile } from './redux/actions/user';
import { resetProgress } from './redux/actions/progress';

import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';

import introspectionQueryResultData from './introspection.json';

import createAuthClient from '@uninow/auth-spa-js';

import { GET_ME } from './gql/queries';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({
  fragmentMatcher,
  dataIdFromObject: (object) => (object.id ? `${object.__typename}:${object.id}` : null),
});

const httpLink = createUploadLink({
  uri: `${window.REACT_APP_GRAPHQL_HOST}/graphql`,
  headers: { 'Apollo-Require-Preflight': 'true' },
});

/* const subscriptionLink = new SubscriptionClient(
  `${window.REACT_APP_GRAPHQL_HOST_SOCKET}/subscriptions`,
  {
    reconnect: true,
    lazy: false
  }
); */

function omitProperty(obj, property) {
  if (obj && typeof obj === 'object' && !(obj instanceof Function) && !(obj instanceof File)) {
    if (Array.isArray(obj)) {
      return obj.map((element) => omitProperty(element, property));
    } else {
      return Object.keys(obj)
        .filter((key) => key !== property)
        .reduce((nextObj, key) => {
          nextObj[key] = omitProperty(obj[key], property);
          return nextObj;
        }, {});
    }
  }
  return obj;
}

class Session extends Component {
  constructor(props) {
    super(props);

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map((error) => {
          const { message, locations, path, extensions } = error;

          if (extensions && [401, 603].includes(extensions.code)) {
            this.logout();
          } else if (window.REACT_APP_ENV === 'development')
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
          return error;
        });
      }
      if (networkError && window.REACT_APP_ENV === 'development') console.log(`[Network error]: ${networkError}`);
    });

    const cleanTypenameMiddleware = new ApolloLink((operation, forward) => {
      if (operation.variables) {
        operation.variables = omitProperty(operation.variables, '__typename');
      }
      return forward(operation);
    });

    const authMiddleware = new ApolloLink((operation, forward) => {
      if (this.props.token) {
        const headers = {
          authorization: `Bearer ${this.props.token}`,
        };
        if (this.props.selectedProfile) {
          headers['x-uninow-feed-selected-profile'] = this.props.selectedProfile;
        }
        operation.setContext({
          headers,
        });
      }

      return forward(operation);
    });

    /* if (props.token) {
      subscriptionLink.connectionParams = () => ({
        authToken: props.token
      });
    } */

    const link = ApolloLink.from([
      cleanTypenameMiddleware,
      /* split(({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
      }, new WebSocketLink(subscriptionLink)), */
      authMiddleware,
      errorLink,
      httpLink,
    ]);

    this.client = new ApolloClient({
      cache,
      link,
      resolvers: {
        Query: {
          selectedMap: () => null,
          tmpLocation: () => null,
        },
      },
    });

    cache.writeData({ data: { selectedMap: null, tmpLocation: null } });

    this.state = {
      profileId: null,
    };
  }

  isValidToken(token) {
    const [_, encodedPayload] = token.split('.');
    const payload = JSON.parse(window.atob(encodedPayload));
    const hasFeedProfile =
      !!payload['https://uninow.com/jwt/claims']?.['x-uninow-profiles']?.['com.uninow.feed']?.profile_id;
    return hasFeedProfile;
  }
  loadSessionData = async () => {
    const { token } = this.props;

    if (token) {
      if (!this.isValidToken(token)) {
        console.log('invalid token');
        this.logout();
        return;
      }
      const { data } = await this.client.query({
        query: GET_ME,
        context: {
          headers: {
            authorization: token,
          },
        },
      });

      this.setState({ profileId: data?.me?.feedEdge?.profile });
    }
  };

  componentDidMount = () => {
    this.loadSessionData();
  };

  getChildContext = () => ({
    isAuthenticated: !!this.props.token && !!this.props.userData,
    login: this.login,
    loginWithToken: this.loginWithToken,
    logout: this.logout,
    getMe: this.getMe,
    switchProfile: this.switchProfile,
    token: this.props.token,
    role: this.props.role,
    profileId: this.state.profileId,
    accountId: this.props.accountId,
    accountRole: this.props.accountRole,
  });

  loginWithToken = async (token) => {
    const authClient = await createAuthClient({
      domain: window.ACCOUNTS_SERVICE_URL,
      application: 'com.uninow.feed',
    });

    authClient.addJWTAuthorization(token);

    const [user, { data = {} }] = await Promise.all([
      authClient.getUser(),
      this.client.query({
        query: GET_ME,
        context: {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
      }),
    ]);

    const { feedEdge, role } = data.me;

    /* subscriptionLink.connectionParams = () => ({
      authToken: token
    });
    subscriptionLink.close(); */
    this.props.changeAuthData(token, role, feedEdge.role, feedEdge.accountId, user.data);

    this.setState({ profileId: data?.me?.feedEdge?.profile });

    return true;
  };

  login = async (email, password) => {
    const authClient = await createAuthClient({
      domain: window.ACCOUNTS_SERVICE_URL,
      application: 'com.uninow.feed',
    });

    const response = await authClient.loginWithCredentials({
      loginId: email,
      password,
    });

    const token = response.getEncryptedToken();
    const { sub: accountId } = jwt.decode(token);
    const user = await authClient.getUser();
    const { data = {} } = await this.client.query({
      query: GET_ME,
      context: {
        headers: {
          authorization: token,
        },
      },
    });

    const { feedEdge, role } = data.me;

    /* subscriptionLink.connectionParams = () => ({
      authToken: token
    });
    subscriptionLink.close(); */
    this.props.changeAuthData(token, role, feedEdge.role, accountId, user.data);

    this.setState({ profileId: data?.me?.feedEdge?.profile });
  };

  logout = () => {
    this.props.changeAuthData(null, null, null, null, null);
    this.props.setSelectedProfile(null);
    this.props.resetProgress();
    this.client.resetStore();
    this.props.persistor.purge();
    console.log('logged out');
  };

  getMe = async () => {
    const authClient = await createAuthClient({
      domain: window.ACCOUNTS_SERVICE_URL,
      application: 'com.uninow.feed',
    });

    return authClient.getUser();
  };

  switchProfile = async (profile) => {
    const { token, accountRole, accountId, userData } = this.props;

    /* subscriptionLink.connectionParams = () => ({
      authToken: token
    });
    subscriptionLink.close(); */
    await this.client.resetStore();
    await this.props.persistor.purge();
    this.props.setSelectedProfile(profile.id);
    return this.props.changeAuthData(token, profile.role, accountRole, accountId, userData);
  };

  render() {
    return <ApolloProvider client={this.client}>{this.props.children}</ApolloProvider>;
  }
}

Session.childContextTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  loginWithToken: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  getMe: PropTypes.func.isRequired,
  switchProfile: PropTypes.func.isRequired,
  token: PropTypes.string,
  role: PropTypes.string,
  accountRole: PropTypes.string,
  accountId: PropTypes.string,
  profileId: PropTypes.string,
};

Session.propTypes = {
  children: PropTypes.node.isRequired,
};

const mapStateToProps = ({ user }) => ({
  token: user.token,
  role: user.role,
  accountRole: user.accountRole,
  accountId: user.accountId,
  userData: user.userData,
  selectedProfile: user.selectedProfile,
});

const mapDispatchToProps = (dispatch) => ({
  changeAuthData: (token, role, accountRole, accountId, userData) =>
    dispatch(changeAuthDataActionCreator(token, role, accountRole, accountId, userData)),
  resetProgress: () => dispatch(resetProgress()),
  setSelectedProfile: (profile) => dispatch(setSelectedProfile(profile)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Session);
