import React, { Component } from 'react';
import moment from 'moment';
import { Row, Col, Spin, Tag, Card } from 'antd';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';

import gradient from '../../images/gradient.svg';
import logo from '../../images/uninow_logo_white.svg';

import { MEDIA_STATUS } from '../../core/gql/queries';

export default class Legal extends Component {
  renderHeader = () => {
    return (
      <Row
        type="flex"
        align="middle"
        style={{
          backgroundImage: `url(${gradient})`,
          backgroundPosition: '50% 50%',
          backgroundSize: 'cover',
          minHeight: 50,
          maxHeight: 50,
        }}
      >
        <Col
          offset={6}
          span={12}
          style={{
            color: 'white',
            textAlign: 'center',
          }}
        >
          <Link
            style={{
              color: 'white',
              fontSize: 24,
              fontWeight: 'bold',
            }}
            to={'/'}
          >
            <img src={logo} alt="" style={{ height: 30 }} />
          </Link>
        </Col>
      </Row>
    );
  };

  render() {
    const { location } = this.props;

    const [, key] = /key=(.*)/gim.exec(location.search);

    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#EDEFF4',
        }}
      >
        {this.renderHeader()}
        <div style={{ textAlign: 'center', paddingTop: 10 }}>
          <Query query={MEDIA_STATUS} variables={{ key }}>
            {({ loading, error, data = {} }) => {
              if (loading) {
                return <Spin />;
              }

              if (error) {
                return (
                  <span>
                    Fehler aufgetreten. Bitte kontaktieren sie <a href="mailto:support@uninow.de">support@uninow.de</a>
                  </span>
                );
              }

              const { status, disconnectedAt } = data.mediaStatus;

              return (
                <Card title="Account-Verknüpfung" style={{ textAlign: 'left', maxWidth: 350, margin: 'auto' }}>
                  <Tag color={status ? 'green' : 'red'}>{status ? 'Aktiv' : 'Inaktiv'}</Tag>
                  Synchronisation ist {status ? '' : 'nicht'} aktiv
                  <br />
                  <br />
                  <div className="color-secondary size-small">
                    Deaktiviert am: {disconnectedAt ? moment(disconnectedAt).format('DD.MM.YYYY HH:mm') : '-'}
                  </div>
                </Card>
              );
            }}
          </Query>
        </div>
      </div>
    );
  }
}
