import React, { useState, useEffect, useCallback } from 'react';

import { useMutation } from '@apollo/react-hooks';

import { Switch } from 'antd';

import { CHANGE_MUST_REVIEW_INSTAGRAM_POST } from '../../core/gql/mutations';

const InstagramPostReviewSwitch = ({ onChange, checked = false }) => {
  const [changeMustReviewInstagramPosts] = useMutation(CHANGE_MUST_REVIEW_INSTAGRAM_POST);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSwitchChange = (value) => {
    try {
      setLoading(true);
      changeMustReviewInstagramPosts({ variables: { value: value } });
      onChange(value);
      setLoading(false);
    } catch (e) {
      setError(e);
    }
  };

  return (
    <div>
      <Switch loading={loading} checked={checked} onChange={handleSwitchChange} />
      {error && (
        <span className="color-primary" style={{ fontSize: '0.8em', paddingLeft: 10 }}>
          Fehler aufgetreten
        </span>
      )}
    </div>
  );
};

export default InstagramPostReviewSwitch;
