import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Confetti from 'react-confetti';

import { PieChart, Pie, Cell, Label } from 'recharts';
import { interpolateRgb } from 'd3-interpolate';

import { Modal } from 'antd';

import useProgress from '../../hooks/useProgress';
import { close } from '../../core/redux/actions/progress';

const colorPalette = interpolateRgb('#f21850', '#faac0d');

export default function () {
  const { innerWidth } = window;

  const { successModal } = useSelector((state) => state.progress);

  const dispatch = useDispatch();

  const closeModal = () => dispatch(close());

  const { sections, loading } = useProgress();
  const [run, setRun] = useState(false);

  const chartData = sections.reduce((items, section) => items.concat(section.data), []).map((item) => ({ value: 1 }));

  useEffect(() => {
    successModal && setTimeout(() => setRun(true), 150);
  }, [successModal]);

  if (loading) {
    return null;
  }

  const colorMap = chartData.map((_, index) => colorPalette((chartData.length / 100) * index));

  return (
    <div id="ProgressDoneModal">
      <Modal visible={successModal} footer={null} closable={true} onCancel={closeModal}>
        <div style={{ position: 'fixed', top: 0, left: 0 }}>
          <Confetti
            style={{ zIndex: 40 }}
            initialVelocityY={4}
            initialVelocityX={10}
            run={run}
            recycle={false}
            colors={colorMap}
            confettiSource={{ x: 0, y: 0 }}
          />
          <Confetti
            style={{ zIndex: 40 }}
            initialVelocityY={4}
            initialVelocityX={-10}
            run={run}
            recycle={false}
            colors={colorMap}
            confettiSource={{ x: innerWidth, y: 0 }}
          />
        </div>
        <div style={{ textAlign: 'center' }}>
          <div style={{ fontSize: '1.5em', fontWeight: 400 }}>Hurra, euer Profil ist eingerichtet!</div>
          <PieChart width={200} height={200} style={{ margin: 'auto' }}>
            <Pie
              isAnimationActive={false}
              data={chartData}
              startAngle={90}
              endAngle={450}
              paddingAngle={5}
              innerRadius={45}
              outerRadius={60}
              dataKey="value"
            >
              <Label value="100%" position="center" style={{ fontWeight: 700 }} />
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colorPalette((chartData.length / 100) * index)} />
              ))}
            </Pie>
          </PieChart>
          <div style={{ fontWeight: 400 }}>
            Herzlich Willkommen bei UniNow und nun viel Spaß mit dem UniNow-Feed.
            <br />
            <br />
            Bei Fragen, Problemen oder Ideen könnt ihr uns gerne jederzeit kontaktieren.
            <div style={{ textAlign: 'right', paddingTop: 15 }}>Das UniNow-Team</div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
