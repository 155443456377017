import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import { Mutation } from 'react-apollo';
import { CHANGE_PASSWORD } from 'core/gql/mutations';

import { Form, Button, Input, notification } from 'antd';

import ContentLoggedOut from '../../components/ContentLoggedOut';

class PasswordResetUpdate extends Component {
  validatePasswordRepeat = (value, callback) => {
    const { getFieldValue } = this.props.form;

    const newPassword = getFieldValue('newPassword');

    if (!newPassword || value === newPassword) {
      callback();
    } else {
      callback('Passwörter stimmen nicht überein');
    }
  };

  sendResetPassword = (callback) => {
    const { validateFields } = this.props.form;
    const { token } = this.props.match.params;
    validateFields((err, values) => {
      if (!err && callback) {
        callback({
          variables: {
            newPassword: values['newPassword'],
            token: token,
          },
        });
      }
    });
  };

  onError = (error) => {
    const { NODE_ENV } = window;
    notification.error({
      message: NODE_ENV === 'development' ? error.message : 'Passwort zurücksetzen fehlgeschlagen',
      description: 'Dein Link ist ungültig oder abgelaufen',
    });
  };

  onCompleted = (response) => {
    this.context.login(response.changePassword);
    notification.success({
      message: 'Passwort zurücksetzen erfolgreich',
      description:
        'Dein Passwort wurde erfolgreich zurückgesetzt. Du kannst dich jetzt mit deinem neuen Passwort einloggen.',
    });
  };

  render() {
    if (this.context.isAuthenticated === true) {
      return <Redirect to="/" />;
    }
    const { getFieldDecorator } = this.props.form;
    const { setPassword } = this.props;

    const passwordRules = [
      {
        min: 8,
        message: 'Bitte gib mindestens 8 Zeichen ein',
      },
      {
        whitespace: true,
        message: 'Nicht nur Leerzeichen',
      },
      {
        pattern: /\d/,
        message: 'Bitte verwende mindestens eine Zahl',
      },
      {
        pattern: /[a-z]+.*[A-Z]+|[A-Z]+.*[a-z]+/,
        message: 'Bitte verwende Groß- und Kleinschreibung',
      },
    ];

    return (
      <ContentLoggedOut>
        <Mutation mutation={CHANGE_PASSWORD} onError={this.onError} onCompleted={this.onCompleted}>
          {(callback, { loading }) => (
            <div id="LoginForm">
              <h4 className="weight-medium">{setPassword ? 'Passwort setzen' : 'Passwort zurücksetzen'}</h4>
              <Form>
                <div className="align-left">
                  <Form.Item>
                    {getFieldDecorator('newPassword', {
                      validateTrigger: 'onBlur',
                      validateFirst: true,
                      rules: [...passwordRules],
                    })(
                      <div>
                        <div className="color-secondary weight-medium size-small">Neues Passwort</div>
                        <Input type="password" size="large" />
                      </div>
                    )}
                  </Form.Item>
                </div>
                <div className="align-left">
                  <Form.Item>
                    {getFieldDecorator('newPasswordRepeat', {
                      validateTrigger: 'onBlur',
                      validateFirst: true,
                      rules: [
                        ...passwordRules,
                        {
                          validator: (rule, value, callback) => this.validatePasswordRepeat(value, callback),
                        },
                      ],
                    })(
                      <div>
                        <div className="color-secondary weight-medium size-small">Neues Passwort wiederholen</div>
                        <Input type="password" size="large" />
                      </div>
                    )}
                  </Form.Item>
                </div>
                <Form.Item>
                  <Link to="/" style={{ float: 'left', lineHeight: '16px', padding: 6 }}>
                    Zurück
                  </Link>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ float: 'right' }}
                    onClick={() => this.sendResetPassword(callback)}
                    loading={loading}
                  >
                    Passwort zurücksetzen
                  </Button>
                </Form.Item>
              </Form>
            </div>
          )}
        </Mutation>
      </ContentLoggedOut>
    );
  }
}

PasswordResetUpdate.propTypes = {
  setPassword: PropTypes.bool,
};

PasswordResetUpdate.defaultProps = {
  setPassword: false,
};

PasswordResetUpdate.contextTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
};

export default Form.create()(PasswordResetUpdate);
