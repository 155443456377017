import React, { Component } from 'react';

import { Spin, List } from 'antd';
import { Query } from 'react-apollo';

import { USERS } from 'core/gql/queries';

class ConsentOverview extends Component {
  renderUserItem = (user) => {
    return (
      <List.Item>
        <List.Item.Meta description={user.username} title={user.displayName ? user.displayName : 'Kein Name gesetzt'} />
        {user.publishMembership ? 'Zugestimmt' : 'Unbeantwortet'}
      </List.Item>
    );
  };

  render() {
    return (
      <div>
        <Query query={USERS} variables={{ filter: 'ALL' }}>
          {({ loading, error, data }) => {
            if (loading) {
              return (
                <div style={{ textAlign: 'center' }}>
                  <Spin />
                </div>
              );
            }
            if (error || !data.users) {
              return (
                <div style={{ textAlign: 'center' }}>
                  Die Liste der Einwilligungserklärungen konnten leider nicht geladen werden.
                  <br />
                  <br />
                  Versuche es doch später noch einmal oder kontaktiere uns über{' '}
                  <a href="mailto:support@uninow.de">support@uninow.de</a>.
                </div>
              );
            }
            return (
              <List
                style={{ marginBottom: 20 }}
                itemLayout="horizontal"
                dataSource={data.users.edges}
                renderItem={(user) => this.renderUserItem(user.node)}
              />
            );
          }}
        </Query>
      </div>
    );
  }
}

export default ConsentOverview;
