import React from 'react';

function ChannelFilterInfo({ notificationChannels, channelFilter }) {
  return (
    <div className="channels" style={{ padding: '20px 0px', textAlign: 'center' }}>
      Dieser Post erscheint nur im Channel{' '}
      <b>#{notificationChannels.filter(channelFilter).map((channel) => channel.name)}</b> und kann ausschließlich im
      Erstsemesterbereich der App angesehen werden.
    </div>
  );
}

export default ChannelFilterInfo;
