import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ProfileOverview from '../../components/ProfileOverview';
import SideMenu from '../../components/SideMenu/index';

import Posts from './components/Posts';
import Targeting from './components/Targeting';
import ConsentOverview from './components/ConsentOverview';

import ThreeColumnLayout from '../../layouts/ThreeColumnLayout';

import '../Settings/style.css';

class Admin extends Component {
  renderSettingsForm() {
    const { subpage = 'profiles' } = this.props.match.params;

    switch (subpage) {
      case 'posts':
        return <Posts />;
      case 'consents':
        return <ConsentOverview />;
      case 'profiles':
      default:
        return <Targeting />;
    }
  }

  render() {
    const { accountRole } = this.context;
    const { subpage = 'profiles' } = this.props.match.params;

    let menuStructure = [];
    if (accountRole === 'SUPERADMIN') {
      menuStructure.push(
        ...[
          { title: 'Profile', key: 'profiles', icon: 'team' },
          { title: 'Posts', key: 'posts', icon: 'read' },
          { title: 'Einwilligungsübersicht', key: 'consents', icon: 'form' },
        ]
      );
    }

    return (
      <ThreeColumnLayout>
        <>
          <ProfileOverview minimized={true} />
          <SideMenu items={menuStructure} />
        </>
        <div
          {...(subpage !== 'posts' && {
            className: 'uninow-settings-container',
          })}
        >
          {this.renderSettingsForm()}
        </div>
      </ThreeColumnLayout>
    );
  }
}

Admin.contextTypes = {
  role: PropTypes.string.isRequired,
  accountRole: PropTypes.string.isRequired,
};

export default Admin;
