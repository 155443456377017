import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function ChatwootBubble() {
  const user = useSelector((state) => state.user);
  const chatwoot = useSelector((state) => state.chatwoot);

  useEffect(() => {
    if (chatwoot.displayName === '' || !user.accountId) return;

    const setUpWidget = () => {
      const windowWithChatwoot = window;

      const BASE_URL = 'https://chatwoot.uninow.io';
      const g = document.createElement('script');
      const s = document.getElementsByTagName('script')[0];
      g.src = `${BASE_URL}/packs/js/sdk.js`;
      s.parentNode.insertBefore(g, s);
      g.async = true;
      g.onload = () => {
        windowWithChatwoot.chatwootSDK.run({
          websiteToken: '2R8cRhzEBn2WGchqaXW37RBK',
          baseUrl: BASE_URL,
        });
      };

      const chatwootReadyHandler = () => {
        windowWithChatwoot.$chatwoot.setUser(user.accountId + '', {
          email: user.userData.email,
          phone_number: chatwoot.phoneNumber,
          name: `${user.userData.firstName} ${user.userData.lastName}`,
          company_name: chatwoot.displayName
        });

        const conversationCreatedHandler = () => {
          windowWithChatwoot.$chatwoot.setCustomAttributes({
            user_id: user.accountId,
            user_email: user.userData.email,
            email: user.userData.email,
          });
          windowWithChatwoot.$chatwoot.setConversationCustomAttributes({
            URL: window.location.href,
            university: window.location.hostname.split('.')[0],
          });
        };

        windowWithChatwoot.addEventListener('conversation_created', conversationCreatedHandler);

        windowWithChatwoot.$chatwoot.setLabel(`${window.location.hostname.split('.')[0]}`);

        windowWithChatwoot.addEventListener('chatwoot:error', (err) => {
          console.error('error occurred', err);
        });

        return () => {
          windowWithChatwoot.removeEventListener('conversation_created', conversationCreatedHandler);
          windowWithChatwoot.removeEventListener('chatwoot:error', (err) => {
            console.error('error occurred', err);
          });
        };
      };

      windowWithChatwoot.addEventListener('chatwoot:ready', chatwootReadyHandler);

      // Cleanup function
      return () => {
        windowWithChatwoot.removeEventListener('chatwoot:ready', chatwootReadyHandler);
      };
    };

    const cleanup = setUpWidget();

    return () => {
      if (cleanup) cleanup();
    };
  }, [chatwoot, user]);

  return null;
}