import update from 'immutability-helper';
// reducers/displayName.js
import { SET_DISPLAY_NAME, SET_PHONE_NUMBER } from '../actions/chatwoot';

const initialState = {
  displayName: '',
  phoneNumber: ''
};

function chatwoot(state = initialState, action ) {
  switch (action.type) {
    case SET_DISPLAY_NAME: {
      return update(state, { displayName: { $set: action.displayName } });
    }
    case SET_PHONE_NUMBER: {
      return update(state, { phoneNumber: { $set: action.phoneNumber } });
    }
    default:
      return state;
  }
}

export default chatwoot;