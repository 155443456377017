import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import {
  SEARCH_PROFILE,
  CURRENT_TARGETS,
  POSSIBLE_TARGETS,
  GET_REGISTER_LINK,
  CURRENT_TARGETS_ACCOUNTS,
} from 'core/gql/queries';
import {
  UPDATE_TARGETS,
  CREATE_CHANNEL,
  DELETE_CHANNEL,
  DELETE_ACCOUNT,
  DELETE_PROFILE,
  UPDATE_PROFILE,
  SWITCH_PROFILE,
  RESET_PASSWORD,
  SET_AUTO_FOLLOW,
  CREATE_NOTIFICATION_CHANNEL,
} from 'core/gql/mutations';
import {
  Form,
  Select,
  AutoComplete,
  Row,
  Col,
  Button,
  Switch,
  Input,
  Icon,
  message,
  List,
  Popconfirm,
  Popover,
  Spin,
  Checkbox,
  Modal,
} from 'antd';
import { debounce } from 'lodash';
import AccountsList from './AccountsList';
import ErrorTooltip from '../../../components/ErrorTooltip';
import ChannelSelection from './ChannelSelection';
import RoleTargetingSelect from './RoleTargetingSelect';

const { Option } = Select;

const CONFIRM_VALUE = 'LÖSCHEN';

const PERMISSIONS = [
  {
    id: 'READ_POST_STATISTICS',
    label: 'Lesen von Post-Statistiken',
    description:
      'Nutzer*innen sehen wie oft Posts gesehen wurden. Verteilung von Studienrichtungen und Hochschulen einsehbar.',
  },
  {
    id: 'ADVERTISE_POST',
    label: 'Bewerben von Posts',
    description: 'Beiträge im Feed können zusätzlich als Werbeanzeige ausgespielt werden.',
  },
  {
    id: 'CREATE_CAREER_ADVERT',
    label: 'Werbeanzeigen im Karriere-Tab',
    description: 'Anstatt an erster Stelle im Feed, taucht die Werbeanzeige an erster Stelle im Karriere-Tab auf.',
  },
  {
    id: 'CREATE_MAIL_ADVERT',
    label: 'Werbeanzeigen im Mail-Modul',
    description: 'Werbeanzeigen können auf der Übersichtsseite der Mails geschaltet werden.',
  },
  {
    id: 'VIEW_FOLLOWER_TREND',
    label: 'Statistik zum Follower-Verlauf',
    description: 'Visualisierung zum Verlauf der Follower der letzten 30 Tage. Funktioniert nicht bei autofollow!',
  },
  {
    id: 'MULTILINGUAL_POSTS',
    label: 'Mehrsprachige Posts',
    description: 'Posts können in mehreren Sprachen verfasst werden. (Aktuell: Deutsch und Englisch)',
  },
  {
    id: 'CUSTOM_POST_PUSHCONFIG',
    label: 'Benutzerdefinierte Push Konfiguration',
    description: 'Nutzer*innen können für den Push eines Post einen eigenen Titel und Text festlegen.',
  },
];

class Targeting extends Component {
  state = {
    searching: false,
    confirmInput: null,
    dataSource: [],
    loading: false,
    showAddChannelModal: false,
  };

  handleChannelChange = async (values) => {
    const notificationChannels = this.state.currentTargets.notificationChannels;
    const newChannel = values.find((value) => !notificationChannels.includes(value));
    if (newChannel) {
      await this.props.client.mutate({
        mutation: CREATE_CHANNEL,
        variables: {
          userId: this.state.account,
          channel: newChannel,
        },
      });
    }
    const deletedChannel = notificationChannels.find((value) => !values.includes(value));
    if (deletedChannel) {
      await this.props.client.mutate({
        mutation: DELETE_CHANNEL,
        variables: {
          userId: this.state.account,
          channel: deletedChannel,
        },
      });
    }
    this.setState((prev) => ({
      currentTargets: {
        affiliations: prev.currentTargets.affiliations,
        universities: prev.currentTargets.universities,
        notificationChannels: values,
        allUniversities: prev.currentTargets.allUniversities,
      },
    }));
  };

  handleUniversityChange = async (values) => {
    await this.props.client.mutate({
      mutation: UPDATE_TARGETS,
      variables: {
        userId: this.state.account,
        universities: values,
      },
    });
    this.setState((prev) => ({
      currentTargets: {
        affiliations: prev.currentTargets.affiliations,
        notificationChannels: prev.currentTargets.notificationChannels,
        universities: values,
        allUniversities: prev.currentTargets.allUniversities,
      },
    }));
  };

  handleUniversityChangeSwitch = async (value) => {
    await this.props.client.mutate({
      mutation: UPDATE_TARGETS,
      variables: {
        userId: this.state.account,
        allUniversities: value,
      },
    });
    this.setState((prev) => ({
      currentTargets: {
        affiliations: prev.currentTargets.affiliations,
        notificationChannels: prev.currentTargets.notificationChannels,
        universities: prev.currentTargets.universities,
        allUniversities: value,
      },
    }));
  };

  handleAffiliationChange = async (values) => {
    await this.props.client.mutate({
      mutation: UPDATE_TARGETS,
      variables: { userId: this.state.account, affiliations: values },
    });
    this.setState((prev) => ({
      currentTargets: {
        affiliations: values,
        notificationChannels: prev.currentTargets.notificationChannels,
        universities: prev.currentTargets.universities,
        allUniversities: prev.currentTargets.allUniversities,
      },
    }));
  };
  handleRoleTargetingChange = async (values) => {
    await this.props.client.mutate({
      mutation: UPDATE_TARGETS,
      variables: { userId: this.state.account, roles: values },
    });
    this.setState((prev) => ({
      currentTargets: {
        ...prev.currentTargets,
        roles: values,
      },
    }));
  };

  handlePipedriveChange = ({ target }) => {
    this.setState({
      pipedriveId: target.value,
    });
  };

  handleSearch = async (value) => {
    if (!value) {
      this.setState({ dataSource: [] });
      return;
    }

    this.setState({ searching: true, dataSource: [] });

    this.searchProfile(value);
  };

  handleConfirmInput = ({ target }) => {
    this.setState({ confirmInput: target.value });
  };

  searchProfile = debounce(async (value) => {
    let data =
      (await this.props.client.query({
        query: SEARCH_PROFILE,
        variables: { text: value },
      })) || {};

    if (!data.data || !data.data.searchProfile) {
      this.setState({ dataSource: [] });
      return;
    }

    this.setState({
      searching: false,
      dataSource: data.data.searchProfile.map((profile) => ({
        ...profile,
        value: profile.id,
        text: `${profile.displayName} (@${profile.username})`,
        accounts: [],
        pipedriveId: profile.pipedriveId,
        permissions: profile.permissions || [],
      })),
    });
  }, 500);

  savePipedrive = async () => {
    const { account, pipedriveId } = this.state;

    await this.props.client.mutate({
      mutation: UPDATE_PROFILE,
      variables: { id: account, pipedriveId },
    });

    message.success('Erfolgreich gespeichert.');
  };

  updatePermissions = async (permissions) => {
    const { account } = this.state;

    await this.props.client.mutate({
      mutation: UPDATE_PROFILE,
      variables: { id: account, permissions },
    });
  };

  onSelect = async (value) => {
    const { dataSource } = this.state;
    this.setState({ loading: true, accountsLoading: true });
    const profile = dataSource.find((data) => data.value === value);

    this.props.client
      .query({
        query: CURRENT_TARGETS_ACCOUNTS,
        variables: { userId: value },
        fetchPolicy: 'no-cache',
      })
      .then(({ data }) => {
        this.setState({
          accounts: data.user.accounts,
          accountsLoading: false,
        });
      });
    const fetchedTargets = await this.props.client.query({
      query: CURRENT_TARGETS,
      variables: { userId: value },
      fetchPolicy: 'no-cache',
    });

    let currentTargets = { universities: [], affiliations: [], roles: [] };
    if (fetchedTargets && fetchedTargets.data && fetchedTargets.data.user && fetchedTargets.data.user.targeting) {
      const { universities, affiliations, allUniversities, roles } = fetchedTargets.data.user.targeting;
      if (universities) {
        currentTargets.universities = universities;
      }

      if (affiliations) {
        currentTargets.affiliations = affiliations;
      }
      if (roles) {
        currentTargets.roles = roles;
      }

      if (allUniversities) {
        currentTargets.allUniversities = allUniversities;
      }
    }

    if (fetchedTargets.data.user.notificationChannels) {
      currentTargets.notificationChannels = fetchedTargets.data.user.notificationChannels.map(
        ({ globalChannel }) => globalChannel
      );
    }

    if (!this.state.affiliations) {
      const data = await this.props.client.query({ query: POSSIBLE_TARGETS });
      if (
        !data ||
        !data.data ||
        !data.data.possibleTargets ||
        !data.data.possibleTargets.affiliations ||
        !data.data.possibleTargets.notificationChannels
      ) {
        alert('An error occurred while fetching possible targets');
        return;
      }

      this.setState({
        profile,
        account: value,
        confirmInput: null,
        ...data.data,
        currentTargets,
        pipedriveId: profile.pipedriveId,
        permissions: profile.permissions,
        loading: false,
      });
    } else {
      this.setState({
        profile,
        account: value,
        confirmInput: null,
        currentTargets,
        pipedriveId: profile.pipedriveId,
        permissions: profile.permissions,
        loading: false,
      });
    }
  };

  resetPassword = async (account) => {
    const { client } = this.props;

    try {
      await client.mutate({
        mutation: RESET_PASSWORD,
        variables: { id: account.id },
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  switchProfile = async () => {
    const { account } = this.state;
    const { switchProfile } = this.context;
    const { client, history } = this.props;

    const { data = {} } = await client.mutate({
      mutation: SWITCH_PROFILE,
      variables: { id: account },
    });

    if (data.switchProfile) {
      await switchProfile(data.switchProfile);
      history.push('/');
    }
  };

  setAutoFollow = async () => {
    const { account } = this.state;
    const { client } = this.props;

    const hide = message.loading('Bitte warten. Dies kann einige Minuten dauern...', 0);

    try {
      const { data = {} } = await client.mutate({
        mutation: SET_AUTO_FOLLOW,
        variables: { id: account },
      });

      hide();
      message.success(`Auto-Follow erfolgreich eingestellt. ${data.setAutoFollow} neue Follower.`);
    } catch (err) {
      hide();
      message.error(err.message);
    }
  };

  getRegisterLink = async (account) => {
    const { client } = this.props;
    const { accounts = [] } = this.state;

    this.setState({
      [`loading${account.id}`]: true,
    });

    const { data } = await client.mutate({
      mutation: GET_REGISTER_LINK,
      variables: { id: account.id },
    });

    this.setState({
      [`loading${account.id}`]: false,
      accounts: accounts.map((acc) => {
        if (acc.id === account.id) {
          acc.inviteLink = data.getRegisterLink;
        }
        return acc;
      }),
    });
  };

  deleteProfile = () => {
    const { account } = this.state;
    const { client } = this.props;

    this.setState({ account: undefined, confirmInput: null, dataSource: [] });

    client.mutate({
      mutation: DELETE_PROFILE,
      variables: { id: account },
    });
  };

  render() {
    const { getFieldDecorator, getFieldError, getFieldValue, isFieldTouched, validateFields } = this.props.form;
    const createNotificationChannelError =
      isFieldTouched('createNotificationChannel') && getFieldError('createNotificationChannel');

    const { accountRole } = this.context;
    const {
      profile = {},
      searching,
      confirmInput,
      dataSource = [],
      accounts = [],
      permissions = [],
      account,
      currentTargets,
      possibleTargets,
      pipedriveId,
      loading,
      accountsLoading,
    } = this.state;

    const { visible, autoFollow } = profile;

    return (
      <div
        style={{
          minHeight: '400px',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'scroll',
        }}
      >
        <Form.Item
          label="Profile"
          help={
            searching ? (
              <span>
                <Spin size="small" /> Suche nach Profilen und Nutzern
              </span>
            ) : null
          }
        >
          <Row>
            <Col span={18}>
              <AutoComplete
                dataSource={dataSource}
                disabled={!!account}
                style={{ width: '100%' }}
                onSelect={this.onSelect}
                onSearch={this.handleSearch}
                placeholder="Suche nach einem Profil/Nutzer"
              />
              {account && <span style={{ fontSize: '0.8em', opacity: 0.5 }}>ID: {account}</span>}
            </Col>
            <Col span={5} offset={1}>
              <Button type="primary" disabled={!account} onClick={() => this.setState({ account: undefined })}>
                Clear
              </Button>
            </Col>
          </Row>
        </Form.Item>
        {loading && <Spin size="large" />}
        {account && (
          <div>
            <ChannelSelection
              notificationChannels={currentTargets.notificationChannels}
              handleChannelChange={this.handleChannelChange}
              onAddClick={() => this.setState({ showAddChannelModal: true })}
              options={possibleTargets.notificationChannels}
            />
            <Form.Item label="Targeting - Hochschule">
              <Row>
                <Col span={20}>
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Wähle eine Hochschule"
                    onChange={this.handleUniversityChange}
                    value={currentTargets.universities}
                    disabled={!!currentTargets.allUniversities}
                    filterOption={(text, option) => {
                      return (
                        option.key.toLowerCase().includes(text.toLowerCase()) ||
                        option.props.children[0].toLowerCase().includes(text.toLowerCase())
                      );
                    }}
                  >
                    {possibleTargets.universities.map((uni) => (
                      <Option key={uni.id}>
                        {uni.name} ({uni.id})
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Col span={3} offset={1}>
                  Alle: &nbsp;
                  <Switch
                    checked={currentTargets.allUniversities}
                    onChange={(e) => this.handleUniversityChangeSwitch(e)}
                  />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item label="Targeting - Fachrichtung">
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Wähle eine Fachrichtung"
                onChange={this.handleAffiliationChange}
                value={currentTargets.affiliations}
                filterOption={(text, option) => option.props.children.toLowerCase().includes(text.toLowerCase())}
              >
                {possibleTargets.affiliations.map((affiliation) => (
                  <Option key={affiliation}>{affiliation}</Option>
                ))}
              </Select>
            </Form.Item>
            <RoleTargetingSelect onChange={this.handleRoleTargetingChange} value={currentTargets.roles} />
            <Row type="flex" align="middle" justify="space-between" gutter={25}>
              <Col span={12}>
                <Form.Item label="Pipedrive ID">
                  <Input
                    style={{ width: '100%' }}
                    placeholder="Pipedrive ID (optional)"
                    onChange={this.handlePipedriveChange}
                    value={pipedriveId}
                    suffix={
                      <Icon type="save" onClick={this.savePipedrive} style={{ cursor: 'pointer', color: '#f21850' }} />
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                {accountRole === 'SUPERADMIN' && (
                  <Row style={{ textAlign: 'center' }}>
                    <Popconfirm
                      disabled={!visible || autoFollow}
                      onConfirm={this.setAutoFollow}
                      title="Diese Aktion ist unumkehrbar. Bist du dir sicher?"
                    >
                      <Button type="primary" disabled={!visible || autoFollow}>
                        Auto-Follow aktivieren
                      </Button>

                      <div className="size-small color-secondary" style={{ paddingTop: 5 }}>
                        Auto-Follow kann nur aktiviert werden, wenn das Profil bereits sichtbar ist und nicht bereits
                        auf Auto-Follow steht.
                      </div>
                    </Popconfirm>
                  </Row>
                )}
              </Col>
            </Row>

            <Row type="flex" align="middle" justify="space-between">
              <Form.Item label="Permissions">
                <Checkbox.Group defaultValue={permissions} onChange={this.updatePermissions}>
                  <Row type="flex" align="top" justify="space-between" gutter={10}>
                    {PERMISSIONS.map(({ id, label, description }) => (
                      <Col key={id} span={12}>
                        <Checkbox value={id}>
                          {label}
                          <div className="size-small color-secondary">{description}</div>
                        </Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Row>
            {accountRole === 'SUPERADMIN' && (
              <Row style={{ textAlign: 'center' }}>
                <Button type="primary" onClick={this.switchProfile}>
                  Zum Profil wechseln
                </Button>
              </Row>
            )}

            <Row>
              <Form.Item label="Accounts">
                {accountsLoading ? (
                  <Spin size="large" />
                ) : (
                  <AccountsList accountRole={accountRole} dataSource={accounts} client={this.props.client} />
                )}
              </Form.Item>
            </Row>
            {accountRole === 'SUPERADMIN' && (
              <Row style={{ textAlign: 'center' }}>
                <Popover
                  title="Profil unwiderruflich löschen？"
                  content={
                    <div>
                      <Row>
                        Folgende Daten werden gelöscht:
                        <ul>
                          <li>Profil</li>
                          <li>Accounts</li>
                          <li>Channel</li>
                          <li>Posts</li>
                        </ul>
                      </Row>
                      <Row>
                        <Form.Item help={`Tippe "${CONFIRM_VALUE}" ein zum bestätigen`}>
                          <Input onChange={this.handleConfirmInput} value={confirmInput} />
                        </Form.Item>
                      </Row>
                      <br />
                      <Row style={{ textAlign: 'right' }}>
                        <Button disabled={confirmInput !== CONFIRM_VALUE} type="primary" onClick={this.deleteProfile}>
                          Bestätigen
                        </Button>
                      </Row>
                    </div>
                  }
                  trigger="click"
                >
                  <Button type="primary">Profil löschen</Button>
                </Popover>
              </Row>
            )}
            <Modal
              title="Create new notification channel"
              visible={this.state.showAddChannelModal}
              okText="Create"
              onCancel={() => this.setState({ showAddChannelModal: false })}
              onOk={async () => {
                validateFields(['createNotificationChannel']);

                if (createNotificationChannelError) {
                  // An error occured
                  message.error(createNotificationChannelError);
                  return;
                }

                await this.props.client.mutate({
                  mutation: CREATE_NOTIFICATION_CHANNEL,
                  variables: {
                    channelName: getFieldValue('createNotificationChannel'),
                  },
                });

                message.success('Notification channel created');

                await this.onSelect(this.state.account);
                this.setState({ showAddChannelModal: false });
              }}
              okButtonProps={{
                disabled: createNotificationChannelError ? true : false,
              }}
            >
              <Form.Item
                validateStatus={createNotificationChannelError ? 'error' : ''}
                help={<ErrorTooltip errors={getFieldError('createNotificationChannel')} />}
              >
                {getFieldDecorator('createNotificationChannel', {
                  validateTrigger: null,
                  initialValue: null,
                  rules: [
                    { required: true, message: 'This can not be empty' },
                    { min: 3, message: 'Too Short' },
                    {
                      whitespace: true,
                      message: 'Blank channel name is invalid',
                    },
                    {
                      validator: async (rule, value) => {
                        if (possibleTargets.notificationChannels?.find((channel) => channel.name === value))
                          throw new Error('Channel already exists.');
                      },
                    },
                  ],
                })(<Input placeholder="Channel Name" onBlur={() => validateFields(['createNotificationChannel'])} />)}
              </Form.Item>
            </Modal>
          </div>
        )}
      </div>
    );
  }
}

Targeting.contextTypes = {
  accountRole: PropTypes.string,
  switchProfile: PropTypes.func.isRequired,
};

export default withRouter(withApollo(Form.create()(Targeting)));
