import React, { Component } from 'react';
import { debounce } from 'lodash';
import { Mutation, Query, withApollo } from 'react-apollo';
import { Modal, Form, Input, Radio, Select } from 'antd';

import { PROFILE_INFO, PROOF_EMAIL, PROOF_USERNAME } from 'core/gql/queries';
import { SIGNUP } from 'core/gql/mutations';

class CreateUser extends Component {
  state = {
    selectedRecipient: 'custom',
    validateStatusEmail: null,
    validateStatusUsername: null,
  };

  onCompleted = (e) => {
    this.props.hide();
  };

  handleCancel = (e) => {
    this.props.hide();
  };

  handleOk = (cb) => {
    const { validateFields } = this.props.form;

    validateFields((errors, values) => {
      if (!errors) {
        const variables = {
          email: values.email.trim(),
          username: values.username.trim(),
          displayName: values.displayName.trim(),
          role: values.role,
          pipedriveId: values.pipedriveId,
        };

        if (values.name) {
          variables.name = values.name.trim();
        }

        if (this.state.selectedRecipient === 'custom') {
          variables.recipient = values.recipient.trim();
        }

        cb({ variables });
      }
    });
  };

  proofUsername = async (value, callback) => {
    const res = await this.props.client.query({
      query: PROOF_USERNAME,
      variables: { username: value },
    });

    if (res && res.data && res.data.checkUsername === false) {
      callback();
    } else {
      callback('Profilname bereits vergeben');
    }
  };

  validateUsername = async () => {
    this.setState({ validateStatusUsername: 'validating' });

    this.validateUsernameDebounce();
  };

  validateUsernameDebounce = debounce(async () => {
    const { validateFields } = this.props.form;

    validateFields(['username'], (error) => {
      if (error) {
        this.setState({ validateStatusUsername: 'error' });
      } else {
        this.setState({ validateStatusUsername: 'success' });
      }
    });
  }, 500);

  proofEmail = async (value, callback) => {
    const res = await this.props.client.query({
      query: PROOF_EMAIL,
      variables: { email: value },
    });

    if (res && res.data && res.data.checkEmail === true) {
      callback();
    } else {
      callback('E-Mail bereits vergeben');
    }
  };

  validateMail = async () => {
    this.setState({ validateStatusEmail: 'validating' });

    this.validateMailDebounce();
  };

  validateMailDebounce = debounce(async () => {
    const { validateFields } = this.props.form;

    validateFields(['email'], (error) => {
      if (error) {
        this.setState({ validateStatusEmail: 'error' });
      } else {
        this.setState({ validateStatusEmail: 'success' });
      }
    });
  }, 500);

  render() {
    const { validateStatusEmail, validateStatusUsername } = this.state;
    const { getFieldDecorator } = this.props.form;
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };

    return (
      <Mutation mutation={SIGNUP} onCompleted={this.onCompleted}>
        {(send, { error }) => (
          <Modal
            destroyOnClose
            title="Neues Profil anlegen"
            visible={this.props.visible}
            onOk={() => this.handleOk(send)}
            onCancel={this.handleCancel}
            maskClosable={false}
          >
            <Form>
              <span className="color-secondary" style={{ fontSize: 18, marginBottom: 16 }}>
                Profil
              </span>
              <Form.Item>
                {getFieldDecorator('displayName', {
                  validateFirst: true,
                  validateTrigger: 'onBlur',
                  rules: [
                    {
                      min: 1,
                      max: 50,
                      message: 'Anzeigname muss aus 1-50 Zeichen bestehen',
                    },
                    {
                      pattern: /^[\w\süöäß\-\.]+$/i, // eslint-disable-line
                      message:
                        'Bitte benutze für deinen Anzeigenamen nur Buchstaben, Zahlen, Leerzeichen sowie folgende Zeichen: . und -',
                    },
                    {
                      required: true,
                      message: 'Bitte gib einen Anzeignamen ein',
                    },
                  ],
                })(<Input placeholder="Anzeigname" />)}
              </Form.Item>
              <Form.Item hasFeedback={true} validateStatus={validateStatusUsername}>
                {getFieldDecorator('username', {
                  validateTrigger: null,
                  validateFirst: true,
                  rules: [
                    {
                      required: true,
                      message: 'Bitte gib einen Profilnutzername ein',
                    },
                    {
                      min: 3,
                      message: 'Bitte gib mindestens 3 Zeichen ein',
                    },
                    {
                      max: 30,
                      message: 'Bitte gib maximal 30 Zeichen ein',
                    },
                    {
                      pattern: /^[\wüöäß\-_\.]+$/i, // eslint-disable-line
                      message: 'Bitte benutze für deinen Profilnutzername nur die Zeichen: a-z, A-Z, 0-9, - , _ und .',
                    },
                    {
                      pattern: new RegExp('^[a-zA-Z]'),
                      message: 'Bitte mit einem Buchstaben beginnen',
                    },
                    {
                      validator: (rule, value, callback) => {
                        if (/uninow|admin/gim.test(value)) {
                          callback('Bitte vermeide folgende Wörter: UniNow, Admin');
                        } else {
                          callback();
                        }
                      },
                    },
                    {
                      validator: (rule, value, callback) => this.proofUsername(value, callback),
                    },
                  ],
                })(<Input addonBefore="@" placeholder="Profilname" onChange={this.validateUsername} />)}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('role', {
                  validateFirst: true,
                  validateTrigger: 'onBlur',
                  rules: [
                    {
                      required: true,
                      message: 'Bitte gib eine Rolle an',
                    },
                  ],
                })(
                  <Select placeholder="Rolle">
                    <Select.Option value="CONTENT_PROVIDER">Content Provider</Select.Option>
                    <Select.Option value="STUDENT_COUNCIL">Fachschaft</Select.Option>
                    <Select.Option value="INSTITUTION">Institution</Select.Option>
                    <Select.Option value="UNIVERSITY">Universität</Select.Option>
                    <Select.Option value="USER">Sonstige</Select.Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item>{getFieldDecorator('pipedriveId')(<Input placeholder="Pipedrive ID (optional)" />)}</Form.Item>
              <span className="color-secondary" style={{ fontSize: 18, marginBottom: 16 }}>
                Account
              </span>
              <Form.Item>
                {getFieldDecorator('name', {
                  validateTrigger: 'onBlur',
                })(<Input placeholder="Name des Benutzers (optional)" />)}
              </Form.Item>
              <Form.Item hasFeedback={true} validateStatus={validateStatusEmail}>
                {getFieldDecorator('email', {
                  validateTrigger: null,
                  validateFirst: true,
                  rules: [
                    {
                      type: 'email',
                      message: 'Keine valide E-Mail-Adresse',
                    },
                    {
                      required: true,
                      message: 'Bitte gib eine E-Mail-Adresse ein',
                    },
                    {
                      validator: (rule, value, callback) => this.proofEmail(value, callback),
                    },
                  ],
                })(<Input type="email" placeholder="E-Mail-Adresse des Benutzers" onChange={this.validateMail} />)}
              </Form.Item>
              <span className="color-secondary" style={{ fontSize: 18, marginBottom: 16 }}>
                Registrierungsmail senden an:
              </span>
              <Form.Item>
                <Query query={PROFILE_INFO}>
                  {({ loading, error, data }) => {
                    if (loading || error || !data.me) {
                      return null;
                    }

                    const { email } = data.me;

                    return (
                      <Radio.Group
                        onChange={(e) =>
                          this.setState({ selectedRecipient: e.target.value }, () =>
                            this.props.form.validateFields(['recipient'])
                          )
                        }
                        style={{ width: '96%' }}
                        value={this.state.selectedRecipient}
                      >
                        <Radio style={radioStyle} value="self">
                          E-Mail-Adresse des Benutzers
                        </Radio>
                        <Radio style={radioStyle} value="custom">
                          {getFieldDecorator('recipient', {
                            initialValue: email,
                            rules: [
                              {
                                validator: (rule, value, callback) => {
                                  if (this.state.selectedRecipient === 'custom' && !value) {
                                    callback('Bitte gib einen Empfänger an.');
                                  } else {
                                    callback();
                                  }
                                },
                              },
                            ],
                          })(<Input placeholder="Oder gib eine andere E-Mail Adresse an" style={{ width: '100%' }} />)}
                        </Radio>
                      </Radio.Group>
                    );
                  }}
                </Query>
              </Form.Item>
            </Form>
          </Modal>
        )}
      </Mutation>
    );
  }
}

export default Form.create()(withApollo(CreateUser));
