import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Picker from 'emoji-picker-react';

import { Mutation, withApollo } from 'react-apollo';
import { Button, Form, Row, Col, Tooltip, Modal, Alert } from 'antd';

import { transform, mapValues, reduce, omit, isEmpty, findKey } from 'lodash';

import { PROFILE } from 'core/gql/queries';
import { SEND, UPDATE_POST } from 'core/gql/mutations';
import { ATTACHMENT_TYPES } from '../../core/constants';

import { connect } from 'react-redux';
import { updatePreview, resetPreview, setPreviewLanguage } from '../../core/redux/actions/preview';
import { resetPost } from '../../core/redux/actions/post';
import { updateCacheAfterSend } from '../../core/gqlCache';

import Attachment from './components/Attachment';

import { attachmentFromValues } from './components/Attachment/builder';

import './style.css';
import PostTextArea from './components/PostTextArea';
import { NewPostInput } from './components/NewPostInput';
import PreviewButton from './components/PreviewButton';
import ChannelSelection from './components/ChannelSelection';
import ReleaseDatePicker from './components/ReleaseDatePicker';
import PushConfiguration from './components/PushConfiguration';

class PostCreator extends Component {
  state = {
    pendingUpdate: false,
    selectedAttachment: null,
    attachment: null,
    selectedChannels: {},
    displayVerifyModal: false,
    publishDateSelection: 'now',
    emergencyPopconfirmVisible: false,
    language: 'de',
    primaryLanguage: 'de',
    showEmojiPicker: false,
  };

  UNSAFE_componentWillMount() {
    this.props.resetPreview();
    this.props.resetPost();
  }

  resetState = () => {
    const { setFieldsValue } = this.props.form;

    setFieldsValue({
      content: null,
      [`translations.content.de`]: null,
      [`translations.content.en`]: null,
      type: null,
    });
    this.props.resetPreview();
    this.props.resetPost();
    this.resetModal();
    this.setState({
      pendingUpdate: false,
      displayVerifyModal: false,
      selectedAttachment: null,
      attachment: null,
    });
  };

  resetModal = () => {
    const { setFieldsValue } = this.props.form;
    setFieldsValue({
      publishDate: undefined,
    });
    this.setState({
      selectedChannels: {},
      publishDateSelection: 'now',
      emergencyPopconfirmVisible: false,
    });
  };

  selectAttachment = (attachment = null) => {
    const { setFieldsValue } = this.props.form;
    const { selectedAttachment } = this.state;

    setFieldsValue({
      type: attachment,
    });

    this.setState({
      selectedAttachment: selectedAttachment === attachment ? null : attachment,
    });
  };

  transformSelectedText = (
    type,
    contentID = 'content',
    inputClassname = 'text-area PostCreatorContentTextArea',
    emoji = '😁'
  ) => {
    const elements = document.getElementsByClassName(inputClassname);

    if (elements.length > 0) {
      const [textArea] = elements;

      const { getFieldValue, setFieldsValue } = this.props.form;
      const textToMarkdown = (type, text) => {
        switch (type) {
          case 'italic':
            return `*${text}*`;
          case 'bold':
            return `**${text}**`;
          case 'emoji':
            return emoji;
          default:
            return text;
        }
      };

      const { selectionStart, selectionEnd } = textArea;
      const content = getFieldValue(contentID) || '';
      const newSubText = content.substr(selectionStart, selectionEnd - selectionStart);
      const replacedContent = `${content.substr(0, selectionStart)}${textToMarkdown(type, newSubText)}${content.substr(
        selectionEnd
      )}`;
      setFieldsValue({ [contentID]: replacedContent });
    }
  };

  verifyForm = () => {
    const { validateFields } = this.props.form;
    const editMode = !!this.props.post;
    validateFields((errors, values) => {
      if (!errors) {
        if (editMode) {
          this.updatePost(values);
          this.setState({ validateError: false });
        } else {
          this.setState({
            displayVerifyModal: !this.state.displayVerifyModal,
            validateError: false,
          });
        }
      } else {
        this.setState({ validateError: true });
      }
    });
  };

  updatePost = async (values) => {
    const { post, client } = this.props;
    const { content, translations } = values;

    this.setState({ pendingUpdate: true });

    const variables = {
      id: post.id,
      content: values.content || values?.translations?.content?.[this.state.primaryLanguage],
      translations: this.transformTranslations(values.translations),
    };

    const type = post.attachments[0] ? post.attachments[0].type.toLowerCase() : null;

    const attachment = attachmentFromValues(type, values);
    if (type && attachment) {
      variables[type] = {
        ...attachment,
        translations: this.transformTranslations(attachment.translations),
      };
    }

    await client.mutate({
      mutation: UPDATE_POST,
      variables,
    });

    this.resetState();
  };

  transformTranslations = (object, toObject = false) => {
    if (!object) return;
    if (toObject) {
      return reduce(
        object,
        (result, value, key) => ({
          ...result,
          [key]:
            key === 'options'
              ? value.map((el) => reduce(el, (res, val) => ({ ...res, [val.lang]: val.value }), {}))
              : reduce(value, (res, val) => ({ ...res, [val.lang]: val.value }), {}),
        }),
        {}
      );
    }

    return mapValues(object, (el) =>
      Array.isArray(el)
        ? el.map((e) =>
            transform(
              e,
              (result, value, key) =>
                isEmpty(value) && typeof value !== 'number' ? result : result.push({ lang: key, value }),
              []
            )
          )
        : transform(
            el,
            (result, value, key) =>
              isEmpty(value) && typeof value !== 'number' ? result : result.push({ lang: key, value }),
            []
          )
    );
  };

  sendPost = (callback, channels = []) => {
    const { channelFilter } = this.props;

    const { selectedAttachment, selectedChannels = {}, publishDateSelection } = this.state;

    const { validateFields } = this.props.form;

    validateFields((errors, values) => {
      if (errors) {
        this.setState({ validateError: true });
        return;
      } else {
        this.setState({ validateError: false });
      }

      let post = {
        translations: values.translations,
        type: selectedAttachment,
      };

      const attachment = attachmentFromValues(selectedAttachment, values);
      if (post.type === 'recruiting') {
        post.type = attachment.jobs ? 'jobs' : 'company';
      }

      const notificationChannels = (
        channelFilter
          ? channels.filter(channelFilter).map((channel) => ({ channel, notification: true }))
          : Object.values(selectedChannels)
      ).map(({ channel, notification }) => ({
        id: channel.id,
        notification,
      }));

      if (callback) {
        let customPushConfigIsActive = false;

        try {
          const data = this.props.client.readQuery({
            query: PROFILE,
          });
          customPushConfigIsActive = data?.me?.permissions?.includes('CUSTOM_POST_PUSHCONFIG');
        } catch {}

        let body = {
          variables: {
            content: post.content || post?.translations?.content?.[this.state.primaryLanguage],
            translations: this.transformTranslations(post.translations),
            notificationChannels,
            pushConfig:
              values.pushconfig && customPushConfigIsActive
                ? {
                    title:
                      values.pushconfig.title && values.pushconfig.title !== '' ? values.pushconfig.title : undefined,
                    text: values.pushconfig.text && values.pushconfig.text !== '' ? values.pushconfig.text : undefined,
                  }
                : {},
          },
        };
        if (publishDateSelection !== 'now') {
          body.variables.publishDate = values.publishDate.toISOString();

          if (post.type === 'poll' && values.pollEndSelection !== 'custom') {
            attachment.end = moment(values.publishDate).add(1, values.pollEndSelection).format();
          }
        }

        if (post.type) {
          body.variables[post.type] = {
            ...attachment,
            translations: this.transformTranslations(attachment.translations),
          };
        }
        callback(body);
      }
    });
  };

  renderForm = (lang, multipleTabs = false) => {
    const { post, form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;

    const { type = null } = post ? post.attachments[0] || {} : {};

    const contentID = `translations.content.${lang}`;
    const inputClassname = `text-area PostCreatorContentTextArea-${lang}`;

    const primaryValue = getFieldValue(`translations.content.${this.state.primaryLanguage}`);
    return (
      <Form
        className="form"
        style={{
          borderTopLeftRadius: !multipleTabs ? '8px' : 0,
        }}
      >
        <Form.Item>
          {getFieldDecorator(contentID, {
            rules: [
              {
                required: lang === this.state.primaryLanguage,
              },
            ],
          })(
            <PostTextArea
              disabled={type === 'Poll'}
              className={inputClassname}
              primaryValue={primaryValue}
              primaryLanguage={this.state.primaryLanguage}
              lang={lang}
            />
          )}
        </Form.Item>
        {this.renderAttachmentForm(lang)}
        <br />
        {getFieldDecorator('type')(this.renderAttachmentSelection(contentID, inputClassname))}
      </Form>
    );
  };

  renderAttachmentSelection = (contentID = 'content', inputClassname = 'text-area PostCreatorContentTextArea') => {
    const { role, accountRole } = this.context;
    const { selectedAttachment, showEmojiPicker } = this.state;

    const editMode = !!this.props.post;

    return (
      <Row>
        <div className="fontButtons">
          <div className="attachment-select">
            <Tooltip title="Emoji">
              <Button
                shape="circle"
                icon="smile"
                onClick={() => {
                  this.setState({ showEmojiPicker: !showEmojiPicker });
                }}
              />
              {showEmojiPicker && (
                <Picker
                  onEmojiClick={(event, emojiObject) => {
                    this.transformSelectedText('emoji', contentID, inputClassname, emojiObject.emoji);
                    this.setState({ showEmojiPicker: false });
                  }}
                />
              )}
            </Tooltip>
          </div>
          <div className="attachment-select">
            <Tooltip title="Fett">
              <Button
                shape="circle"
                icon="bold"
                onClick={() => {
                  this.transformSelectedText('bold', contentID, inputClassname);
                }}
              />
            </Tooltip>
          </div>
          <div className="attachment-select">
            <Tooltip title="Kursiv">
              <Button
                type="dashed"
                shape="circle"
                icon="italic"
                onClick={() => {
                  this.transformSelectedText('italic', contentID, inputClassname);
                }}
              />
            </Tooltip>
          </div>
        </div>
        <div className="attachmentButtons">
          {ATTACHMENT_TYPES.filter(
            (attachment) =>
              !attachment.roles || attachment.roles.includes(role) || attachment.roles.includes(accountRole)
          ).map((attachment) => (
            <div className="attachment-select" key={attachment.type}>
              <Tooltip title={attachment.title}>
                <Button
                  disabled={editMode && selectedAttachment !== attachment.type}
                  className={selectedAttachment === attachment.type ? 'active' : ''}
                  type={selectedAttachment === attachment.type ? '' : 'dashed'}
                  shape="circle"
                  icon={attachment.icon}
                  onClick={editMode ? null : () => this.selectAttachment(attachment.type)}
                />
              </Tooltip>
            </div>
          ))}
        </div>
      </Row>
    );
  };

  renderAttachmentForm = (lang) => {
    const { selectedAttachment, attachment } = this.state;

    return (
      <Attachment
        form={this.props.form}
        onClose={() => this.selectAttachment(null)}
        type={selectedAttachment}
        data={attachment}
        language={lang}
        activeLanguage={this.state.language}
      />
    );
  };

  renderVerifyModal = (callback, loading, error) => {
    const { channelFilter, client } = this.props;

    const { displayVerifyModal, selectedChannels = {}, publishDateSelection } = this.state;

    const { setFields, getFieldValue } = this.props.form;

    const { me = {} } = displayVerifyModal
      ? client.readQuery({
          query: PROFILE,
        })
      : {};
    const { notificationChannels = [] } = me;
    const content = this.props.form.getFieldValue('content') || 'Notfall';

    const pushConfigIsActive = me?.permissions?.includes('CUSTOM_POST_PUSHCONFIG');

    return (
      <Modal
        visible={displayVerifyModal}
        footer={null}
        onCancel={() =>
          this.setState(
            {
              displayVerifyModal: !displayVerifyModal,
              emergencyPopconfirmVisible: false,
            },
            this.resetModal
          )
        }
        className="PostCreatorVerifyModal"
        centered
      >
        <Form>
          {error && (
            <Alert
              type="error"
              message="
                Der Post konnte aufgrund eines Fehlers nicht veröffentlicht
                werden. Versuch es gleich wieder oder kontaktiere uns."
              style={{ width: '100%', marginBottom: 10 }}
            />
          )}
          <ChannelSelection
            channelFilter={channelFilter}
            setSelectedChannels={(newSelection) => {
              this.setState({ selectedChannels: newSelection });
            }}
            selectedChannels={selectedChannels}
            notificationChannels={notificationChannels}
          />
          <ReleaseDatePicker
            form={this.props.form}
            publishDateSelection={publishDateSelection}
            accountRole={this.state.accountRole}
            onChangePublishDateSelection={(newValue) =>
              this.setState({ publishDateSelection: newValue }, () => {
                if (newValue === 'now') {
                  setFields({
                    publishDate: {
                      value: getFieldValue('publishDate'),
                      errors: null,
                    },
                  });
                }
              })
            }
          />
          {!!pushConfigIsActive && (
            <PushConfiguration
              selectedChannels={this.state.selectedChannels}
              form={this.props.form}
              content={content}
              me={me}
            />
          )}
          <Form.Item className="verify-section" style={{ textAlign: 'center' }}>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              onClick={() => this.sendPost(callback, notificationChannels)}
            >
              Posten
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    );
  };
  migrateAttachmentToTranslations = (attachment, type) => {
    switch (type) {
      case 'blog':
        return {
          image: {
            [this.state.primaryLanguage]: attachment?.image?.large
              ? {
                  large: attachment.image.large,
                }
              : null,
          },
          title: {
            [this.state.primaryLanguage]: attachment?.title ? attachment.title : null,
          },
          subtitle: {
            [this.state.primaryLanguage]: attachment?.subtitle ? attachment.subtitle : null,
          },
          description: {
            [this.state.primaryLanguage]: attachment?.description ? attachment.description : null,
          },
          url: {
            [this.state.primaryLanguage]: attachment?.url ? attachment.url : null,
          },
        };
      case 'podcast':
        return {
          image: {
            [this.state.primaryLanguage]: attachment?.image?.large
              ? {
                  large: attachment.image.large,
                }
              : null,
          },
          title: {
            [this.state.primaryLanguage]: attachment?.title ? attachment.title : null,
          },
          description: {
            [this.state.primaryLanguage]: attachment?.description ? attachment.description : null,
          },
          duration: {
            [this.state.primaryLanguage]: attachment?.duration ? attachment.duration : null,
          },
          audio: {
            [this.state.primaryLanguage]: attachment?.audio?.raw
              ? {
                  raw: attachment.audio.raw,
                }
              : null,
          },
        };
      case 'gallery':
        return {
          images: {
            [this.state.primaryLanguage]: attachment?.images?.map
              ? attachment.images.map((image) => ({
                  raw: image.raw,
                  large: image.large,
                }))
              : [],
          },
        };
      case 'video':
        return {
          video: {
            [this.state.primaryLanguage]: attachment?.video?.raw
              ? {
                  raw: attachment.video.raw,
                  payload: attachment.video.payload,
                }
              : null,
          },
        };
      case 'event':
        return {
          title: {
            [this.state.primaryLanguage]: attachment?.title ? attachment.title : null,
          },
        };
      case 'poll':
        return {
          options: attachment?.options?.map
            ? attachment.options.map((el) => ({
                [this.state.primaryLanguage]: el.option,
              }))
            : [],
        };
      default:
        return {};
    }
  };

  loadPost = (post) => {
    const { setFieldsValue } = this.props.form;
    const { content, attachments = [] } = post;

    const type = attachments.length > 0 ? attachments[0].type.toLowerCase() : null;

    if (
      post?.translations &&
      (findKey(omit(post.translations, ['__typename', '__proto__']), (el) => !isEmpty(el)) ||
        (type && findKey(omit(attachments?.[0]?.translations, ['__typename', '__proto__']), (el) => !isEmpty(el))))
    ) {
      this.setState(
        {
          selectedAttachment: null,
        },
        () => {
          this.setState(
            {
              selectedAttachment: type,
              attachment: type
                ? {
                    ...attachments[0],
                    translations: this.transformTranslations(omit(attachments?.[0]?.translations, '__typename'), true),
                  }
                : null,
            },
            () => {
              setFieldsValue({
                type,
                translations: this.transformTranslations(omit(post?.translations, '__typename'), true),
                attachment: {
                  translations: this.transformTranslations(omit(attachments?.[0]?.translations, '__typename'), true),
                },
              });
            }
          );
        }
      );
    } else {
      this.setState(
        {
          selectedAttachment: null,
        },
        () => {
          this.setState(
            {
              selectedAttachment: type,
              attachment: type
                ? {
                    ...attachments[0],
                    translations: this.migrateAttachmentToTranslations(attachments?.[0], type),
                  }
                : null,
            },
            () => {
              setFieldsValue({
                translations: { content: { de: content } },
                type,
              });
            }
          );
        }
      );
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const post = this.props.post || {};
    const nextPost = nextProps.post || {};

    if (nextPost.id && post.id !== nextPost.id) {
      this.loadPost(nextPost);
    }
  }

  render() {
    const { pendingUpdate } = this.state;
    const editMode = !!this.props.post;

    return (
      <Mutation mutation={SEND} onCompleted={this.resetState} update={updateCacheAfterSend}>
        {(send, { loading, error }) => (
          <div id="PostCreator" className="PostCreator">
            <NewPostInput
              renderForm={this.renderForm}
              changeLanguage={(key) => {
                this.setState({ language: key });
                this.props.setPreviewLanguage(key);
              }}
              validateError={this.state.validateError}
              primaryLanguage={this.state.primaryLanguage}
            />
            {this.renderVerifyModal(send, loading, error)}
            <br />
            <Row>
              <Col span={24}>
                <PreviewButton />

                <Button
                  loading={loading || pendingUpdate}
                  type="primary"
                  onClick={this.verifyForm}
                  style={{ float: 'right' }}
                >
                  {editMode ? 'Aktualisieren' : 'Posten'}
                </Button>
                {editMode && (
                  <Button
                    loading={loading || pendingUpdate}
                    onClick={this.resetState}
                    style={{
                      float: 'right',
                      marginRight: 15,
                      borderRadius: 100,
                      padding: '0px 25px',
                    }}
                  >
                    Abbrechen
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        )}
      </Mutation>
    );
  }
}

PostCreator.propTypes = {
  channelFilter: PropTypes.func,
  postFilter: PropTypes.string,
  plannedPostFilter: PropTypes.string,
  setPreviewLanguage: PropTypes.func.isRequired,
};

PostCreator.defaultProps = {
  postFilter: 'PUBLISHED',
  plannedPostFilter: 'PLANNED',
};

PostCreator.contextTypes = {
  role: PropTypes.string,
  accountRole: PropTypes.string,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  updatePreview: (post) => dispatch(updatePreview(post)),
  setPreviewLanguage: (lang) => dispatch(setPreviewLanguage(lang)),
  resetPreview: () => dispatch(resetPreview()),
  resetPost: () => dispatch(resetPost()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    onValuesChange: (props, changedValues, values) => {
      const { updatePreview } = props;
      const { content, type, attachment = {}, translations = {} } = values;
      const post = {
        content,
        type,
        attachment,
        translations,
      };

      if (changedValues['attachment.end']) {
        post.attachment.end = changedValues['attachment.end'];
      }

      updatePreview(post);
    },
  })(withApollo(PostCreator))
);
