import React, { Component } from 'react';

import { Form, Input, Button, notification } from 'antd';
import { Mutation, withApollo } from 'react-apollo';

import { PROFILE, PROOF_EMAIL } from 'core/gql/queries';
import { UPDATE_USER } from 'core/gql/mutations';

class AccountForm extends Component {
  state = {
    pendingChanges: false,
  };

  updatePending = (event) => {
    const { pendingChanges } = this.state;
    const { getFieldsValue } = this.props.form;

    const values = {
      ...getFieldsValue(),
      [event.target.id]: event.target.value,
    };

    const hasChanges = ['email', 'name'].reduce((curr, field) => curr | (values[field] !== this.data[field]), false);

    if (hasChanges !== pendingChanges) {
      this.setState({
        pendingChanges: hasChanges,
      });
    }
  };

  validateEmail = async (value, ownEmail, callback) => {
    clearTimeout(this.timer);
    if (value === ownEmail) callback();
    const res = await this.props.client.query({
      query: PROOF_EMAIL,
      variables: { email: value },
    });

    if (res && res.data && res.data.checkEmail === true) {
      callback();
    } else {
      callback('E-Mail bereits vergeben');
    }
  };

  updateTimer(field) {
    clearTimeout(this.timer);
    this.setState(
      { timerValidatingField: field },
      () =>
        (this.timer = setTimeout(() => {
          this.props.form.validateFields([this.state.timerValidatingField]);
        }, 1000))
    );
  }

  saveSettings = async (callback, currentSettings) => {
    const { validateFields } = this.props.form;

    validateFields(async (errors, values) => {
      if (errors) {
        return;
      }

      if (callback) {
        let body = {
          variables: {},
        };

        ['email', 'name'].forEach((prop) => {
          if (values[prop] && values[prop] !== currentSettings[prop]) {
            body.variables[prop] = values[prop];
          }
        });

        callback(body);
        this.setState({ pendingChanges: false });
      }
    });
  };

  onError = () => {
    notification.error({
      message: 'Einstellungen konnten nicht gespeichert werden',
      description: 'Bitte versuchen Sie es später noch einmal oder kontaktieren Sie uns.',
      duration: 3,
    });
  };

  onSuccess = () => {
    notification.success({
      message: 'Einstellungen wurden aktualisiert',
      description: 'Ihre Einstellungen wurden erfolgreich gespeichert',
      duration: 3,
    });
  };

  render() {
    const { pendingChanges } = this.state;
    const { getFieldDecorator } = this.props.form;

    const { email = '', name = '' } = this.props.data;

    this.data = {
      email,
    };

    const formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13, offset: 2 },
        md: { span: 15, offset: 2 },
      },
    };

    return (
      <Form>
        <div className="headline weight-bold size-large">Account</div>
        <Form.Item
          label="Zugangs-Mail"
          {...formLayout}
          hasFeedback={true}
          extra={<div>Mit dieser E-Mail-Adresse loggst du dich im UniNow-Feed ein.</div>}
        >
          {getFieldDecorator('email', {
            initialValue: email,
            validateFirst: true,
            validateTrigger: 'onBlur',
            rules: [
              {
                required: true,
                message: 'Bitte gib deine E-Mail ein',
              },
              {
                type: 'email',
                message: 'Bitte gib eine gültige E-Mail ein',
              },
              {
                validator: (rule, value, callback) => this.validateEmail(value, email, callback),
              },
            ],
          })(
            <Input
              type="email"
              disabled={true}
              onChange={(event) => {
                this.updatePending(event);
                this.updateTimer('email');
              }}
            />
          )}
        </Form.Item>
        <Form.Item label="Dein Name" {...formLayout} hasFeedback={true}>
          {getFieldDecorator('name', {
            initialValue: name,
            validateFirst: true,
            validateTrigger: 'onBlur',
            rules: [],
          })(
            <Input
              disabled={true}
              onChange={(event) => {
                this.updatePending(event);
              }}
            />
          )}
        </Form.Item>
        <Form.Item {...formLayout} label=" " colon={false}>
          <Mutation
            onError={this.onError}
            onCompleted={this.onSuccess}
            mutation={UPDATE_USER}
            update={(cache, { data: { updateUser } }) => {
              const { me } = cache.readQuery({
                query: PROFILE,
              });
              cache.writeQuery({
                query: PROFILE,
                data: { me: { ...me, ...updateUser } },
              });
            }}
          >
            {(send, { loading }) => (
              <a href="https://accounts.uninow.com" target="_blank" rel="noopener noreferrer">
                Weiter zum accounts.uninow.com
              </a>
            )}

            {/*(send, { loading }) => (
              <Button
                loading={loading}
                disabled={!pendingChanges}
                type="primary"
                htmlType="submit"
                onClick={() => this.saveSettings(send)}
              >
                Änderungen Speichern
              </Button>              
            )*/}
          </Mutation>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create()(withApollo(AccountForm));
