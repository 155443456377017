import { useState, useEffect, useCallback } from 'react';

const breakpoints = [
  ['xs', 0],
  ['sm', 576],
  ['md', 768],
  ['lg', 992],
  ['xl', 1200],
  ['xxl', 1600],
];

function sizeToBreakpoint(size) {
  const index = breakpoints.findIndex((breakpoint) => breakpoint[1] > size);

  return (breakpoints[index - 1] || breakpoints[breakpoints.length - 1])[0];
}

export default function () {
  const [breakpoint, setBreakpoint] = useState(sizeToBreakpoint(window.innerWidth));

  const onResize = useCallback(() => {
    const nextBreakpoint = sizeToBreakpoint(window.innerWidth);

    if (nextBreakpoint !== breakpoint) {
      setBreakpoint(nextBreakpoint);
    }
  }, [breakpoint]);

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);

  return { breakpoint, breakpoints };
}
