import React, { Component } from 'react';

import update from 'immutability-helper';

import IconPopover from '../../../components/IconPopover';
import Ionicon, { ICONS } from '../../../components/Ionicon';

import { Spin, Icon, List, Input, Tooltip, Button, Empty, Row, Col } from 'antd';
import { withApollo } from 'react-apollo';

import { FRAGMENT_PROFILE_DATA } from 'core/gql/fragments';
import { PROFILE, GET_LINK_COLLECTION } from 'core/gql/queries';
import { SAVE_LINK_COLLECTION } from 'core/gql/mutations';

const PLACEHOLDER = {
  title: 'Titel',
  icon: ICONS[0],
  link: 'https://uninow.de',
};

class LinkCollection extends Component {
  state = {
    editIndex: null,
    loading: true,
    error: false,
    title: null,
    icon: null,
    link: null,
    links: [],
    id: null,
  };

  async componentDidMount() {
    const { client } = this.props;
    try {
      const { data } = await client.query({
        query: GET_LINK_COLLECTION,
        fetchPolicy: 'network-only',
      });

      const { id, links = [] } = data.me.linkCollections[0];

      this.setState({
        id,
        links,
        loading: false,
        error: false,
      });
    } catch (error) {
      this.setState({ loading: false, error: true, links: [] });
    }
  }

  renderLink = (item, index) => {
    const { editIndex, title, icon, link, links = [] } = this.state;

    if (editIndex === index) {
      return (
        <List.Item
          actions={[
            <Tooltip title="Speichern">
              <Icon className="color-primary" type="save" onClick={() => this.saveLink(index)} />
            </Tooltip>,
            <Tooltip title="Abbrechen">
              <Icon className="color-primary" type="close" onClick={() => this.cancelEdit(index)} />
            </Tooltip>,
          ]}
        >
          <List.Item.Meta
            avatar={
              <IconPopover value={icon} defaultValue={item.icon} onSelect={(icon) => this.updateValue('icon', icon)} />
            }
            title={
              <Input
                size="small"
                value={title}
                placeholder={item.title || PLACEHOLDER.title}
                onChange={({ target }) => this.updateValue('title', target.value)}
              />
            }
            description={
              <Input
                size="small"
                value={link}
                placeholder={item.link || PLACEHOLDER.link}
                onChange={({ target }) => this.updateValue('link', target.value)}
              />
            }
          />
        </List.Item>
      );
    } else {
      return (
        <Row type="flex" align="middle" justify="center" gutter={5} style={{ paddingBottom: 10 }}>
          <Col span={2} style={{ textAlign: 'center' }}>
            <Row>
              <Icon
                type="up"
                className={index !== 0 ? 'clickable-primary' : 'color-secondary'}
                onClick={index !== 0 ? () => this.moveLink(index, -1) : null}
              />
            </Row>
            <Row>
              <span className="weight-bold">{index + 1}</span>
            </Row>
            <Row>
              <Icon
                type="down"
                className={index < links.length - 1 ? 'clickable-primary' : 'color-secondary'}
                onClick={index < links.length - 1 ? () => this.moveLink(index, 1) : null}
              />
            </Row>
          </Col>
          <Col span={22}>
            <List.Item
              actions={[
                <Tooltip title="Bearbeiten">
                  <Icon className="color-primary" type="edit" onClick={() => this.startEdit(index)} />
                </Tooltip>,
                <Tooltip title="Löschen">
                  <Icon className="color-primary" type="delete" onClick={() => this.removeLink(index)} />
                </Tooltip>,
              ]}
            >
              <List.Item.Meta
                avatar={<Ionicon type={item.icon} />}
                title={item.title}
                description={
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    {item.link}
                  </a>
                }
              />
            </List.Item>
          </Col>
        </Row>
      );
    }
  };

  updateValue = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  resetValues = () => {
    this.setState({
      title: null,
      icon: null,
      link: null,
    });
  };

  removeLink = (index) => {
    this.cancelEdit();
    this.setState((state) => update(state, { links: { $splice: [[index, 1]] } }), this.saveLinks);
  };

  saveLink = (index) => {
    const { title, icon, link, links = [] } = this.state;
    const linkItem = links[index];

    const newLinkItem = {
      title: title || linkItem.title || PLACEHOLDER.title,
      icon: icon || linkItem.icon || PLACEHOLDER.icon,
      link: link || linkItem.link || PLACEHOLDER.link,
    };

    if (!newLinkItem.link.startsWith('http')) {
      newLinkItem.link = `https://${newLinkItem.link}`;
    }

    this.setState((state) => update(state, { links: { [index]: { $set: newLinkItem } } }), this.saveLinks);
    this.setState({ editIndex: null });
    this.resetValues();
  };

  saveLinks = async () => {
    const { client } = this.props;
    const { id, links = [] } = this.state;

    await client.mutate({
      mutation: SAVE_LINK_COLLECTION,
      variables: {
        id,
        links,
      },
    });

    const { me } = client.readQuery({
      query: PROFILE,
    });

    const fragmentId = `${me.__typename}:${me.id}`;

    await client.writeFragment({
      id: fragmentId,
      fragment: FRAGMENT_PROFILE_DATA,
      data: update(
        client.readFragment({
          id: fragmentId,
          fragment: FRAGMENT_PROFILE_DATA,
        }),
        {
          freshmanInUse: {
            $set: Date.now(),
          },
        }
      ),
    });
  };

  startEdit = (index) => {
    const linkItem = this.state.links[index];
    const { title, icon, link } = linkItem;

    this.setState({
      editIndex: index,
      title: title || null,
      icon: icon || null,
      link: link || null,
    });
  };

  cancelEdit = () => {
    this.setState({ editIndex: null });
    this.resetValues();
  };

  addLink = () => {
    const { title, icon, link } = PLACEHOLDER;
    const newLink = {
      title,
      icon,
      link,
    };

    this.setState(
      (state) => update(state, { links: { $push: [newLink] } }),
      () => this.startEdit(this.state.links.length - 1)
    );
  };

  moveLink = (index, value) => {
    const { links = [] } = this.state;
    this.cancelEdit();

    const linkA = links[index];
    const linkB = links[index + value];

    this.setState(
      {
        links: update(links, {
          [index]: {
            $set: linkB,
          },
          [index + value]: {
            $set: linkA,
          },
        }),
      },
      this.saveLinks
    );
  };

  render() {
    const { editIndex, links = [], loading, error } = this.state;

    if (loading) {
      return (
        <div className="uninow-content-container" style={{ textAlign: 'center' }}>
          <Spin />
        </div>
      );
    }

    if (error) {
      return (
        <div className="uninow-content-container" style={{ textAlign: 'center' }}>
          Deine Linksammlung konnte leider nicht geladen werden.
          <br />
          <br />
          Versuche es doch später noch einmal oder kontaktiere uns über{' '}
          <a href="mailto:support@uninow.de">support@uninow.de</a>.
        </div>
      );
    }

    return (
      <div className="uninow-content-container">
        <div className="headline weight-bold size-large">Linksammlung</div>
        {links.length === 0 ? <Empty description=" " /> : <List dataSource={links} renderItem={this.renderLink} />}
        <div style={{ textAlign: 'center', width: '100%' }}>
          <Button disabled={editIndex !== null} icon="plus" onClick={this.addLink}>
            Link hinzufügen
          </Button>
        </div>
      </div>
    );
  }
}

export default withApollo(LinkCollection);
