import React, { Component } from 'react';
import update from 'immutability-helper';
import { PropTypes } from 'prop-types';
import { Spin, Popconfirm, notification } from 'antd';

import { Query, withApollo } from 'react-apollo';

import { GET_FRESHMAN_MAPS } from 'core/gql/queries';
import { UPDATE_LOCATION } from 'core/gql/mutations';
import { ICONS } from '../../../components/Ionicon';

import GMap from '../../../components/GMap';

class FreshmanMap extends Component {
  state = {
    popconfirmLocation: {
      x: 0,
      y: 0,
      visible: false,
    },
  };

  componentDidMount() {
    if (window.google) {
      this.geocoder = new window.google.maps.Geocoder();
    }
  }

  setRefToMap = (map) => {
    this.map = map;
  };

  setPlacesService = (placesService) => {
    this.placesService = placesService;
  };

  fromLatLngToPoint = (latLng, map) => {
    try {
      const topRight = map.getProjection().fromLatLngToPoint(map.getBounds().getNorthEast());
      const bottomLeft = map.getProjection().fromLatLngToPoint(map.getBounds().getSouthWest());
      const scale = Math.pow(2, map.getZoom());
      const worldPoint = map.getProjection().fromLatLngToPoint(latLng);
      return new window.google.maps.Point((worldPoint.x - bottomLeft.x) * scale, (worldPoint.y - topRight.y) * scale);
    } catch (e) {
      return undefined;
    }
  };

  setPopConfirm = (event) => {
    if (event.placeId && this.map) {
      const coordinates = this.fromLatLngToPoint(event.latLng, this.map);
      if (coordinates) {
        this.setState({
          geoPoint: {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          },
          placeId: event.placeId,
          popconfirmLocation: {
            x: coordinates.x,
            y: coordinates.y - 5,
            visible: true,
          },
        });
      } else {
        notification.error({
          message: 'Während des Vorganges kam es zu einem Fehler.',
          description: 'Bitte versuche es später noch einmal oder kontaktiere uns über den Support Chat',
          duration: 2.5,
        });
      }
    } else {
      this.setState({
        geoPoint: {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        },
        placeId: undefined,
        popconfirmLocation: {
          x: event.pixel.x,
          y: event.pixel.y,
          visible: true,
        },
      });
    }
  };

  onMapClick = (event) => {
    event.stop();
    this.setPopConfirm(event);
  };

  updateMapLocation = (locationId, lat, lng) => {
    this.latLngToLocation(lat, lng, undefined, (result) => {
      if (result) {
        this.props.onLocationDragEnd(locationId, result);
        this.updateLocation(locationId, result);
      }
    });
  };

  updateLocation = async (locationId, geoLocation) => {
    const { client } = this.props;

    if (locationId) {
      const cacheData = client.readQuery({ query: GET_FRESHMAN_MAPS });

      if (locationId.startsWith('tmp_')) {
        const { tmpLocation } = cacheData;

        client.writeData({
          data: {
            tmpLocation: JSON.stringify({
              ...JSON.parse(tmpLocation),
              location: geoLocation,
            }),
          },
        });
      } else {
        const { selectedMap } = cacheData;
        const { maps = [] } = cacheData.me;

        const mapIndex = selectedMap ? maps.findIndex((map) => map.id === selectedMap) : 0;

        const { data } = await client.mutate({
          mutation: UPDATE_LOCATION,
          variables: {
            map: maps[mapIndex].id,
            id: locationId,
            location: geoLocation,
          },
        });

        const locationIndex = maps[mapIndex].locations.findIndex((l) => l.id === locationId);

        client.writeQuery({
          query: GET_FRESHMAN_MAPS,
          data: update(cacheData, {
            me: {
              maps: {
                [mapIndex]: {
                  locations: {
                    [locationIndex]: {
                      $set: data.updateLocation,
                    },
                  },
                },
              },
            },
          }),
        });
      }
    }
  };

  addLocation = async (location, name) => {
    const { client } = this.props;

    const cacheData = client.readQuery({ query: GET_FRESHMAN_MAPS });

    const { selectedMap } = cacheData;
    const { maps = [] } = cacheData.me;

    const mapIndex = selectedMap ? maps.findIndex((map) => map.id === selectedMap) : 0;

    const { data } = await client.mutate({
      mutation: UPDATE_LOCATION,
      variables: {
        location,
        name: name || location.label,
        type: ICONS[0],
        map: maps[mapIndex].id,
      },
    });

    client.writeQuery({
      query: GET_FRESHMAN_MAPS,
      data: update(cacheData, {
        me: {
          maps: {
            [mapIndex]: {
              locations: {
                $push: [data.updateLocation],
              },
            },
          },
        },
      }),
    });
  };

  onLocationConfirm = () => {
    const { geoPoint, placeId } = this.state;
    this.latLngToLocation(geoPoint.lat, geoPoint.lng, placeId, (location, name) => {
      if (location) this.addLocation(location, name);
    });
  };

  getLocationDetails = async (placeId, placesService) => {
    return new Promise(function (resolve, reject) {
      if (!placesService) {
        resolve({});
      }
      placesService.getDetails(
        {
          placeId: placeId,
          fields: ['name', 'rating', 'formatted_phone_number', 'geometry'],
        },
        (place, status) => {
          if (status === 'OK') {
            resolve(place);
          } else {
            resolve({});
          }
        }
      );
    });
  };

  latLngToLocation = async (lat, lng, placeId, callback) => {
    if (this.geocoder) {
      this.geocoder.geocode({ location: { lat: lat, lng: lng } }, async (results, status) => {
        if (status === 'OK') {
          const suggest = results[0];
          const location = {
            geoPoint: { lat, lng },
            country: '',
            city: '',
            postalCode: '',
            street: '',
            label: suggest.formatted_address,
          };
          const tmpLocation = {
            street: '',
            streetNumber: '',
          };

          suggest.address_components.forEach((component) => {
            component.types.forEach((type) => {
              switch (type) {
                case 'street_number':
                  tmpLocation.streetNumber = component.long_name;
                  break;

                case 'route':
                  tmpLocation.street = component.long_name;
                  location.street = component.long_name;
                  break;

                case 'locality':
                  location.city = component.long_name;
                  break;

                case 'administrative_area_level_1':
                  location.state = component.long_name;
                  break;

                case 'country':
                  location.country = component.long_name;
                  break;

                case 'postal_code':
                  location.postalCode = component.long_name;
                  break;

                default:
                  break;
              }
            });
          });

          if (tmpLocation.street && tmpLocation.streetNumber) {
            location.street = `${tmpLocation.street} ${tmpLocation.streetNumber}`;
          }

          if (placeId) {
            callback(location, (await this.getLocationDetails(placeId, this.placesService)).name);
          } else {
            callback(location);
          }
        } else {
          callback(undefined);
        }
      });
    } else {
      callback(undefined);
    }
  };

  render() {
    return (
      <Query query={GET_FRESHMAN_MAPS} returnPartialData={true}>
        {({ loading, error, data }) => {
          if (loading) {
            return (
              <div style={{ textAlign: 'center' }}>
                <Spin />
              </div>
            );
          }

          if (error || !data.me) {
            return null;
          }

          const { selectedMap = null, tmpLocation = null } = data;
          const { maps = [] } = data.me;
          const activeMap = selectedMap ? maps.find((map) => map.id === selectedMap) : maps[0];

          let locations = activeMap ? activeMap.locations : [];

          if (tmpLocation) {
            const tmpLoc = JSON.parse(tmpLocation);

            const locationIndex = locations.findIndex((location) => location.id === tmpLoc.id);

            locations = update(
              locations,
              locationIndex === -1
                ? { $push: [tmpLoc] }
                : {
                    [locationIndex]: {
                      $set: tmpLoc,
                    },
                  }
            );
          }

          return (
            <div
              style={{
                boxShadow: '0 2px 22px 0 rgba(196, 196, 196, 0.5)',
                height: '80%',
              }}
            >
              <GMap
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                onClick={(e) => this.onMapClick(e)}
                setPlacesService={(placesService) => this.setPlacesService(placesService)}
                setRefToMap={(map) => this.setRefToMap(map)}
                locations={locations
                  .filter(({ location }) => location && location.geoPoint)
                  .map((location, index) => ({
                    ...location.location.geoPoint,
                    options: {
                      label: (index + 1).toString(),
                      draggable: true,
                      clickable: true,
                      onDragEnd: (e) => {
                        this.updateMapLocation(location.id, e.latLng.lat(), e.latLng.lng());
                      },
                    },
                  }))}
                mapOptions={{
                  zoomControl: true,
                  draggable: true,
                  clickableIcons: true,
                  gestureHandling: 'cooperative',
                  disableDoubleClickZoom: true,
                  scrollwheel: true,
                  scrollControl: true,
                  maxZoom: 18,
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  zIndex: '2',
                  top: this.state.popconfirmLocation.y ? this.state.popconfirmLocation.y + 5 : 0,
                  left: this.state.popconfirmLocation.x ? this.state.popconfirmLocation.x : 0,
                }}
              >
                <Popconfirm
                  visible={this.state.popconfirmLocation.visible}
                  title="Möchtest du hier einen Standort setzen?"
                  cancelText="Nein"
                  okText="Ja"
                  onConfirm={() => this.onLocationConfirm()}
                  onCancel={() =>
                    this.setState({
                      popconfirmLocation: { visible: false },
                    })
                  }
                  onVisibleChange={() =>
                    this.setState({
                      popconfirmLocation: { visible: false },
                    })
                  }
                />
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

FreshmanMap.propTypes = {
  onLocationDragEnd: PropTypes.func.isRequired,
  onLocationConfirm: PropTypes.func.isRequired,
  setCenterAndZoomFunction: PropTypes.func.isRequired,
};

export default withApollo(FreshmanMap);
