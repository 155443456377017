import React, { Component } from 'react';

import { Spin } from 'antd';

import AccountForm from './AccountForm';
import PasswordForm from './PasswordForm';
import PropTypes from 'prop-types';

class Account extends Component {
  state = {
    loading: true,
  };

  loadData = async () => {
    const data = await this.context.getMe();

    this.setState({
      loading: false,
      data: data.data,
    });
  };

  componentDidMount() {
    this.loadData();
  }

  render() {
    const { loading, data = {} } = this.state;

    if (loading) {
      return (
        <div style={{ textAlign: 'center' }}>
          <Spin />
        </div>
      );
    }

    const { email, firstName: name } = data;

    return (
      <>
        <AccountForm data={{ email, name }} />
        <PasswordForm />
      </>
    );
  }
}

Account.contextTypes = {
  getMe: PropTypes.func.isRequired,
};

export default Account;
