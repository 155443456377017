import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Select, Row, Col, Icon, Tooltip, Input, Switch, Alert, Form } from 'antd';

const { Option } = Select;
const { TextArea } = Input;

const PLACEHOLDER = {
  name: 'Kartenname',
};

class MapSelection extends Component {
  constructor(props) {
    super(props);

    const { maps = [] } = props;

    this.state = {
      lastMapId: null,
      mapId: maps[0] ? maps[0].id : null,
      editMode: false,
      name: null,
      description: null,
    };
  }

  addMap = () => {
    const { mapId } = this.state;

    this.setState({
      lastMapId: mapId,
      editMode: true,
      mapId: null,
      name: PLACEHOLDER.name,
    });
    this.props.onSelect('null');
  };

  onSelect = (value) => {
    this.cancelEdit();

    switch (value) {
      case 'CREATE_MAP':
        this.addMap();
        break;

      default:
        this.setState({ mapId: value });
        this.props.onSelect(value);
        break;
    }
  };

  startEdit = () => {
    const { maps = [] } = this.props;
    const { mapId } = this.state;

    const map = maps.find((map) => map.id === mapId) || {};

    this.setState({
      editMode: true,
      name: map.name || null,
      description: map.description || null,
    });
  };

  cancelEdit = () => {
    const { mapId, lastMapId } = this.state;

    this.setState({
      mapId: mapId || lastMapId,
      editMode: false,
      name: null,
      description: null,
      lastMapId: null,
    });

    this.props.onSelect(lastMapId);
  };

  deleteMap = () => {
    const { mapId } = this.state;

    this.props.onDelete(mapId);
  };

  saveMap = () => {
    const { mapId, name, description } = this.state;

    this.props.onChange({
      id: mapId,
      name: name || PLACEHOLDER.name,
      description: description || null,
    });

    this.cancelEdit();
  };

  updateValue = (key, value) => {
    this.setState({ [key]: value });
  };

  updateVisibility = (value, mapId) => {
    this.props.onVisibilityToggle(value, mapId);
  };

  componentDidUpdate(prevProps) {
    if (this.props.maps.length < prevProps.maps.length) {
      this.setState({
        mapId: this.props.maps[0] ? this.props.maps[0].id : null,
      });
    } else if (this.props.maps.length > prevProps.maps.length) {
      this.setState({
        mapId: this.props.maps[this.props.maps.length - 1].id,
      });
    }
  }

  render() {
    const { maps = [] } = this.props;
    const { mapId, editMode, name, description } = this.state;

    const map = maps.find((map) => map.id === mapId) || {};

    const validName = !!(name || '').trim();

    const actions = editMode
      ? [
          {
            tooltip: 'Speichern',
            type: 'save',
            onClick: () => (validName ? this.saveMap() : null),
            className: validName ? 'color-primary' : 'color-secondary',
          },
          {
            tooltip: 'Abbrechen',
            type: 'close',
            onClick: () => this.cancelEdit(),
          },
        ]
      : [
          {
            tooltip: 'Bearbeiten',
            type: 'edit',
            onClick: () => this.startEdit(),
          },
          {
            tooltip: 'Löschen',
            type: 'delete',
            onClick: () => this.deleteMap(),
          },
        ];

    return (
      <>
        <Row className="headline" type="flex" align="middle">
          <Col span={12}>
            <div className="weight-bold size-large">Karten</div>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Tooltip title="Karte hinzufügen">
              <Icon
                style={{ padding: '0px 6px', cursor: 'pointer' }}
                className="color-primary"
                type="plus"
                onClick={this.addMap}
              />
            </Tooltip>
          </Col>
        </Row>
        <Row type="flex" justify="space-between" gutter={25}>
          <Col xs={24} sm={3} md={2} lg={4} xl={2}>
            <Tooltip title={`Karte jetzt ${map.visible ? 'un' : ''}sichtbar schalten.`}>
              <Switch
                disabled={!mapId || editMode}
                checked={map.visible}
                onChange={(value) => this.updateVisibility(value, map.id)}
              />
            </Tooltip>
          </Col>
          <Col xs={18} sm={16} md={16} lg={15} xl={16}>
            {editMode ? (
              <div>
                <Form.Item
                  validateStatus={validName ? null : 'error'}
                  help={validName ? null : 'Bitte gib einen Kartennamen ein'}
                >
                  <Input value={name} onChange={({ target }) => this.updateValue('name', target.value)} />
                </Form.Item>
                <TextArea
                  value={description}
                  rows={3}
                  onChange={({ target }) => this.updateValue('description', target.value)}
                />
                {!mapId && (
                  <Alert
                    style={{ marginTop: 10 }}
                    message="Du musst deine Karte speichern, bevor du Standorte hinzufügen kannst."
                    type="info"
                    showIcon
                  />
                )}
              </div>
            ) : (
              <div>
                <Select
                  value={mapId || undefined}
                  placeholder="Keine Karte ausgewählt"
                  style={{ width: '100%' }}
                  onSelect={this.onSelect}
                >
                  {maps.map((map) => (
                    <Option key={map.id} value={map.id}>
                      {map.name}
                    </Option>
                  ))}
                  <Option value="CREATE_MAP">+ Karte erstellen</Option>
                </Select>
                <div className="color-secondary" style={{ padding: 5 }}>
                  {map.description || 'Keine Beschreibung'}
                </div>
              </div>
            )}
          </Col>
          <Col xs={6} sm={5} md={6} lg={5} xl={6} style={{ textAlign: 'right' }}>
            {(mapId || editMode) && (
              <Fragment>
                <Tooltip title={actions[0].tooltip}>
                  <Icon style={{ padding: '0px 6px' }} className="color-primary" {...actions[0]} />
                </Tooltip>
                <span style={{ color: '#e8e8e8' }}>|</span>
                <Tooltip title={actions[1].tooltip}>
                  <Icon style={{ padding: '0px 6px' }} className="color-primary" {...actions[1]} />
                </Tooltip>{' '}
              </Fragment>
            )}
          </Col>
        </Row>
      </>
    );
  }
}

MapSelection.propTypes = {
  selected: PropTypes.string,
  maps: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onVisibilityToggle: PropTypes.func.isRequired,
  onMapEditToggle: PropTypes.func,
};

export default MapSelection;
