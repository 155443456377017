import React, { Component } from 'react';
import { decode } from 'jsonwebtoken';
import { PropTypes } from 'prop-types';
import { withApollo } from 'react-apollo';

import { Row, Col, Spin } from 'antd';

const { REACT_APP_WAPI_HOST } = window;

const translateToken = async (token) => {
  const res = await fetch(`${REACT_APP_WAPI_HOST}/translate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token, application: 'com.uninow.feed' }),
  });

  const body = await res.json();

  return body.token;
};

class Register extends Component {
  componentDidMount() {
    const { search } = this.props.location;

    const [, token] = /token=([^&]+)/gim.exec(search);

    this.queryMe(token);
  }

  queryMe = async (token) => {
    const decodedToken = decode(token);

    const isDeprecatedToken = !!decodedToken.account && !decodedToken.acr;

    if (isDeprecatedToken) {
      token = await translateToken(token);
    }

    const res = await this.context.loginWithToken(token);

    if (res) {
      this.props.history.push('/');
    }
  };

  render() {
    return (
      <Row>
        <Col span={24} style={{ textAlign: 'center', paddingTop: 100 }}>
          <Spin />
          <br />
          Warte nur kurz. Du wirst sofort weitergeleitet.
        </Col>
      </Row>
    );
  }
}

Register.contextTypes = {
  loginWithToken: PropTypes.func.isRequired,
};

export default withApollo(Register);
