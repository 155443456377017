import update from 'immutability-helper';

import {
  CHANGE_AUTH_DATA,
  CHANGE_FEED_VERSION,
  CHANGE_PASSWORD_SET,
  IGNORE_SETUP_STEP,
  SET_SELECTED_PROFILE,
  SET_HAS_SEEN_DISCLAIMER,
} from '../actions/user.js';

function user(
  state = {
    token: null,
    role: 'null',
    accountRole: 'null',
    feedVersion: null,
    userData: null,
    ignoredSetupSteps: [],
  },
  action
) {
  switch (action.type) {
    case CHANGE_AUTH_DATA: {
      return update(state, {
        token: {
          $set: action.token,
        },
        role: {
          $set: action.role,
        },
        accountRole: {
          $set: action.accountRole,
        },
        accountId: {
          $set: action.accountId,
        },
        userData: {
          $set: action.userData,
        },
      });
    }

    case SET_SELECTED_PROFILE: {
      return update(state, { selectedProfile: { $set: action.profile } });
    }
    case CHANGE_FEED_VERSION: {
      return update(state, {
        feedVersion: {
          $set: action.feedVersion,
        },
      });
    }

    case CHANGE_PASSWORD_SET: {
      return update(state, {
        userData: {
          passwordSet: {
            $set: action.isSet,
          },
        },
      });
    }
    case IGNORE_SETUP_STEP: {
      return update(state, {
        ignoredSetupSteps: {
          $push: action.step,
        },
      });
    }
    case SET_HAS_SEEN_DISCLAIMER: {
      return update(state, {
        hasSeenDisclaimer: {
          $set: action.hasSeenDisclaimer,
        },
      });
    }

    default:
      return state;
  }
}

export default user;
