import moment from 'moment';
import 'moment/locale/de';

export function buildTimeString(start, end) {
  if (!start) {
    return null;
  }

  const startDate = moment.utc(start);
  const endDate = end ? moment.utc(end) : null;

  if (!endDate) {
    return `${startDate.format('dd, D. MMMM YYYY, HH:mm')}`;
  } else {
    const sameDay = startDate.isSame(endDate, 'day');

    return sameDay
      ? `${startDate.format('dd, D. MMMM, HH:mm')} - ${endDate.format('HH:mm')}`
      : `${startDate.format('D. MMM, HH:mm')} - ${endDate.format('D. MMM, HH:mm')}`;
  }
}
