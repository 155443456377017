import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Mutation } from 'react-apollo';
import { Button, Form, Input, Modal, notification } from 'antd';

import { privacyPolicyVersion, userAgreementVersion } from '../../core/constants';

import gradient from '../../images/gradient.svg';
import logo from '../../images/uninow_logo_vertical_white.svg';

import { changePasswordSet } from '../../core/redux/actions/user';
import { REGISTER } from 'core/gql/mutations';

import './style.css';

const PASSWORD_RULES = [
  {
    required: true,
    message: 'Bitte gib ein Passwort ein',
  },
  {
    min: 8,
    message: 'Bitte gib mindestens 8 Zeichen ein',
  },
  {
    whitespace: true,
    message: 'Nicht nur Leerzeichen',
  },
  {
    pattern: /\d/,
    message: 'Bitte verwende mindestens eine Zahl',
  },
  {
    pattern: /[a-z]+.*[A-Z]+|[A-Z]+.*[a-z]+/,
    message: 'Bitte verwende Groß- und Kleinschreibung',
  },
];

class RegistrationModal extends Component {
  state = {
    disabledButton: true,
  };

  updateButton(data = {}) {
    const { getFieldsValue } = this.props.form;

    const values = getFieldsValue(['password1', 'password2']);

    const pw1 = data.password1 === undefined ? values.password1 : data.password1;
    const pw2 = data.password2 === undefined ? values.password2 : data.password2;

    const disabledButton = !pw1 || !pw2 || pw1 !== pw2;

    this.setState({ disabledButton });
  }

  onSubmit(callback) {
    const { validateFields } = this.props.form;

    validateFields((error, values) => {
      if (error) {
        return;
      }
      const variables = {
        privacyPolicyVersion: privacyPolicyVersion,
        userAgreementVersion: userAgreementVersion,
        password: values.password1,
      };

      callback({ variables });
    });
  }

  onError = () => {
    notification.error({
      message: 'Während der Registrierung kam es zu einem Fehler',
      description: 'Bitte kontaktieren sie support@uninow.de.',
    });
  };

  onCompleted = () => {
    notification.success({
      message: 'Du hast dich erfolgreich registriert',
      description: 'Verfasse gleich deinen ersten Post!',
    });
    this.props.changePasswordSet(true);
  };

  render() {
    const { visible } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { disabledButton } = this.state;

    return (
      <Modal
        wrapClassName="RegistrationModal"
        title={null}
        visible={visible}
        onOk={this.handleOk}
        closable={false}
        footer={null}
        width={400}
        bodyStyle={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
        style={{ borderRadius: '10px' }}
      >
        <Form style={{ textAlign: 'center' }}>
          <div
            style={{
              marginTop: 10,
              marginBottom: 10,
              backgroundImage: `url(${gradient})`,
              backgroundPosition: '50% 50%',
              backgroundSize: 'cover',
              height: 150,
              width: '100%',
              color: 'white',
              borderRadius: '10px 10px 0px 0px',
            }}
          >
            <div
              style={{
                paddingTop: 10,
                fontWeight: 600,
                fontSize: '1.3rem',
              }}
            >
              Willkommen im Feed von
            </div>
            <br />
            <img src={logo} alt="" style={{ height: 60 }} />
          </div>
          <div style={{ paddingLeft: 25, paddingRight: 25 }}>
            Begleitet eure Studierenden durch das Studium und erleichtert ihnen den Studienalltag.
            <br />
            <br />
            Jetzt nur noch Passwort festlegen.
            <br />
            <br />
            <div>
              <Form.Item>
                {getFieldDecorator('password1', {
                  validateTrigger: 'onBlur',
                  validateFirst: true,
                  rules: PASSWORD_RULES,
                })(
                  <Input
                    placeholder="Passwort"
                    type="password"
                    onChange={(e) => {
                      this.updateButton({ password1: e.target.value });
                    }}
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('password2', {
                  validateTrigger: 'onBlur',
                  validateFirst: true,
                  rules: PASSWORD_RULES,
                })(
                  <Input
                    placeholder="Passwort wiederholen"
                    type="password"
                    onChange={(e) => {
                      this.updateButton({ password2: e.target.value });
                    }}
                  />
                )}
              </Form.Item>
            </div>
            <div style={{ fontSize: '0.8rem' }}>
              Mit der Registrierung für den Feed akzeptierst du unsere{' '}
              <a target="_blank" rel="noopener norefferer" href="https://legal.uninow.com/feed/terms-of-use-uninow-feed/de/latest">
                Nutzervereinbarung
              </a>{' '}
              und{' '}
              <a target="_blank" rel="noopener norefferer" href='https://legal.uninow.com/feed/privacy-policy-uninow-feed/de/latest'>
                Datenschutzerklärung
              </a>{' '}
              .
            </div>
            <Mutation mutation={REGISTER} onError={this.onError} onCompleted={this.onCompleted}>
              {(callback, { error }) => (
                <Button
                  disabled={disabledButton}
                  type="primary"
                  htmlType="submit"
                  style={{
                    fontWeight: 700,
                    padding: '0px 30px',
                    borderWidth: 0,
                    borderRadius: 100,
                    marginTop: 20,
                  }}
                  onClick={() => this.onSubmit(callback)}
                >
                  Los geht's!
                </Button>
              )}
            </Mutation>
          </div>
        </Form>
      </Modal>
    );
  }
}

RegistrationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
};

RegistrationModal.contextTypes = {
  login: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  changePasswordSet: (isSet) => dispatch(changePasswordSet(isSet)),
});

export default connect(null, mapDispatchToProps)(Form.create()(RegistrationModal));
