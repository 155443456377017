import React, { useState } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { Row, Col, Menu, Avatar, Switch, notification, Icon } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { PROFILE } from 'core/gql/queries';
import { SET_PROFILE_VISIBILITY } from 'core/gql/mutations';
import { freshmanGlobalChannelId } from '../../core/constants';

import CreateUser from '../CreateUser';
import useBreakpoint from '../../hooks/useBreakpoint';

import gradient from '../../images/gradient.svg';
import logo from '../../images/uninow_logo_white.svg';

import defaultAvatar from '../../images/avatar_placeholder.png';

import './style.css';

export const NAVBAR_HEIGHT = 50;

const Bar = ({ menu = null }) => (
  <div style={{ height: NAVBAR_HEIGHT }}>
    <Row
      type="flex"
      align="middle"
      style={{
        backgroundImage: `url(${gradient})`,
        backgroundPosition: '50% 50%',
        backgroundSize: 'cover',
        height: NAVBAR_HEIGHT,
        position: 'fixed',
        width: '100%',
        zIndex: 20,
      }}
    >
      <Col
        span={menu ? 4 : 24}
        style={{
          color: 'white',
          textAlign: menu ? 'left' : 'center',
        }}
      >
        <Link
          style={{
            color: 'white',
            fontWeight: 'bold',
          }}
          to={'/'}
        >
          <img src={logo} alt="" style={{ height: 30, paddingLeft: 30 }} />
        </Link>
      </Col>
      {menu && (
        <Col
          span={20}
          style={{
            textAlign: 'right',
          }}
        >
          {menu}
        </Col>
      )}
    </Row>
  </div>
);

const Navbar = ({ history, location }, context) => {
  const { accountRole, isAuthenticated, logout } = context;
  const { breakpoint } = useBreakpoint();
  const [createNewUser, setCreateNewUser] = useState(false);

  const { data, error, loading } = useQuery(PROFILE);
  const [setVisibility] = useMutation(SET_PROFILE_VISIBILITY, {
    ignoreResults: true,
    update: (cache, { data, error: mutationError }) => {
      const status = data.setVisibility;
      const { me } = cache.readQuery({ query: PROFILE });

      if (mutationError) {
        notification.error({
          message: `${status ? 'Aktivierung' : 'Deaktivierung'} fehlgeschlagen`,
          description: 'Versuche es doch später noch einmal oder kontaktiere uns über unseren Support-Chat.',
        });
        return;
      } else if (status) {
        notification.success({
          message: 'Profil online',
          description: 'Euer Profil sowie eure Beiträge sind nun in der UniNow-App sichtbar.',
        });
      } else {
        notification.warning({
          message: 'Profil offline',
          description: 'Euer Profil sowie eure Beiträge werden nun nicht mehr in der UniNow-App angezeigt.',
        });
      }

      cache.writeQuery({
        query: PROFILE,
        data: {
          me: update(me, {
            visible: {
              $set: status,
            },
          }),
        },
      });
    },
  });

  if (!isAuthenticated || loading || error || !data.me) {
    return <Bar />;
  }

  const { avatar, username = '', visible = false, notificationChannels = [] } = data.me;

  const mobileView = ['xs'].includes(breakpoint);

  const { pathname } = location;

  const hasFreshmanAccess = notificationChannels.some(({ globalChannel }) => globalChannel === freshmanGlobalChannelId);

  const activeKeys = [];
  if (pathname === '/') {
    activeKeys.push('feed');
  } else if (pathname.includes('freshman')) {
    activeKeys.push('freshman');
  }

  const openMenu = history.action === 'PUSH' && location.state && location.state.openMenu;

  let defaultOpenKeys = [];
  if (openMenu) {
    defaultOpenKeys.push('more');
  }

  const navigationMenu = (
    <Menu
      key={defaultOpenKeys[0] ? Math.random().toString() : null}
      mode="horizontal"
      style={{
        backgroundColor: 'transparent',
        border: 0,
        height: NAVBAR_HEIGHT,
      }}
      selectedKeys={activeKeys}
      defaultOpenKeys={defaultOpenKeys}
    >
      {hasFreshmanAccess && !mobileView && (
        <Menu.Item
          key="feed"
          className="menu-item-white"
          onClick={() => {
            history.push('/');
          }}
        >
          <span>Startseite</span>
        </Menu.Item>
      )}
      {hasFreshmanAccess && !mobileView && (
        <Menu.Item
          key="freshman"
          className="menu-item-white"
          onClick={() => {
            history.push('/freshman/posts');
          }}
        >
          <span>Erstsemesterbereich</span>
        </Menu.Item>
      )}
      <Menu.SubMenu key="more" className="menu-item-white" title={<Icon type="menu" style={{ color: '#FFFFFF' }} />}>
        <div style={{ padding: '10px 16px' }}>
          <Avatar size="default" src={avatar ? avatar.large : defaultAvatar} />
          <div
            className="hide-xs size-small"
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              display: 'inline-block',
              fontWeight: 400,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {username}
          </div>
        </div>
        {['SUPERADMIN', 'ADMIN'].includes(accountRole) && (
          <Row type="flex" justify="space-between" align="middle" style={{ padding: '10px 16px' }}>
            <Col span={12}>Online: </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Switch checked={visible} onClick={() => setVisibility({ variables: { visible: !visible } })} />
            </Col>
          </Row>
        )}
        <Menu.Divider />
        {mobileView && (
          <Menu.Item
            key="feed"
            onClick={() => {
              history.push('/');
            }}
          >
            Startseite
          </Menu.Item>
        )}
        {hasFreshmanAccess && mobileView && (
          <Menu.Item
            key="freshman"
            onClick={() => {
              history.push('/freshman/posts');
            }}
          >
            Erstsemesterbereich
          </Menu.Item>
        )}
        <Menu.Item
          key="settings"
          onClick={() => {
            if (accountRole === 'DRAFT') {
              history.push('/settings/account');
            } else {
              history.push('/settings/profile');
            }
          }}
        >
          Einstellungen
        </Menu.Item>
        {accountRole === 'SUPERADMIN' && (
          <Menu.Item
            key="newuser"
            onClick={() => {
              setCreateNewUser(true);
            }}
          >
            Neuen Nutzer anlegen
          </Menu.Item>
        )}
        {accountRole === 'SUPERADMIN' && (
          <Menu.Item
            key="admin"
            onClick={() => {
              history.push('/admin/profiles');
            }}
          >
            Interner Bereich
          </Menu.Item>
        )}
        <Menu.Item key="logout" onClick={logout}>
          Logout
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );

  return (
    <div id="NavBar">
      {createNewUser && <CreateUser visible={createNewUser} hide={() => setCreateNewUser(false)} />}
      <Bar
        menu={
          <Row
            style={{
              cursor: 'pointer',
              display: 'inline-block',
              paddingRight: 10,
            }}
          >
            {navigationMenu}
          </Row>
        }
      />
    </div>
  );
};

Navbar.contextTypes = {
  logout: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  role: PropTypes.string,
  accountRole: PropTypes.string,
};

export default withRouter(Navbar);
