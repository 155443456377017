import React, { Component } from 'react';

import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { Query } from 'react-apollo';
import { PROFILE } from 'core/gql/queries';
import formatText from '../../utils/markdown';
import translate from '../../utils/translate';
import { buildTimeString } from '../../utils/time';

import { Row, Col, Icon, Badge } from 'antd';
import GMap from '../GMap/index';
import FollowButton from '../FollowButton';
import Ionicon from '../Ionicon';

import placeholder from './placeholder.jpg';
import defaultAvatar from '../../images/avatar_placeholder.png';

import { AudioPlayer } from '../';

import './style.css';

import moment from 'moment';

const Phone = (props) => (
  <div
    id="Preview"
    style={{
      height: 721 * props.scale,
      width: 346 * props.scale,
      paddingTop: 87 * props.scale,
    }}
  >
    <div
      style={{
        background: props.backgroundColor ? props.backgroundColor : '#fff',
        height: 510 * props.scale,
        width: 284 * props.scale,
        marginLeft: 31 * props.scale,
        paddingTop: 31 * props.scale,
        overflowY: 'auto',
      }}
    >
      <Row style={{ backgroundColor: '#fff', width: '100%' }}>
        <Col span={5}>
          <img
            src={props.avatar || defaultAvatar}
            alt=""
            className="avatar"
            style={{ height: 50 * props.scale, width: 50 * props.scale }}
          />
        </Col>
        <Col
          span={19}
          style={{
            lineHeight: 2.2 * props.scale,
          }}
        >
          <div
            className="weight-bold"
            style={{
              fontSize: 14 * props.scale,
            }}
          >
            {props.displayName || ''}
            <br />
          </div>
          <div
            className="weight-regular color-secondary"
            style={{
              fontSize: 10 * props.scale,
            }}
          >
            {props.username ? `@${props.username}` : ''}
          </div>
          <div
            className="weight-regular color-secondary"
            style={{
              fontSize: 11 * props.scale,
            }}
          >
            {translate('PHONE_TIME', props.language)}
          </div>
        </Col>
        <Col span={24}>
          <Row
            style={{
              paddingTop: 15 * props.scale,
              paddingBottom: 15 * props.scale,
              fontSize: 14 * props.scale,
              whiteSpace: 'pre-line',
            }}
          >
            <Col span={24} style={{ paddingBottom: '1.0em' }}>
              {props.content ? (
                <Row>
                  <Col
                    span={props.action ? 14 : 24}
                    style={{
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                    }}
                  >
                    {formatText(props.content)}
                  </Col>
                  {props.action && <Col span={10}>{props.action}</Col>}
                </Row>
              ) : (
                <div className="color-secondary">{translate('NEW_POST', props.language)}</div>
              )}
            </Col>
            <Col span={24}>{props.extra}</Col>
            <Col span={24} style={{ textAlign: 'left', paddingTop: 10 }} className="color-secondary">
              <Icon type="heart-o" />
            </Col>
            <Col span={24}>
              <img src={placeholder} alt="placeholder" style={{ marginTop: 20, width: '100%' }} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  </div>
);

class Preview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: null,
      windowWidth: null,
      primaryLanguage: 'de',
    };
  }

  updateDimensions() {
    this.setState({
      windowWidth: window.outerWidth,
      windowHeight: window.outerHeight,
    });
  }
  UNSAFE_componentWillMount() {
    this.updateDimensions();
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }
  renderAttachment(SCALE) {
    if (!this.props.post || !this.props.post.type) return null;

    const { type, attachment } = this.props.post;

    const translationImage =
      attachment?.translations?.image?.[this.props.language || 'de'] ||
      attachment?.translations?.image?.[this.state.primaryLanguage || 'de'];
    const image = translationImage ? translationImage.large || translationImage.preview : null;
    const title =
      attachment?.translations?.title?.[this.props.language || 'de'] ||
      attachment?.translations?.title?.[this.state.primaryLanguage];
    const url =
      attachment?.translations?.url?.[this.props.language || 'de'] ||
      attachment?.translations?.url?.[this.state.primaryLanguage];
    const subtitle =
      attachment?.translations?.subtitle?.[this.props.language || 'de'] ||
      attachment?.translations?.subtitle?.[this.state.primaryLanguage];
    const description =
      attachment?.translations?.description?.[this.props.language || 'de'] ||
      attachment?.translations?.description?.[this.state.primaryLanguage];
    const translationAudio =
      attachment?.translations?.audio?.[this.props.language || 'de'] ||
      attachment?.translations?.audio?.[this.state.primaryLanguage || 'de'];
    const audio = translationAudio ? translationAudio.preview || translationAudio.raw : null;
    const images = (
      !isEmpty(attachment?.translations?.images?.[this.props.language || 'de'])
        ? attachment?.translations?.images?.[this.props.language || 'de']
        : !isEmpty(attachment?.translations?.images?.[this.state.primaryLanguage || 'de'])
        ? attachment?.translations?.images?.[this.state.primaryLanguage || 'de']
        : []
    ).filter((image) => image.type === 'image');
    const translationVideo =
      attachment?.translations?.video?.[this.props.language || 'de'] ||
      attachment?.translations?.video?.[this.state.primaryLanguage || 'de'];
    const video = translationVideo ? translationVideo.preview || translationVideo.raw : null;
    switch (type) {
      case 'recruiting':
        const { company, jobs = [], image: recruitingImage, badgeTitle } = attachment;

        if (!company) {
          return null;
        }

        if (jobs.length === 0) {
          const { header = {} } = company;
          const img = recruitingImage ? recruitingImage.preview : (header || {}).large;

          return (
            <div>
              <div style={{ backgroundColor: '#eef0f2' }}>
                {img && (
                  <div style={{ position: 'relative', width: '100%' }}>
                    <img style={{ width: '100%' }} src={img} alt="" />
                    {badgeTitle && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '5%',
                          left: 0,
                          color: '#ffffff',
                          backgroundColor: '#f21850',
                          fontWeight: 600,
                          padding: '5px 15px',
                        }}
                      >
                        {badgeTitle}
                      </div>
                    )}
                  </div>
                )}
                <Row type="flex" justify="space-between" style={{ padding: '10px 20px' }}>
                  <Col>
                    <span className="weight-bold color-secondary">{translate('LEARN_MORE', this.props.language)}</span>
                  </Col>
                  <Col>
                    <Icon type="right" className="color-secondary" />
                  </Col>
                </Row>
              </div>
            </div>
          );
        } else {
          return jobs.slice(0, 2).map(({ id, title, type, company, address }) => (
            <Row
              key={id}
              type="flex"
              align="middle"
              style={{
                padding: '10px 0px',
                borderTop: '1px solid #eef0f2',
                borderBottom: '1px solid #eef0f2',
              }}
            >
              <Col span={3}>
                <Ionicon fontStyle={{ fontSize: '1.8em', color: '#f21850' }} type="ios-briefcase" />
              </Col>
              <Col span={19}>
                <div className="color-secondary">{type}</div>
                <div className="weight-bold">{title}</div>
                <div className="color-secondary">{company.title}</div>
                <div className="color-secondary">
                  <Ionicon
                    style={{ display: 'inline-block', paddingRight: '1.0em' }}
                    fontStyle={{ fontSize: '1.2em' }}
                    type="ios-pin-outline"
                  />
                  {address.city}
                </div>
              </Col>
              <Col span={2}>
                <Ionicon fontStyle={{ fontSize: '1.6em', color: '#f21850' }} type="ios-bookmark-outline" />
              </Col>
            </Row>
          ));
        }

      case 'blog':
        return (
          <div className="blog">
            <a target="_blank" href={url} rel="noopener noreferrer">
              {image && <img src={image} alt="" />}
              <div style={{ padding: '1rem' }}>
                <div className="weight-bold color-secondary">{title ? title.toUpperCase() : null}</div>
                <div
                  className="weight-bold"
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  {subtitle}
                </div>
                <div
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  {description}
                </div>
              </div>
            </a>
          </div>
        );
      case 'podcast':
        return (
          <div className="podcast">
            {image && <img src={image} alt="" />}
            <div style={{ padding: '1rem' }}>
              <div
                className="weight-bold"
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                {title}
              </div>
              <div
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                {description}
              </div>
              <AudioPlayer source={audio} />
            </div>
          </div>
        );
      case 'gallery':
        if (images.length === 0) return null;
        return (
          <Row>
            <div className="gallery">
              <Col span={24}>
                <img src={images[0].preview} alt="" className="gallery" />
              </Col>
              <Col span={24} style={{ textAlign: 'center', fontSize: 10 * SCALE }}>
                {images.length > 1 &&
                  images.map((image, index) => <Badge key={image.name} status={index === 0 ? 'error' : 'default'} />)}
              </Col>
            </div>
          </Row>
        );
      case 'event':
        const { addition, label, geoPoint } = attachment?.location || {};
        let locationString =
          label ||
          ['city', 'street']
            .map((prop) => (attachment?.location || {})[prop])
            .concat([attachment?.addition])
            .filter((prop) => prop)
            .join(', ');

        if (!locationString) {
          locationString = 'Kein Ort angegeben';
        }

        const timeString = buildTimeString(attachment?.start, attachment?.end);

        return (
          <div className="event">
            {geoPoint && (
              <GMap
                containerElement={<div style={{ height: 200 * SCALE }} />}
                mapElement={<div style={{ height: `100%` }} />}
                locations={geoPoint}
                defaultZoom={14}
              />
            )}
            <div style={{ padding: '10px 10px' }}>
              <div
                className="weight-bold"
                style={{
                  lineHeight: 3 * SCALE,
                }}
              >
                {title}
              </div>
              <div className="color-secondary">
                <Row type="flex" align="middle" gutter={10}>
                  <Col span={20}>
                    <Row>
                      <Col span={3}>
                        &nbsp;
                        <Icon type="clock-circle-o" style={{ color: '#F21852' }} />
                      </Col>
                      <Col span={21}>{timeString}</Col>
                    </Row>
                    <Row>
                      <Col span={3}>
                        &nbsp;
                        <Icon type="environment-o" style={{ color: '#F21852' }} />
                      </Col>
                      <Col span={21}>
                        <div className="overflow-ellipsis">{locationString}</div>
                        {addition && (
                          <div>
                            {translate('PLACE', this.props.language)}
                            {addition}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={4} style={{ textAlign: 'right' }}>
                    <Icon
                      type="calendar"
                      style={{
                        color: '#F21852',
                        fontSize: 18 * SCALE,
                        paddingRight: 5,
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        );
      case 'poll':
        return (
          <Row>
            {(attachment?.translations?.options?.length > 1 ? attachment?.translations?.options : [null, null]).map(
              (option, index) => (
                <Col span={24} className="poll" key={index}>
                  {option?.[this.props.language || 'de']
                    ? option[this.props.language || 'de']
                    : option?.[this.state.primaryLanguage]
                    ? option[this.state.primaryLanguage]
                    : 'Option ' + (index + 1)}
                  <Icon
                    type="right"
                    theme="outlined"
                    className="color-secondary"
                    style={{ float: 'right', padding: 2 * SCALE }}
                  />
                </Col>
              )
            )}
            <Col span={24} style={{ marginTop: 10 }}>
              <span className="color-secondary" style={{ fontSize: 12 * SCALE, display: 'block' }}>
                {translate('POLL_I', this.props.language)}
                <br />
                {translate('POLL_II', this.props.language)}
                {attachment?.end
                  ? moment.duration(moment(attachment?.end).diff(moment())).humanize(true)
                  : translate('POLL_III', this.props.language)}
              </span>
            </Col>
          </Row>
        );

      case 'video':
        const thumbnail = image;

        return video ? (
          <div style={{ paddingTop: '0.5rem' }}>
            <video
              preload="metadata"
              poster={thumbnail}
              src={video}
              autoPlay={false}
              muted={true}
              controls={true}
              style={{
                width: '100%',
              }}
            />
          </div>
        ) : null;
      default:
        return null;
    }
  }

  render() {
    if (!this.props.focus) return null;
    let maxWidth = this.state.windowWidth * (6 / 24);
    const SCALE = Math.min(maxWidth / 346, this.state.windowHeight / (721 + 450));
    return (
      <Query query={PROFILE}>
        {({ loading, error, data }) => {
          if (loading || error || !data.me) {
            return <Phone scale={SCALE} />;
          }

          const { avatar, username = '', displayName = '' } = data.me;
          const avatarImg = this.state.avatar || (avatar ? avatar.large : null);
          const { post } = this.props;

          let content = null;
          let attachmentType = null;
          if (post) {
            content =
              post.translations?.content[this.props.language] ||
              post.translations?.content[this.state.primaryLanguage] ||
              post.content ||
              null;
            attachmentType = post.attachment && post.attachment[0] ? post.attachment[0].type : null;
          }

          return (
            <Phone
              scale={SCALE}
              avatar={avatarImg}
              content={content}
              action={attachmentType === 'CompanyPromotion' ? <FollowButton /> : null}
              username={username}
              displayName={displayName}
              extra={this.renderAttachment(SCALE)}
              language={this.props.language}
            />
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = ({ preview }) => ({
  focus: preview.focus,
  post: preview.post,
  language: preview.language,
});

export default connect(mapStateToProps)(Preview);
