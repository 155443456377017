import React, { Component } from 'react';

import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

import { Icon } from 'antd';

import { isEqualWith } from 'lodash';

import './style.css';

class GMap extends Component {
  adjusted = false;

  constructor(props) {
    super(props);
    this.setCenterAndZoom = this.setCenterAndZoom.bind(this);
  }

  componentDidUpdate({ locations: oldLocations }) {
    const { locations = [] } = this.props;

    if (!isEqualWith(oldLocations, locations, (value) => (typeof value === 'function' ? true : undefined))) {
      this.setCenterAndZoom();
    }
  }

  componentDidMount() {
    if (!this.adjusted) {
      this.setCenterAndZoom();
      this.adjusted = true;
    }
    if (this.props.setCenterAndZoomFunction) {
      this.props.setCenterAndZoomFunction(this.setCenterAndZoom);
    }
    if (this.props.setRefToMap) {
      this.props.setRefToMap(this.refs.map);
    }
    if (this.props.setPlacesService && this.refs.map) {
      const map = new window.google.maps.places.PlacesService(
        this.refs.map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED
      );
      this.props.setPlacesService(map);
    }
  }

  setCenterAndZoom() {
    const { locations } = this.props;
    if (locations && Array.isArray(locations) && locations.length > 0 && this.refs.map && this.refs.map.fitBounds) {
      const bounds = new window.google.maps.LatLngBounds();
      this.props.locations.forEach((bound) => bounds.extend(new window.google.maps.LatLng(bound.lat, bound.lng)));
      this.refs.map.fitBounds(bounds);
    }
  }

  render() {
    const { locations, center, defaultZoom, mapOptions, onClick, onDblClick } = this.props;

    const centerLocation = center
      ? center
      : !Array.isArray(locations)
      ? locations
      : this.adjusted
      ? undefined
      : locations[0];

    const absoluteStyle = {
      textAlign: 'center',
      position: 'absolute',
      margin: 'auto',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      height: '100%',
      width: '100%',
      fontSize: '2rem',
      color: 'rgb(162, 162, 162)',
    };
    if (!locations || (Array.isArray(locations) && locations.length === 0)) {
      return (
        <div style={absoluteStyle}>
          <div
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
            }}
          >
            <div style={{ ...absoluteStyle, height: '40%' }}>
              <Icon type="environment-o" />
              <div style={{ fontSize: '1rem' }}>Kein Standort ausgewählt</div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <GoogleMap
        {...(centerLocation ? { center: centerLocation } : {})}
        defaultZoom={defaultZoom ? defaultZoom : 15}
        ref="map"
        options={{
          scrollwheel: false,
          zoomControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          draggable: false,
          clickable: false,
          disableDefaultUI: true,
          disableDoubleClickZoom: true,
          maxZoom: 15,
          scrollControl: false,
          ...mapOptions,
        }}
        onClick={(e) => onClick && onClick(e)}
        onDblClick={(e) => onDblClick && onDblClick(e)}
      >
        {Array.isArray(locations) ? (
          <div>
            {locations.map((location, index) => (
              <Marker
                cursor="pointer"
                position={{ lat: location.lat, lng: location.lng }}
                key={index}
                {...location.options}
              />
            ))}
          </div>
        ) : (
          <Marker cursor="pointer" position={{ lat: locations.lat, lng: locations.lng }} {...locations.options} />
        )}
      </GoogleMap>
    );
  }
}

export default withGoogleMap(GMap);
