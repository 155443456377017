import { Component } from 'react';
import PropTypes from 'prop-types';

import { notification } from 'antd';

class InfoBox extends Component {
  UNSAFE_componentWillMount() {
    const { message, description } = this.props;

    notification.info({
      duration: null,
      message: message,
      description: description,
    });
  }

  render() {
    return null;
  }
}

InfoBox.propTypes = {
  message: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default InfoBox;
