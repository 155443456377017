export function setupLogging() {
  if (window.REACT_APP_ENV === 'development') {
    console.debug = function () {
      console.log(...arguments);
    };
  } else {
    console.debug = function () {};
    //['log', 'debug', 'warn', 'info'].forEach((method) => (console[method] = function () {}));
  }
}
