import React, { Component } from 'react';

import { connect } from 'react-redux';
import PostCreator from '../../../components/PostCreator';
import PostList from '../../../components/PostList';

import { freshmanGlobalChannelId } from '../../../core/constants';

class Posts extends Component {
  filterFreshmanChannel = ({ globalChannel }) => {
    return globalChannel === freshmanGlobalChannelId;
  };

  render() {
    const { post } = this.props;

    return (
      <div>
        <PostCreator
          post={post}
          channelFilter={this.filterFreshmanChannel}
          postFilter="FIRST_SEMESTER"
          plannedPostFilter="FIRST_SEMESTER_PLANNED"
        />
        <br />
        <PostList postFilter="FIRST_SEMESTER" plannedPostFilter="FIRST_SEMESTER_PLANNED" />
      </div>
    );
  }
}

const mapStateToProps = ({ post }) => ({
  post: post.post,
});

export default connect(mapStateToProps)(Posts);
