import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import { Mutation } from 'react-apollo';
import { RESET_PASSWORT_REQUEST } from 'core/gql/mutations';

import { Form, Button, Input, notification } from 'antd';

import ContentLoggedOut from '../../components/ContentLoggedOut';

class PasswordResetIntro extends Component {
  sendResetPassword = (callback) => {
    const { validateFields } = this.props.form;

    validateFields((err, values) => {
      if (!err && callback) {
        callback({ variables: { email: values['email'] } });
      }
    });
  };

  onError = (error) => {
    const { NODE_ENV } = window;

    notification.error({
      message: NODE_ENV === 'development' ? error.message : 'Passwort zurücksetzen fehlgeschlagen',
      description: 'Bitte kontaktieren sie support@uninow.de.',
    });
  };

  onCompleted = (response) => {
    notification.success({
      message: 'Passwort zurücksetzen erfolgreich',
      description: 'Es wurde dir eine E-Mail mit einen Link gesendet, mit dem du dein Passwort zurücksetzen kannst.',
    });
    this.props.history.push('/');
  };

  render() {
    if (this.context.isAuthenticated === true) {
      return <Redirect to="/settings/account" />;
    }

    const { getFieldDecorator } = this.props.form;

    return (
      <ContentLoggedOut>
        <Mutation mutation={RESET_PASSWORT_REQUEST} onError={this.onError} onCompleted={this.onCompleted}>
          {(callback, { loading }) => (
            <div id="LoginForm">
              <h4 className="weight-medium">Passwort zurücksetzen</h4>
              <Form>
                <div className="align-left">
                  <Form.Item>
                    {getFieldDecorator('email', {
                      validateTrigger: 'onBlur',
                      rules: [
                        {
                          type: 'email',
                          message: 'Keine valide E-Mail-Adresse',
                        },
                        {
                          required: true,
                          message: 'Bitte gib deine E-Mail-Adresse ein',
                        },
                      ],
                    })(
                      <div>
                        <div className="color-secondary weight-medium size-small">Deine E-Mail-Adresse</div>
                        <Input size="large" type="email" />
                      </div>
                    )}
                  </Form.Item>
                </div>
                <Form.Item>
                  <Link to="/" style={{ float: 'left', lineHeight: '16px', padding: 6 }}>
                    Zurück
                  </Link>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ float: 'right' }}
                    onClick={() => this.sendResetPassword(callback)}
                    loading={loading}
                  >
                    Passwort zurücksetzen
                  </Button>
                </Form.Item>
              </Form>
            </div>
          )}
        </Mutation>
      </ContentLoggedOut>
    );
  }
}

PasswordResetIntro.contextTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

export default Form.create()(PasswordResetIntro);
