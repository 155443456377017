import React from 'react';

import { List, Icon } from 'antd';

import { ROLE_PERMISSIONS } from '../../core/constants';

export default ({ role = 'USER' }) => {
  return (
    <List
      header={<span style={{ fontWeight: 400 }}>Berechtigungen</span>}
      size="small"
      dataSource={ROLE_PERMISSIONS}
      renderItem={({ name, roles = [] }) => {
        const allowed = roles.includes(role);

        return (
          <List.Item
            style={{
              textDecoration: allowed ? null : 'line-through',
            }}
          >
            <Icon type={allowed ? 'check' : 'close'} style={{ paddingLeft: 10, paddingRight: 10, fontSize: '0.8em' }} />
            {name}
          </List.Item>
        );
      }}
    />
  );
};
