import React, { Component } from 'react';
import { Query, withApollo } from 'react-apollo';
import { Icon, Spin, Select } from 'antd';
import update from 'immutability-helper';
import VisibilitySensor from 'react-visibility-sensor';

import { GET_POSTS } from 'core/gql/queries';

import Post from '../../../components/Post';
import './../../../components/PostList/style.css';

const DefaultListItem = ({ content }) => <div className="message-list color-secondary">{content}</div>;

const DefaultListItemEnd = ({ content, style = {} }) => (
  <div className="uninow-post postListEnd color-secondary size-tiny" style={style}>
    {content}
  </div>
);

class PostList extends Component {
  state = {
    authorRole: null,
  };

  loadPosts = async (fetchMore, data = {}) => {
    const { authorRole } = this.state;

    const cursor = data && data.posts ? data.posts.edges[data.posts.edges.length - 1].cursor : null;
    fetchMore({
      variables: {
        paginationInput: {
          first: 5,
          before: cursor,
        },
        authorRole,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const newPosts = fetchMoreResult.posts.edges;
        const pageInfo = fetchMoreResult.posts.pageInfo;
        return update(prev, {
          posts: {
            edges: { $push: [...newPosts] },
            pageInfo: { $set: pageInfo },
          },
        });
      },
    });
  };

  hasMorePosts = (loading, error, data = {}) => {
    if (loading) return false;
    else if (error) return false;
    else if (data && data.posts && data.posts.pageInfo.hasPreviousPage) return true;
    else return false;
  };

  renderPost = (edge) => {
    return <Post key={edge.cursor} post={edge.node} />;
  };

  handleRoleSelect = (authorRole) => {
    this.setState({ authorRole });
  };

  render() {
    const { authorRole } = this.state;

    return (
      <Query query={GET_POSTS} variables={{ authorRole }}>
        {({ loading, error, data = {}, fetchMore }) => {
          let render = <div />;
          if (error) {
            render = (
              <div id="PostList">
                <DefaultListItem
                  content={
                    <div>
                      Deine Posts konnten leider nicht geladen werden.
                      <br />
                      <br />
                      Versuche es doch später noch einmal oder kontaktiere uns über{' '}
                      <a href="mailto:support@uninow.de">support@uninow.de</a>.
                    </div>
                  }
                />
              </div>
            );
          }
          if (data.posts && data.posts.edges.length === 0) {
            render = (
              <div id="PostList">
                <DefaultListItem
                  content={
                    <div>
                      Verfasse jetzt deinen ersten <b>Post</b> und halte deine Follower auf dem Laufenden.
                      <br />
                      <br />
                      <Icon
                        type="smile-o"
                        style={{
                          fontSize: 24,
                        }}
                      />
                    </div>
                  }
                />
              </div>
            );
          }
          if (data && data.posts && data.posts.edges.length > 0) {
            render = (
              <div id="PostList">
                {data.posts.edges.map(this.renderPost)}
                {this.hasMorePosts(loading, error, data) && (
                  <VisibilitySensor
                    partialVisibility={true}
                    offset={{ top: 150 }}
                    onChange={(value) => value && this.loadPosts(fetchMore, data)}
                  >
                    <DefaultListItemEnd key="spinner" content={<Spin />} />
                  </VisibilitySensor>
                )}
              </div>
            );
          }
          return (
            <div>
              <div
                style={{
                  backgroundColor: '#ffffff',
                  padding: 25,
                  maxWidth: 750,
                  margin: 'auto',
                  marginBottom: 25,
                }}
              >
                <span className="color-secondary">Welche Posts möchtest du sehen?</span>
                <Select onSelect={this.handleRoleSelect} defaultValue={null} style={{ width: '100%' }}>
                  <Select.Option value={null}>Alle</Select.Option>
                  <Select.Option value="CONTENT_PROVIDER">Content Provider</Select.Option>
                  <Select.Option value="STUDENT_COUNCIL">Fachschaft</Select.Option>
                  <Select.Option value="INSTITUTION">Institution</Select.Option>
                  <Select.Option value="UNIVERSITY">Universität</Select.Option>
                  <Select.Option value="USER">Sonstige</Select.Option>
                </Select>
              </div>
              {render}
              {loading && (
                <div style={{ textAlign: 'center' }}>
                  <Spin />
                </div>
              )}
              {data.posts && !data.posts.pageInfo.hasPreviousPage && data.posts.edges.length > 0 && (
                <DefaultListItemEnd
                  content={<div className="size-tiny">Es gibt keine weiteren Posts</div>}
                  style={{
                    padding: 0,
                    marginTop: -20,
                  }}
                />
              )}
            </div>
          );
        }}
      </Query>
    );
  }
}

export default withApollo(PostList);
