import React, { useState } from 'react';
import { Form, Select } from 'antd';

const options = [
  { value: 'student', label: 'Studierende' },
  { value: 'employee', label: 'Beschäftigte' },
  { value: 'guest', label: 'Gäste' },
  { value: 'interested', label: 'Studieninteressierte' },
  { value: 'alumni', label: 'Alumni' },
];
function RoleTargetingSelect({ value, onChange: propOnChange }) {
  const [loading, setLoading] = useState(false);
  const onChange = async (values) => {
    setLoading(true);
    await propOnChange(values);
    setLoading(false);
  };
  return (
    <Form.Item label="Targeting - Rolle">
      <Select
        loading={loading}
        disabled={loading}
        mode="multiple"
        style={{ width: '100%' }}
        placeholder="Für alle sichtbar"
        onChange={onChange}
        value={value}
        filterOption={(input, option) => option.props.label.toLowerCase().includes(input.toLowerCase())}
      >
        {options.map((option) => (
          <Select.Option key={option.value} labell={option.label}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default RoleTargetingSelect;
