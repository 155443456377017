import React, { Component } from 'react';
import PropTypes from 'prop-types';

import update from 'immutability-helper';

import IconPopover from '../../../components/IconPopover';
import Ionicon from '../../../components/Ionicon';
import Geosearch from '../../../components/Geosearch';

import { ICONS } from '../../../components/Ionicon';
import { isEqual } from 'lodash';
import { Icon, List, Input, Tooltip, Button, Empty, Row, Col } from 'antd';

const PLACEHOLDER = {
  name: 'Titel',
  type: ICONS[0],
};

class LocationList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locations: props.locations || [],
      editIndex: null,
      name: null,
      type: null,
      label: null,
      location: {},
    };
  }

  addLocation = () => {
    const { name, type } = PLACEHOLDER;

    const newLocation = {
      id: `tmp_${Date.now()}`,
      name,
      type,
    };

    this.props.onSelect(newLocation);

    this.setState(
      (state) => update(state, { locations: { $push: [newLocation] } }),
      () => this.toggleEdit(this.state.locations.length - 1)
    );
  };

  removeLocation = (index) => {
    const { locations = [] } = this.state;
    const item = locations[index];

    this.toggleEdit();
    this.props.onDelete(item.id);
    this.setState((state) => update(state, { locations: { $splice: [[index, 1]] } }));
  };

  saveLocation = (index) => {
    const { name, type, location, locations = [] } = this.state;
    const item = locations[index];

    const newLocation = {
      id: (item.id || '').startsWith('tmp_') ? undefined : item.id,
      name: name || item.name,
      type: type || item.type,
      location: location || item.location,
    };

    this.props.onSave(newLocation);
    this.setState((state) =>
      update(state, {
        editIndex: { $set: null },
        locations: { [index]: { $set: newLocation } },
      })
    );
    this.resetValues();
  };

  toggleEdit = (index = null) => {
    const { editIndex, locations = [] } = this.state;
    const selectedItem = locations[editIndex] || {};

    let stateUpdate;

    if (index !== null) {
      const item = this.state.locations[index];
      const { name, type, location = {} } = item;

      stateUpdate = {
        editIndex: index,
        name: name || null,
        type: type || null,
        label: location.label || null,
        location: location || {},
      };
    } else {
      stateUpdate = { editIndex: null };

      this.resetValues();
    }

    if (editIndex !== null && !selectedItem.id) {
      stateUpdate.locations = update(locations, {
        $splice: [[locations.length - 1, 1]],
      });
    }

    this.setState(stateUpdate);
  };

  updateValue = (key, value) => {
    const { name, type, editIndex, locations = [] } = this.state;
    const item = locations[editIndex];

    switch (key) {
      case 'location':
        this.setState({
          label: value ? value.label : null,
          location: value,
        });

        const newLocation = {
          id: item.id,
          name: name || item.name,
          type: type || item.type,
          location: value,
        };
        this.props.onSelect(newLocation);
        break;

      default:
        this.setState({
          [key]: value,
        });
        break;
    }
  };

  moveLocation = (index, value) => {
    this.toggleEdit();
    this.props.onMove(index, value);
  };

  resetValues = () => {
    this.setState({
      name: null,
      type: null,
      label: null,
      location: {},
    });
    this.props.onSelect(null);
  };

  renderLocation = (item, index) => {
    const { locations = [] } = this.state;
    const { editIndex, name, type, label } = this.state;

    if (editIndex === index) {
      return (
        <List.Item
          actions={[
            <Tooltip title="Speichern">
              <Icon className="color-primary" type="save" onClick={() => this.saveLocation(index)} />
            </Tooltip>,
            <Tooltip title="Abbrechen">
              <Icon className="color-primary" type="close" onClick={() => this.toggleEdit()} />
            </Tooltip>,
          ]}
        >
          <List.Item.Meta
            avatar={
              <IconPopover value={type} defaultValue={item.type} onSelect={(icon) => this.updateValue('type', icon)} />
            }
            title={
              <Input
                size="small"
                value={name}
                placeholder={item.name || PLACEHOLDER.name}
                onChange={({ target }) => this.updateValue('name', target.value)}
              />
            }
            description={
              <Geosearch
                size="small"
                initialValue={(item.location ? item.location.label : undefined) || label || ''}
                onSelect={(location) => this.updateValue('location', location)}
              />
            }
          />
        </List.Item>
      );
    } else {
      return (
        <Row type="flex" align="middle" justify="center" gutter={5} style={{ paddingBottom: 10 }}>
          <Col xs={2} sm={2} md={2} lg={3} xl={2} xxl={1} style={{ textAlign: 'center' }}>
            <Row>
              <Icon
                type="up"
                className={index !== 0 ? 'clickable-primary' : 'color-secondary'}
                onClick={index !== 0 ? () => this.moveLocation(index, -1) : null}
              />
            </Row>
            <Row>
              <span className="weight-bold">{index + 1}</span>
            </Row>
            <Row>
              <Icon
                type="down"
                className={index < locations.length - 1 ? 'clickable-primary' : 'color-secondary'}
                onClick={index < locations.length - 1 ? () => this.moveLocation(index, 1) : null}
              />
            </Row>
          </Col>
          <Col xs={22} sm={22} md={22} lg={21} xl={22} xxl={23}>
            <List.Item
              actions={[
                <Tooltip title="Bearbeiten">
                  <Icon className="color-primary" type="edit" onClick={() => this.toggleEdit(index)} />
                </Tooltip>,
                <Tooltip title="Löschen">
                  <Icon className="color-primary" type="delete" onClick={() => this.removeLocation(index)} />
                </Tooltip>,
              ]}
            >
              <List.Item.Meta
                avatar={<Ionicon type={item.type} />}
                title={item.name || <span style={{ fontStyle: 'italic' }}>Ohne Titel</span>}
                description={
                  item.location ? (
                    item.location.label || <span style={{ fontStyle: 'italic' }}>Keine Standortinformationen</span>
                  ) : (
                    <span style={{ fontStyle: 'italic' }}>Keine Standortinformationen</span>
                  )
                }
              />
            </List.Item>
          </Col>
        </Row>
      );
    }
  };

  componentDidUpdate({ locations: oldLocations }) {
    const { editIndex } = this.state;
    const { locations = [] } = this.props;

    if (!isEqual(oldLocations, locations)) {
      this.setState({
        locations,
        location: editIndex !== null && locations[editIndex] ? locations[editIndex].location : {},
      });
    }
  }

  UNSAFE_componentWillReceiveProps({ tmpLocation: nextLocation }) {
    const { editIndex, locations = [] } = this.state;
    const { tmpLocation } = this.props;

    if (nextLocation && editIndex !== null && tmpLocation && (tmpLocation.id || '').startsWith('tmp_')) {
      this.setState({
        location: nextLocation.location,
        locations: update(locations, {
          [editIndex]: { location: { $set: nextLocation.location } },
        }),
      });
    }
  }

  render() {
    const { disabled = false } = this.props;
    const { editIndex, locations = [] } = this.state;

    return (
      <div>
        {locations.length === 0 ? (
          <Empty description=" " />
        ) : (
          <List dataSource={locations} renderItem={this.renderLocation} />
        )}
        <div style={{ textAlign: 'center', width: '100%' }}>
          <Button disabled={disabled || editIndex !== null} icon="plus" onClick={this.addLocation}>
            Standort hinzufügen
          </Button>
        </div>
      </div>
    );
  }
}

LocationList.propTypes = {
  locations: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  tmpLocation: PropTypes.object,
};

export default LocationList;
