import React, { memo } from 'react';

import { Row, Col, Icon, Tooltip, Button } from 'antd';

import './style.css';

export default memo(function ({
  title = null,
  description = null,
  checked = false,
  minimized = false,
  onClick = null,
  onHide = null,
}) {
  return (
    <div id="ChecklistItem">
      <Row type="flex" align="middle" style={{ padding: '10px 20px' }}>
        <Col span={4} onClick={onClick} className={onClick ? 'clickable' : ''}>
          {checked && (
            <Icon
              style={{
                color: '#f21850',
                fontSize: '1.25em',
              }}
              type="check"
            />
          )}
        </Col>
        <Col
          span={19}
          style={{ color: checked ? '#85858f' : 'inherit' }}
          onClick={onClick}
          className={onClick ? 'clickable' : ''}
        >
          {minimized ? (
            <Tooltip title={description}>
              <div style={{ fontWeight: 400 }}>{title}</div>
            </Tooltip>
          ) : (
            <>
              <div style={{ fontWeight: 400 }}>{title}</div>
              <div style={{ fontSize: '0.8em' }}>{description}</div>
            </>
          )}
        </Col>
        {!checked && onHide && (
          <Col span={1}>
            <Tooltip title={'Überspringen'}>
              <Button type="link" icon="stop" size={'small'} onClick={onHide} />
            </Tooltip>{' '}
          </Col>
        )}
      </Row>
    </div>
  );
});
