import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Form, Input, Button } from 'antd';

import './style.css';

class LoginForm extends Component {
  state = {
    email: null,
    password: null,
  };

  onLogin = () => {
    const { validateFields } = this.props.form;

    validateFields((err, values) => {
      if (!err) {
        if (this.props.onLogin) {
          this.props.onLogin(values);
        }
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const isJobware = getFieldValue('email')?.includes('@jobware.de');
    return (
      <div id="LoginForm">
        <h4 className="weight-medium">Login</h4>
        <Form>
          <div className="align-left">
            <Form.Item>
              {getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    message: 'Bitte gib eine E-Mail-Adresse ein',
                  },
                ],
              })(
                <div>
                  <div className="color-secondary weight-medium size-small">E-Mail-Adresse</div>
                  <Input size="large" type="email" />
                </div>
              )}
            </Form.Item>
          </div>
          <div className="align-left">
            <Form.Item>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Bitte gib ein Passwort ein' }],
              })(
                <div>
                  <div className="color-secondary weight-medium size-small">
                    Passwort
                    <a
                      href="https://accounts.uninow.com/auth/forgot-password"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="color-secondary weight-light size-tiny"
                      style={{ float: 'right' }}
                    >
                      Passwort vergessen?
                    </a>
                  </div>
                  <Input type="password" size="large" />
                </div>
              )}
            </Form.Item>
          </div>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              onClick={this.onLogin}
              loading={this.props.loading}
              disabled={isJobware}
            >
              Anmelden
            </Button>
            <br />
            {isJobware && (
              <span>
                Bitte logge dich auf <a href={'https://recruiting.uninow.com'}>recruiting.uninow.com</a> ein.
              </span>
            )}
          </Form.Item>
        </Form>
      </div>
    );
  }
}

LoginForm.propTypes = {
  onLogin: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default Form.create()(LoginForm);
