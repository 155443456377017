import React, { useState, useEffect } from 'react';

import { useQuery } from '@apollo/react-hooks';
import { FOLLOWER_TREND } from 'core/gql/queries';

import { /* Select, */ Spin, /* Form, */ Empty } from 'antd';
import { LineGraph } from 'components';

// const formItemLayout = {
//   labelCol: {
//     xs: { span: 6 }
//   },
//   wrapperCol: {
//     xs: { span: 18 }
//   }
// };

export default () => {
  const [initialLoad, setInitialLoad] = useState(true);
  const [variables] = useState({ unit: 'DAYS', amount: 30 });

  const { data = {}, loading } = useQuery(FOLLOWER_TREND, {
    variables,
  });

  useEffect(() => {
    if (loading === false && initialLoad === true) {
      setInitialLoad(false);
    }
  }, [loading, initialLoad]);

  const { followerTrend = {} } = data;

  const { values = [] } = followerTrend;

  const interval = Math.round(values.length / 7);

  return (
    <div>
      <div className="" style={{ paddingBottom: 15 }}>
        Verlauf der letzten 30 Tage.
        <br />
        <span className="color-secondary size-small">Hinweis: Heutige Daten sind nicht enthalten.</span>
      </div>
      {/* <Form.Item {...formItemLayout} labelAlign="left" label="Zeitraum">
        <Select
          style={{ width: '100%' }}
          defaultValue={groupBy}
          onSelect={value => setGroupBy(value)}
        >
          <Select.Option value="days">Letzte Woche</Select.Option>
          <Select.Option value="weeks">Letzter Monat</Select.Option>
          <Select.Option value="month">Letztes Halbjahr</Select.Option>
        </Select>
  </Form.Item>*/}

      {initialLoad ? (
        <div style={{ textAlign: 'center' }}>
          <Spin />
        </div>
      ) : values.length === 0 ? (
        <Empty description="Keine Daten vorhanden" />
      ) : (
        <LineGraph
          data={values.map(({ x, y }) => ({ x: parseInt(x), y: parseInt(y) }))}
          interval={interval}
          loading={loading}
        />
      )}
    </div>
  );
};
