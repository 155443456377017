import update from 'immutability-helper';

import { MINIMIZE, COMPLETED, CLOSE, RESET_PROGRESS } from '../actions/progress.js';

const defaultState = { minimized: false, closed: false, successModal: false };

function progress(state = defaultState, action) {
  switch (action.type) {
    case MINIMIZE: {
      return update(state, {
        minimized: { $set: true },
      });
    }

    case COMPLETED: {
      return update(state, {
        successModal: { $set: true },
      });
    }

    case CLOSE: {
      return update(state, {
        closed: { $set: true },
        successModal: { $set: false },
      });
    }

    case RESET_PROGRESS:
      return defaultState;

    default:
      return state;
  }
}

export default progress;
