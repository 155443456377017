import { createStore } from 'redux';

import { persistStore, persistCombineReducers } from 'redux-persist';
import createEncryptor from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/es/storage';
import post from './reducers/post';
import preview from './reducers/preview';
import progress from './reducers/progress';
import user from './reducers/user';
import chatwoot from './reducers/chatwoot';

const encryptor = createEncryptor({
  secretKey: window.REACT_APP_STORE_KEY,
});

const config = {
  key: 'uninow_feed',
  transforms: [encryptor],
  storage,
};

const reducer = persistCombineReducers(config, {
  post,
  preview,
  progress,
  user,
  chatwoot
});

export default function configureStore() {
  const store = createStore(reducer);

  let persistor = persistStore(store);

  return { persistor, store };
}
