export const CHANGE_AUTH_DATA = 'CHANGE_AUTH_DATA';
export const CHANGE_FEED_VERSION = 'CHANGE_FEED_VERSION';
export const CHANGE_PASSWORD_SET = 'CHANGE_PASSWORD_SET';
export const IGNORE_SETUP_STEP = 'IGNORE_SETUP_STEP';
export const SET_SELECTED_PROFILE = 'SET_SELECTED_PROFILE';
export const SET_HAS_SEEN_DISCLAIMER = 'SET_HAS_SEEN_DISCLAIMER';

export function changeAuthDataActionCreator(token, role, accountRole, accountId, userData) {
  return {
    type: CHANGE_AUTH_DATA,
    token,
    role,
    accountRole,
    accountId,
    userData,
  };
}
export function setSelectedProfile(profile) {
  return {
    type: SET_SELECTED_PROFILE,
    profile,
  };
}
export function changeFeedVersion(feedVersion) {
  return {
    type: CHANGE_FEED_VERSION,
    feedVersion,
  };
}

export function changePasswordSet(isSet) {
  return {
    type: CHANGE_PASSWORD_SET,
    isSet,
  };
}
export function ignoreSetupStep(step) {
  return {
    type: IGNORE_SETUP_STEP,
    step,
  };
}
export function setHasSeenDisclaimer(hasSeenDisclaimer) {
  return {
    type: SET_HAS_SEEN_DISCLAIMER,
    hasSeenDisclaimer,
  };
}
