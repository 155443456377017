import { Component } from 'react';
import { connect } from 'react-redux';

import { notification } from 'antd';

import { changeFeedVersion } from '../../core/redux/actions/user';

import CHANGELOG from './changelog.json';

class Changelognotification extends Component {
  UNSAFE_componentWillMount() {
    const { feedVersion, changeVersion } = this.props;

    const mostRecentChange = CHANGELOG[CHANGELOG.length - 1];

    if (compareVersion(mostRecentChange.version, feedVersion) > 0) {
      if (feedVersion) {
        notification.info({
          duration: null,
          message: mostRecentChange.message,
          description: mostRecentChange.description,
        });
      }

      changeVersion(mostRecentChange.version);
    }
  }

  render() {
    return null;
  }
}

function compareVersion(versionA, versionB) {
  if (!versionA && !versionB) {
    return 0;
  }

  if (versionA && !versionB) {
    return 1;
  }

  if (!versionA && versionB) {
    return -1;
  }

  const a = versionA.split('.').map((v) => parseInt(v, 10));
  const b = versionB.split('.').map((v) => parseInt(v, 10));

  return a.reduce((res, p, i) => (res !== 0 ? res : p - b[i]), 0);
}

const mapStateToProps = ({ user }) => ({
  feedVersion: user.feedVersion,
});

const mapDispatchToProps = (dispatch) => ({
  changeVersion: (version) => dispatch(changeFeedVersion(version)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Changelognotification);
