import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import { Icon, Message } from 'antd';

import './style.css';

const MAX_IMAGE_SIZE = 2000000000; // 20MB

class ImageDropzone extends Component {
  onFilesAccept = (files) => {
    if (this.props.onFileSelected) {
      this.props.onFileSelected(files);
    }
  };

  onFilesRejected = (files) => {
    files.forEach((file) => {
      if (file.size > MAX_IMAGE_SIZE) {
        Message.error(`Dateigröße von ${file.name} übersteigt das Limit von ${MAX_IMAGE_SIZE / 1000000}MB.`);
      } else {
        Message.error(`${file.name} konnte nicht hochgeladen werden.`);
      }
    });
  };

  render() {
    const { shape, icon, style = {} } = this.props;

    return (
      <Dropzone
        id="ImageDropzone"
        className={`overlay ${shape}`}
        accept="image/jpeg, image/png"
        maxSize={MAX_IMAGE_SIZE}
        multiple={this.props.multiple}
        onDropRejected={this.onFilesRejected}
        onDropAccepted={this.onFilesAccept}
        style={style}
      >
        <div style={{ position: 'relative', height: '100%' }}>
          <Icon type={icon} className="upload-icon" />
        </div>
      </Dropzone>
    );
  }
}

ImageDropzone.propTypes = {
  onFileSelected: PropTypes.func,
  shape: PropTypes.string,
  multiple: PropTypes.bool,
  icon: PropTypes.string,
};

ImageDropzone.defaultProps = {
  multiple: false,
  icon: 'picture',
};

export default ImageDropzone;
