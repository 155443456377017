import React, { Component } from 'react';

import { Spin, Form, Input, Button, notification, Alert } from 'antd';
import { Query, Mutation, withApollo } from 'react-apollo';

import { PROFILE } from 'core/gql/queries';
import { UPDATE_USER } from 'core/gql/mutations';

class AccessToken extends Component {
  state = {
    pendingChanges: false,
    showAccessToken: false,
  };

  updatePending = (event) => {
    const { pendingChanges } = this.state;
    const { getFieldsValue } = this.props.form;
    const { accessToken } = this.data;

    const values = {
      ...getFieldsValue(),
      [event.target.id]: event.target.value,
    };

    const accessTokenChanged = values.hasOwnProperty('accessToken') && accessToken !== values.accessToken;

    const hasChanges = accessTokenChanged;

    if (hasChanges !== pendingChanges) {
      this.setState({
        pendingChanges: hasChanges,
      });
    }
  };

  updateTimer(field) {
    clearTimeout(this.timer);
    this.setState(
      { timerValidatingField: field },
      () =>
        (this.timer = setTimeout(() => {
          this.props.form.validateFields([this.state.timerValidatingField]);
        }, 1000))
    );
  }

  saveSettings = async (callback, currentSettings) => {
    const { validateFields } = this.props.form;

    validateFields(async (errors, values) => {
      if (errors) {
        return;
      }

      if (callback) {
        let body = {
          variables: {
            accessToken: values.accessToken || null,
          },
        };

        callback(body);
        this.setState({ pendingChanges: false });
      }
    });
  };

  onError = () => {
    notification.error({
      message: 'Einstellungen konnten nicht gespeichert werden',
      description: 'Bitte versuchen Sie es später noch einmal oder kontaktieren Sie uns.',
      duration: 3,
    });
  };

  onSuccess = () => {
    notification.success({
      message: 'Einstellungen wurden aktualisiert',
      description: 'Ihre Einstellungen wurden erfolgreich gespeichert',
      duration: 3,
    });
  };

  render() {
    const { showAccessToken, pendingChanges } = this.state;
    const { getFieldDecorator } = this.props.form;

    const formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13, offset: 2 },
        md: { span: 15, offset: 2 },
      },
    };

    return (
      <Query query={PROFILE}>
        {({ loading, error, data }) => {
          if (loading) {
            return (
              <div className="uninow-content-container" style={{ textAlign: 'center' }}>
                <Spin />
              </div>
            );
          }

          if (error || !data.me) {
            return (
              <div className="uninow-content-container" style={{ textAlign: 'center' }}>
                Deine Einstellungen konnten leider nicht geladen werden.
                <br />
                <br />
                Versuche es doch später noch einmal oder kontaktiere uns über{' '}
                <a href="mailto:support@uninow.de">support@uninow.de</a>.
              </div>
            );
          }

          const { accessToken = '' } = data.me;

          this.data = {
            accessToken,
          };

          return (
            <Form className="uninow-content-container">
              <div className="headline weight-bold size-large">Zugangscode zum Erstsemesterbereich</div>
              <Form.Item
                label="Zugangscode"
                extra={
                  <div>
                    Hier könnt ihr einen individuellen Zugangscode für den Erstsemesterbereich erstellen. Zugriff zu dem
                    Modul und eure Inhalte bekommen nur Nutzer mit dem Zugangscode.
                    <br />
                    <span
                      className="clickable-primary"
                      onClick={() =>
                        this.setState({
                          showAccessToken: !showAccessToken,
                        })
                      }
                    >
                      {showAccessToken ? 'Zugangscode verbergen' : 'Zugangscode anzeigen'}
                    </span>
                    <br />
                    <br />
                    <Alert
                      style={{ fontSize: 12 }}
                      message={
                        <div>
                          Wenn ihr den Zugangscode ändert, müssen eure <b>Erstis</b> den Code erneut eingeben, um wieder
                          Zugriff auf den Erstsemesterbereich zu erhalten.
                          <br />
                          <br />
                          <b>Praktisch:</b> So könnt ihr im nächsten Semester alle Anderen aus dem Bereich entfernen.
                        </div>
                      }
                      type="info"
                      showIcon
                    />
                  </div>
                }
                {...formLayout}
                hasFeedback={true}
              >
                {getFieldDecorator('accessToken', {
                  initialValue: accessToken,
                  validateTrigger: 'onBlur',
                  rules: [
                    {
                      min: 6,
                      message: 'Bitte gib mindestens 6 Zeichen ein',
                    },
                    {
                      max: 25,
                      message: 'Bitte gib maximal 25 Zeichen ein',
                    },
                    {
                      pattern: new RegExp('^[a-zA-Z0-9_.-]*$'),
                      message: 'Bitte benutze für den Zugangscode nur die Zeichen: a-z, A-Z, 0-9, - , _ und .',
                    },
                  ],
                })(
                  <Input
                    type={showAccessToken ? 'text' : 'password'}
                    placeholder="Kein Zugangscode vergeben"
                    onChange={(event) => {
                      this.updatePending(event);
                      this.updateTimer('accessToken');
                    }}
                  />
                )}
              </Form.Item>

              <Form.Item {...formLayout} label=" " colon={false}>
                <Mutation
                  onError={this.onError}
                  onCompleted={this.onSuccess}
                  mutation={UPDATE_USER}
                  update={(cache, { data: { updateUser } }) => {
                    const { me } = cache.readQuery({
                      query: PROFILE,
                    });
                    cache.writeQuery({
                      query: PROFILE,
                      data: { me: { ...me, ...updateUser } },
                    });
                  }}
                >
                  {(send, { loading }) => (
                    <Button
                      loading={loading}
                      disabled={!pendingChanges}
                      type="primary"
                      htmlType="submit"
                      onClick={() => this.saveSettings(send, data.me)}
                    >
                      Änderungen Speichern
                    </Button>
                  )}
                </Mutation>
              </Form.Item>
            </Form>
          );
        }}
      </Query>
    );
  }
}

export default Form.create()(withApollo(AccessToken));
