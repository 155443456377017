import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import moment from 'moment';

import { Row, Col, Radio, Form, Input, Tooltip, Button, Select, DatePicker, TimePicker } from 'antd';
import locale from 'antd/lib/date-picker/locale/de_DE';

class Poll extends Component {
  constructor(props) {
    super(props);

    const { options, end } = props.data || {};

    const newOptions = props.form.getFieldValue('attachment.translations.options');

    this.state = {
      pollEnd: end ? moment(end) : moment().add(1, 'day'),
      pollDatePickerOpen: false,
      pollTimePickerOpen: false,
      options: options
        ? options.map(({ option }) => option)
        : newOptions && Object.keys(newOptions).length > 1
        ? Object.keys(newOptions).map((el, index) => moment.utc().add(index, 'second').format('x'))
        : [moment.utc().format('x'), moment.utc().add(1, 'second').format('x')],
      primaryLanguage: 'de',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newOptions = nextProps.form.getFieldValue('attachment.translations.options');
    if (!this.props.data) {
      if (
        nextProps.activeLanguage !== this.props.language &&
        Object.keys(newOptions).length > this.state.options.length
      ) {
        this.setState({
          options: Object.keys(newOptions).map((el, index) => moment.utc().add(index, 'second').format('x')),
        });
      }
      if (
        nextProps.activeLanguage !== this.props.language &&
        Object.keys(newOptions).filter(
          (el) => newOptions?.[el]?.[nextProps.activeLanguage] || newOptions?.[el]?.[nextProps.activeLanguage] === null
        ).length < this.state.options.length &&
        Object.keys(newOptions).filter(
          (el) => newOptions?.[el]?.[nextProps.activeLanguage] || newOptions?.[el]?.[nextProps.activeLanguage] === null
        ).length > 1
      ) {
        const index = Object.keys(newOptions).findIndex(
          (el) => newOptions?.[el]?.[nextProps.activeLanguage] || newOptions?.[el]?.[nextProps.activeLanguage] === null
        );
        this.setState({
          options: [...this.state.options.slice(0, index), ...this.state.options.slice(index + 1)],
        });
      }
    }
  }

  onSelectPollEnd = (value) => {
    const { setFieldsValue } = this.props.form;

    switch (value) {
      case 'day':
        const inOneDay = moment().add(1, 'day');
        setFieldsValue({
          pollEndSelection: value,
          'attachment.end': inOneDay,
        });

        this.setState({
          pollEnd: inOneDay,
        });
        break;

      case 'week':
        const inOneWeek = moment().add(1, 'week');

        setFieldsValue({
          pollEndSelection: value,
          'attachment.end': inOneWeek,
        });

        this.setState({
          pollEnd: moment().add(1, 'week'),
        });
        break;

      default:
        this.setState({
          pollDatePickerOpen: true,
        });
        break;
    }
  };

  onDateTimeSelect = (date, prop) => {
    const { setFieldsValue, getFieldValue } = this.props.form;

    const currentEnd = getFieldValue('attachment.end');

    const nextEnd =
      prop === 'time'
        ? currentEnd.clone().hour(date.hour()).minute(date.minute())
        : date.hour(currentEnd.hour()).minute(currentEnd.minute());

    setFieldsValue({
      pollEndSelection: 'custom',
      'attachment.end': nextEnd,
    });

    this.setState({
      pollEnd: nextEnd,
    });
  };

  addOption = () => {
    const { setFieldsValue } = this.props.form;
    const { options } = this.state;

    this.setState(
      {
        options: [...options, moment.utc().format('x')],
      },
      () => {
        setFieldsValue({
          [`attachment.translations.options.${options.length}.${this.props.language}`]: null,
        });
      }
    );
  };

  removeOption = (index) => {
    const { setFieldsValue, getFieldValue, resetFields } = this.props.form;
    const { options } = this.state;

    let fieldIndex = 0;
    const fieldsValue = Object.values(getFieldValue(`attachment.translations.options`)).reduce((fields, value, i) => {
      if (i !== index) {
        fields[`attachment.translations.options.${fieldIndex}`] = value;
        fieldIndex += 1;
      } else {
        fields[`attachment.translations.options.${fieldIndex}`] = {
          de: null,
          en: null,
        };
      }

      return fields;
    }, {});

    this.setState(
      {
        options: [...options.slice(0, index), ...options.slice(index + 1)],
      },
      () => {
        setFieldsValue(fieldsValue);
      }
    );
  };

  renderOption = (option, index) => {
    const { options } = this.state;
    const { getFieldDecorator, getFieldValue } = this.props.form;

    const editMode = !!this.props.data;
    const primaryValues = {};
    const { translations } = this.props.data || {};
    // const translations = getFieldValue(`attachment.translations`);
    // const primaryValues = translations ? mapValues(translations, (el) => el?.[this.state.primaryLanguage]) : null;

    return (
      <Row key={option} type="flex" align="middle" justify="center">
        <Col span={2} style={{ textAlign: 'center' }}>
          <Radio disabled />
        </Col>
        <Col span={20}>
          <Form.Item>
            {getFieldDecorator(`attachment.translations.options.${index}.${this.props.language}`, {
              initialValue: editMode
                ? translations?.options?.length > index
                  ? translations.options[index][this.props.language]
                  : options[index]
                : null,
              rules: [
                {
                  required: this.props.language === this.state.primaryLanguage,
                },
                {
                  pattern: /\S/, // eslint-disable-line
                  message: 'Optionen dürfen nicht nur aus Leerzeichen bestehen',
                },
              ],
            })(<Input placeholder={primaryValues?.options?.[index] || `Option ${index + 1}`} disabled={editMode} />)}
          </Form.Item>
        </Col>
        <Col span={2} style={{ textAlign: 'center' }} className="remove-option">
          {options.length > 2 && !editMode && (
            <Tooltip title="Option löschen">
              <Button size="small" shape="circle" icon="close" onClick={() => this.removeOption(index)} />
            </Tooltip>
          )}
        </Col>
      </Row>
    );
  };

  render() {
    const { data, form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const { options, pollEnd, pollDatePickerOpen, pollTimePickerOpen } = this.state;

    const fieldEnd = getFieldValue('attachment.end') || pollEnd;

    const editMode = !!data;

    return (
      <div>
        <div style={{ textAlign: 'left', padding: '0px 20px 10px 20px' }}>
          {options.map(this.renderOption)}
          <Row>
            <Col span={20} offset={2} style={{ paddingTop: 10 }}>
              {options.length < 10 && !editMode && (
                <span className="clickable-primary" onClick={this.addOption}>
                  + Option hinzufügen
                </span>
              )}
            </Col>
          </Row>
        </div>
        <div
          style={{
            backgroundColor: '#e4e6eb',
            borderRadius: '0 0 7px 7px',
            borderTop: '1px solid #c2c2cd',
          }}
        >
          <Row type="flex" align="middle" style={{ padding: '10px 20px' }}>
            <Col xs={0} sm={2} />
            <Col xs={24} sm={8}>
              <Form.Item>
                {getFieldDecorator('pollEndSelection', {
                  initialValue: editMode ? 'custom' : 'day',
                  rules: [
                    {
                      required: true,
                    },
                  ],
                })(
                  <Select onSelect={this.onSelectPollEnd}>
                    <Select.Option value="day">1 Tag</Select.Option>
                    <Select.Option value="week">1 Woche</Select.Option>
                    <Select.Option value="custom">Benutzerdefiniert</Select.Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} style={{ textAlign: 'left' }} id="TriggerPicker">
              <div className="size-small color-secondary" style={{ paddingLeft: 20 }}>
                <span id="DatePickerAnchor"> Umfrage endet am </span>
                <span
                  className="clickable-primary"
                  onClick={() => {
                    this.setState({ pollDatePickerOpen: true });
                  }}
                >
                  {fieldEnd.format('D. MMM')}
                </span>
                <span id="TimePickerAnchor" style={{ position: 'relative' }}>
                  {' '}
                  um{' '}
                </span>
                <span
                  className="clickable-primary"
                  onClick={() => {
                    this.setState({ pollTimePickerOpen: true });
                  }}
                >
                  {fieldEnd.format('HH:mm')}
                </span>
              </div>
              {getFieldDecorator('attachment.end', {
                initialValue: fieldEnd,
                rules: [
                  {
                    required: true,
                  },
                ],
              })(
                <div>
                  <DatePicker
                    locale={locale}
                    style={{ display: 'none' }}
                    onChange={(date) => this.onDateTimeSelect(date, 'date')}
                    defaultValue={fieldEnd}
                    disabledDate={(currentDate) => {
                      const now = moment().startOf('day');
                      return currentDate && currentDate.isBefore(now);
                    }}
                    getCalendarContainer={() => document.getElementById('DatePickerAnchor')}
                    onOpenChange={(open) => this.setState({ pollDatePickerOpen: open })}
                    open={pollDatePickerOpen}
                  />
                  <TimePicker
                    locale={locale}
                    allowClear={false}
                    defaultValue={fieldEnd}
                    format="HH:mm"
                    style={{ display: 'none' }}
                    onChange={(date) => this.onDateTimeSelect(date, 'time')}
                    getPopupContainer={() => document.getElementById('TimePickerAnchor')}
                    onOpenChange={(open) => this.setState({ pollTimePickerOpen: open })}
                    open={pollTimePickerOpen}
                  />
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

Poll.propTypes = {
  form: PropTypes.object.isRequired,
  language: PropTypes.string,
};

export default Poll;
