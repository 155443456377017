import React from 'react';

import { Row, Col } from 'antd';

import './style.css';

export default function ({ leftSpans = {}, middleSpans = {}, rightSpans = {}, children = [] }) {
  return (
    <div id="ThreeColumnLayout" className="uninow-feed-content">
      <Row type="flex" justify="center" gutter={25}>
        <Col xs={24} md={8} lg={7} xl={6} {...leftSpans}>
          {children[0] || null}
        </Col>
        <Col xs={24} md={16} lg={17} xl={12} {...middleSpans}>
          {children[1] || null}
        </Col>
        <Col xs={0} md={0} lg={0} xl={6} {...rightSpans}>
          {children[2] || null}
        </Col>
      </Row>
    </div>
  );
}
