export const LOAD_POST = 'LOAD_POST';
export const RESET_POST = 'RESET_POST';

export function loadPost(post) {
  return {
    type: LOAD_POST,
    post: post,
  };
}

export function resetPost() {
  return {
    type: RESET_POST,
  };
}
