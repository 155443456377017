import React from 'react';
import PropTypes from 'prop-types';

import { Progress } from 'antd';

import './style.css';

const ProgressBar = (props) => <Progress id="ProgressBar" percent={props.value} showInfo={false} />;

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
};

export default ProgressBar;
