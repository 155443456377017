import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Query } from 'react-apollo';

import { GET_POST_STATISTICS } from 'core/gql/queries';

import { Post } from '../';
import LinkListing from './components/LinkListing';

import { Modal, Row, Col, Spin, Statistic, Empty, Icon, Popover, Divider } from 'antd';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';

class StatisticModal extends Component {
  renderPieChartRow = (data) => (
    <Row>
      <Col span={12}>
        <PieChart width={200} height={100}>
          <Pie
            data={data}
            cx={80}
            cy={100}
            startAngle={180}
            endAngle={0}
            innerRadius={60}
            outerRadius={80}
            paddingAngle={5}
            nameKey="label"
            dataKey="value"
            animationBegin={100}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={`rgba(242, 24, 80, ${1 - (1 * index) / data.length})`} />
            ))}
          </Pie>
          <Tooltip isAnimationActive={false} wrapperStyle={{ zIndex: 100 }} />
        </PieChart>
      </Col>
      <Col span={12}>
        <table style={{ fontSize: 12 }}>
          {data.map(({ label, value }) => (
            <tr key={label}>
              <td style={{ verticalAlign: 'top' }}>{(value * 100).toFixed(2)}%</td>
              <td style={{ paddingLeft: 10 }}>{label}</td>
            </tr>
          ))}
        </table>
      </Col>
    </Row>
  );

  render() {
    const { visible, onCancel, post } = this.props;

    return (
      <Modal visible={visible} onCancel={onCancel} width={1000} footer={null} destroyOnClose>
        {post && (
          <Row gutter={25} type="flex" justify="space-around">
            <Col xs={24} sm={24} md={24} lg={14} style={{ borderRight: '1px solid #d8d8d8' }}>
              <Post key={post.id} post={post} />
            </Col>
            <Col xs={24} sm={24} md={24} lg={10} style={{ paddingLeft: 20 }}>
              <Query query={GET_POST_STATISTICS} variables={{ id: post.id }} fetchPolicy="network-only">
                {({ loading, error, data }) => {
                  if (loading) {
                    return (
                      <div style={{ textAlign: 'center', paddingTop: 25 }}>
                        <Spin />
                      </div>
                    );
                  }

                  if (error) {
                    return (
                      <div style={{ textAlign: 'center', paddingTop: 25 }}>
                        Es ist leider ein Fehler aufgetreten. Bitte kontaktieren sie{' '}
                        <a href="mailto:support@uninow.de">support@uninow.de</a>.
                      </div>
                    );
                  }

                  const { publishDate, createdAt, statistics, likesCount = 0 } = data.post;

                  const { seen = 0, uniqueSeen = 0, clicks = [], affiliations = [], universities = [] } = statistics;

                  const insufficientData = affiliations.length === 0 || universities.length === 0;

                  const overallClicks = clicks.reduce((result, { value }) => result + value, 0);

                  const noClicks = overallClicks === 0;
                  const multipleLinks = clicks.length > 1;
                  const sortedAffiliations = affiliations
                    .sort((a, b) => b.value - a.value)
                    .slice(0, 5)
                    .map((a) => ({ ...a, value: Math.round(a.value * 1e4) / 1e4 }));
                  const sortedUniversities = universities
                    .sort((a, b) => b.value - a.value)
                    .slice(0, 5)
                    .map((a) => ({ ...a, value: Math.round(a.value * 1e4) / 1e4 }));
                  return (
                    <Fragment>
                      <Row>
                        <span className="color-secondary size-small">
                          {moment(publishDate || createdAt).format('[Veröffentlicht am] DD.MM.YYYY [um] HH:mm [Uhr]')}
                        </span>
                      </Row>
                      <Row>
                        <Col span={noClicks ? 8 : 12} style={{ paddingTop: 20 }}>
                          <Statistic
                            title={<span className="color-secondary size-small">Gesehen</span>}
                            value={seen}
                            groupSeparator="."
                          />
                        </Col>
                        <Col span={noClicks ? 8 : 12} style={{ paddingTop: 20 }}>
                          <Statistic
                            title={<span className="color-secondary size-small">Nutzer</span>}
                            value={uniqueSeen}
                            groupSeparator="."
                          />
                        </Col>
                        <Col span={noClicks ? 8 : 12} style={{ paddingTop: 20 }}>
                          <Statistic
                            title={<span className="color-secondary size-small">Likes</span>}
                            value={likesCount}
                          />
                        </Col>
                        <Col span={noClicks ? 0 : 12} style={{ paddingTop: 20 }}>
                          <Statistic
                            title={
                              <span className="color-secondary size-small">
                                Klicks auf Verlinkungen
                                {multipleLinks && (
                                  <Popover placement="topRight" content={<LinkListing links={clicks} />}>
                                    <Icon
                                      type="info-circle"
                                      className="color-primary"
                                      style={{ paddingLeft: 5, cursor: 'pointer' }}
                                    />
                                  </Popover>
                                )}
                              </span>
                            }
                            value={overallClicks}
                          />
                        </Col>
                      </Row>

                      <Divider />

                      {insufficientData ? (
                        <Empty
                          description={
                            <span className="color-secondary size-small">
                              Nicht genügend Daten zur weiteren Auswertung
                            </span>
                          }
                        />
                      ) : (
                        <Fragment>
                          <div className="color-secondary size-small">Top 5 Fachrichtungen</div>
                          {this.renderPieChartRow(sortedAffiliations)}

                          <div className="color-secondary size-small" style={{ paddingTop: 25 }}>
                            Top 5 Hochschulen
                          </div>
                          {this.renderPieChartRow(sortedUniversities)}
                        </Fragment>
                      )}
                    </Fragment>
                  );
                }}
              </Query>
              <span className="color-secondary size-small">Alle 12 Stunden aktualisiert</span>
            </Col>
          </Row>
        )}
      </Modal>
    );
  }
}

StatisticModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  post: PropTypes.object,
};

export default StatisticModal;
