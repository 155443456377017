import React, { Component } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { mapValues } from 'lodash';

import { Row, Col, Form, Input, DatePicker, TimePicker } from 'antd';

import Geosearch from '../../../../Geosearch';

class Event extends Component {
  constructor(props) {
    super(props);

    const { data } = props;
    const { end } = data || {};

    this.state = {
      eventHasEnd: !!end,
      primaryLanguage: 'de',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const end = this.props.form.getFieldValue(`attachment.end`);
    if (!!end !== !!this.state.eventHasEnd) {
      this.setState({ eventHasEnd: !!end });
    }
  }

  changeLocation = (location = {}) => {
    const { setFieldsValue } = this.props.form;

    setFieldsValue({
      [`attachment.location`]: location,
    });

    this.setState({ location });
  };

  handleEndTimeChange = (date) => {
    const { setFieldsValue, getFieldValue } = this.props.form;

    const startFieldValue = getFieldValue(`attachment.start`);
    const startDate = moment(startFieldValue);

    if (startDate.isAfter(date)) {
      setFieldsValue({
        [`attachment.start`]: moment(date).subtract(1, 'hour'),
      });
    }
  };

  handleStartTimeChange = (date) => {
    const { setFieldsValue, getFieldValue } = this.props.form;

    const endFieldValue = getFieldValue(`attachment.end`);
    const endDate = endFieldValue ? moment(endFieldValue) : null;

    if (endDate && endDate.isBefore(date)) {
      setFieldsValue({
        [`attachment.end`]: moment(date).add(1, 'hour'),
      });
    }
  };

  addEnd = () => {
    const { setFieldsValue, getFieldValue } = this.props.form;

    const startField = getFieldValue(`attachment.start`);

    if (startField) {
      setFieldsValue({
        [`attachment.end`]: moment(startField).add(1, 'hour'),
      });
    }

    this.setState({ eventHasEnd: true });
  };

  removeEnd = () => {
    const { setFieldsValue } = this.props.form;

    setFieldsValue({
      [`attachment.end`]: null,
    });

    this.setState({ eventHasEnd: false });
  };

  render() {
    const { form, data } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const { eventHasEnd } = this.state;

    const { title, start, end, location = {}, translations } = data || {};

    const locationString = ['street', 'city', 'country']
      .map((prop) => location[prop])
      .filter((prop) => prop)
      .join(', ');

    const defaultStart = moment.utc('09:00', 'HH:mm');
    const startValue = getFieldValue(`attachment.start`);
    const initialEnd = startValue ? moment(startValue).add(1, 'hour') : null;

    const formTranslations = getFieldValue(`attachment.translations`);
    const primaryValues = formTranslations
      ? mapValues(formTranslations, (el) => el?.[this.state.primaryLanguage])
      : null;

    return (
      <div>
        <div style={{ padding: '0px 20px 10px 20px' }}>
          <Row type="flex" align="middle">
            <Form.Item className="event-input">
              {getFieldDecorator(`attachment.translations.title.${this.props.language}`, {
                initialValue: translations?.title ? translations?.title?.[this.props.language] : title,
                rules: [
                  {
                    required: this.props.language === this.state.primaryLanguage,
                  },
                ],
              })(<Input placeholder={primaryValues?.title || 'Titel der Veranstaltung'} />)}
            </Form.Item>
          </Row>
          <Row type="flex" align="middle" gutter={20}>
            <Col xs={24} sm={16}>
              {getFieldDecorator(`attachment.location`, {
                initialValue: location,
                trigger: null,
              })(
                <Geosearch
                  initialValue={locationString}
                  onSelect={this.changeLocation}
                  onChange={(value) => {
                    if (!value) {
                      this.changeLocation();
                    }
                  }}
                />
              )}
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item className="event-input">
                {getFieldDecorator(`attachment.addition`, {
                  initialValue: location.addition,
                  rules: [],
                })(<Input placeholder={primaryValues?.addition || 'Gebäude, Raum o.ä.'} />)}
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div
          style={{
            backgroundColor: '#e4e6eb',
            borderRadius: '0 0 7px 7px',
            borderTop: '1px solid #c2c2cd',
          }}
        >
          <div style={{ padding: '0px 20px 10px 20px' }}>
            <Row gutter={24}>
              <Col xs={24} sm={16}>
                <Form.Item className="event-input">
                  {getFieldDecorator(`attachment.start`, {
                    initialValue: start ? moment.utc(start) : defaultStart,
                    rules: [
                      {
                        required: true,
                      },
                    ],
                  })(
                    <DatePicker
                      placeholder={'Startdatum'}
                      format="DD.MM.YYYY"
                      disabledDate={(currentDate) => {
                        const endDate = getFieldValue(`attachment.end`);
                        const startOfDay = moment().startOf('day');
                        return (
                          currentDate && (currentDate.isBefore(startOfDay) || (endDate && currentDate.isAfter(endDate)))
                        );
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <Form.Item className="event-input">
                  {getFieldDecorator(`attachment.start`, {
                    onChange: this.handleStartTimeChange,
                    rules: [
                      {
                        required: true,
                      },
                    ],
                  })(<TimePicker format="HH:mm" minuteStep={15} placeholder={'Startzeit'} allowClear={false} />)}
                </Form.Item>
              </Col>
            </Row>
            {eventHasEnd ? (
              <Row gutter={24}>
                <Col xs={24} sm={16}>
                  <Form.Item className="event-input">
                    {getFieldDecorator(`attachment.end`, {
                      initialValue: end ? moment.utc(end) : initialEnd,
                      rules: [
                        {
                          required: true,
                        },
                      ],
                    })(
                      <DatePicker
                        placeholder={'Enddatum'}
                        format="DD.MM.YYYY"
                        disabledDate={(currentDate) => {
                          const startDate = getFieldValue(`attachment.start`);
                          const startOfDay = moment().startOf('day');
                          return (
                            currentDate &&
                            (currentDate.isBefore(startOfDay) || (startDate && currentDate.isBefore(startDate)))
                          );
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item className="event-input">
                    {getFieldDecorator(`attachment.end`, {
                      initialValue: initialEnd,
                      onChange: this.handleEndTimeChange,
                      rules: [
                        {
                          required: true,
                        },
                      ],
                    })(<TimePicker format="HH:mm" minuteStep={15} placeholder={'Endzeit'} allowClear={false} />)}
                  </Form.Item>
                </Col>
                <Col span={24} style={{ textAlign: 'right', padding: '0px 20px 0px 20px' }}>
                  <span className="clickable-primary" onClick={this.removeEnd}>
                    - Ende entfernen
                  </span>
                </Col>
              </Row>
            ) : (
              <Row style={{ textAlign: 'right', padding: '0px 20px 0px 20px' }}>
                <Col span={24}>
                  <span className="clickable-primary" onClick={this.addEnd}>
                    + Ende hinzufügen
                  </span>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Event.propTypes = {
  form: PropTypes.object.isRequired,
  data: PropTypes.object,
  language: PropTypes.string,
};

export default Event;
