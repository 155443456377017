import React, { Component } from 'react';

import ContentLoggedOut from '../../components/ContentLoggedOut';
import { withApollo } from 'react-apollo';

import { CONFIRM_EMAIL_APP_ACCOUNT } from 'core/gql/mutations';
import { Icon, Button } from 'antd';

class ErrorPage extends Component {
  state = { error: false, success: false, loading: true };

  async componentDidMount() {
    const { token } = this.props.match.params;
    if (!token) {
      this.setState({ error: true, loading: false });
    }
    const { data = {}, error } = await this.props.client
      .mutate({
        mutation: CONFIRM_EMAIL_APP_ACCOUNT,
        variables: { token },
      })
      .catch(() => ({ data: null, error: true }));
    if (error || !data.verifyEmail) {
      this.setState({ error: true, loading: false });
      return;
    }
    this.setState({ success: true, loading: false });
  }

  render() {
    const buttonStyle = {
      margin: 20,
      borderWidth: 0,
      borderRadius: 100,
      width: 213,
      height: 43,
      fontWeight: 600,
      fontSize: 20,
    };
    return (
      <ContentLoggedOut>
        <div>
          {this.state.loading && (
            <div style={{ textAlign: 'center' }}>
              <h1 style={{ color: '#ffffff' }}>
                <Icon type="loading" />
                <br />
                Deine E-Mail Adresse wird verifiziert
              </h1>
            </div>
          )}
          {this.state.success && (
            <div style={{ textAlign: 'center' }}>
              <h1 style={{ color: '#ffffff' }}>
                <Icon type="check" />
                <br />
                Deine E-Mail wurde erfolgreich verifiziert
              </h1>
              <p style={{ color: '#fff' }}>Kehre jetzt in die App zurück und schreibe mit deinen Freunden</p>
            </div>
          )}
          {this.state.error && (
            <div style={{ textAlign: 'center' }}>
              <h1 style={{ color: '#ffffff' }}>
                <Icon type="close" />
                <br /> Deine E-Mail konnte nicht verifiziert werden
              </h1>
              <p style={{ color: '#fff' }}>
                Bitte versuche es später noch einmal oder kontaktiere uns unter{' '}
                <a href="mailto:support@uninow.de" style={{ color: '#fff', textDecoration: 'underline' }}>
                  support@uninow.de
                </a>
              </p>
            </div>
          )}
        </div>
        <div>
          <a href="https://itunes.apple.com/de/app/uninow-studium-karriere/id969806189?mt=8">
            <Button type="primary" style={buttonStyle}>
              <Icon type="apple" /> App Store
            </Button>
          </a>
          <a href="https://play.google.com/store/apps/details?id=de.mocama.UniNow">
            <Button type="primary" style={buttonStyle}>
              <Icon type="android" /> Play Store
            </Button>
          </a>
        </div>
      </ContentLoggedOut>
    );
  }
}

export default withApollo(ErrorPage);
